import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useUnit } from 'effector-react';

import { setSelectedDdc } from '@/stores/userInfoStore/UserInfoEvents';
import { UserInfoStore } from '@/stores/userInfoStore/UserInfoStore';
import { ddcNameFormatter } from '@/utils/ddcNameFormatter/ddcNameFormatter';

import { Ddc } from '../../types/ModalChangeDDCTypes';
import { useSegmentDdcSelect } from '../useSegmentDdcSelect/useSegmentDdcSelect';

export const useChangeDdc = ({ isOpen, onClose }) => {
  const { formatMessage } = useIntl();
  const { dispatchSelectedDdcEvent } = useSegmentDdcSelect();
  const { ddc } = useUnit(UserInfoStore);
  const [oldDdc, setOldDdc] = useState({ id: '', name: '' });

  const handleChangeClick = (newDdc: Ddc) => {
    onClose();
    setSelectedDdc({ ddc: { ...newDdc, value: newDdc.id ? newDdc.id : 'default' } });
    dispatchSelectedDdcEvent(ddcNameFormatter({ ddc: oldDdc }), ddcNameFormatter({ ddc }));
  };

  useEffect(() => {
    setOldDdc(ddc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return {
    handleChangeClick,
    formatMessage,
    oldDdc,
    setOldDdc,
  };
};
