import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useQuery } from '@bees-force-shared/services';
import { useUnit } from 'effector-react';

import { UserInfoStore } from '@/stores/userInfoStore/UserInfoStore';
import { GetKpiRequestQuery } from '@/types/domains/GetKpisDomain';
import { getKpiUseCase } from '@/useCases/visits/GetKpisUseCase';
import { formatUpdateTime } from '@/utils/formatUpdateTime/formatUpdateTime';

export function useQueryKpis(requestQuery: GetKpiRequestQuery) {
  const { ddc } = useUnit(UserInfoStore);
  const isManualRefresh = useRef(false);

  const [lastTimeUpdate, setLastTimeUpdate] = useState('');
  const [errorFromRefresh, setErrorFromRefresh] = useState(false);

  const { locale: currentLocale } = useIntl();

  const kpis = useQuery({
    queryKey: ['kpis', requestQuery],
    queryFn: () => {
      const params = isManualRefresh.current
        ? { ...requestQuery, refreshData: true }
        : requestQuery;

      return getKpiUseCase(params);
    },
    enabled: !!requestQuery.startDate && !!requestQuery.endDate && ddc.value !== '',
    retry: false,
    onError: () => {
      if (isManualRefresh.current) {
        setErrorFromRefresh(true);
      }
    },
    onSuccess: () => {
      setErrorFromRefresh(false);
    },
    onSettled: () => {
      isManualRefresh.current = false;
    },
  });

  const refreshKpisData = () => {
    isManualRefresh.current = true;
    kpis.refetch();
  };

  const percentageCanceledVisits = () => {
    if (!kpis?.data?.totalCancelledVisits || !kpis?.data?.totalOfVisits) {
      return 0;
    }

    const percentage = (kpis?.data?.totalCancelledVisits * 100) / kpis?.data?.totalOfVisits;
    return parseFloat(percentage.toFixed(2));
  };

  useEffect(() => {
    if (kpis.data?.updatedAt) {
      setLastTimeUpdate(formatUpdateTime(kpis.data.updatedAt, currentLocale));
    }
  }, [kpis, currentLocale]);

  return {
    kpis,
    lastTimeUpdate,
    errorFromRefresh,
    percentageCanceledVisits,
    refreshKpisData,
  };
}
