import { Heading, Paragraph } from '@hexa-ui/components';

import { BadgeStatus } from '@/components/Atoms/BadgeStatus/BadgeStatus';
import { DateFormats } from '@/consts/GlobalConsts';
import { formatDate } from '@/utils/dateFormatter/dateFormatter';
import { getFormattedMessage } from '@/utils/getFormattedMessage/getFormattedMessage';

import { VISIT_INFORMATION_TEST_IDS } from '../../consts/VisitDetailsCardConsts';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { useVisitDetails } from '../../hooks/useVisitDetails';
import { VisitInformationProps } from '../../types/VisitDetails.types';
import * as Styled from './VisitInformation.styles';

const VisitInformationMinMaxTime = ({ sugTime, minTime, maxTime }) => {
  const { suggestedTimeLabel, minimumTimeLabel, maximumTimeLabel, minutesLabel } = useTranslation();

  const renderTimeInfo = (label: string, time: string) => (
    <Styled.WrapperInformation>
      <Paragraph weight="semibold">{label}</Paragraph>
      <Paragraph>{getFormattedMessage(minutesLabel, { min: time })}</Paragraph>
    </Styled.WrapperInformation>
  );

  return (
    <>
      {renderTimeInfo(suggestedTimeLabel, sugTime)}
      {renderTimeInfo(minimumTimeLabel, minTime)}
      {renderTimeInfo(maximumTimeLabel, maxTime)}
    </>
  );
};

export const VisitInformation = ({
  date,
  status,
  suggestedTime,
  minimumTime,
  maximumTime,
}: VisitInformationProps) => {
  const { isFeatureVisitEffectiveRouteEnabled, preferredLanguage } = useVisitDetails();
  const { visitInformationTitle, visitDateLabel, visitStatusLabel } = useTranslation();

  return (
    <Styled.WrapperVisitInformationCard border="medium" elevated="minimal">
      <Heading data-testid={VISIT_INFORMATION_TEST_IDS.HEADER} size="H3">
        {visitInformationTitle}
      </Heading>
      <Styled.WrapperInformationContent>
        <Styled.WrapperInformation>
          <Paragraph weight="semibold">{visitDateLabel}</Paragraph>
          <Paragraph>{formatDate(new Date(date), preferredLanguage, DateFormats.MEDIUM)}</Paragraph>
        </Styled.WrapperInformation>
        <Styled.WrapperInformation>
          <Paragraph weight="semibold">{visitStatusLabel}</Paragraph>
          <BadgeStatus status={status} />
        </Styled.WrapperInformation>
        <Styled.WrapperInformation />
        {isFeatureVisitEffectiveRouteEnabled && (
          <VisitInformationMinMaxTime
            sugTime={suggestedTime}
            minTime={minimumTime}
            maxTime={maximumTime}
          />
        )}
      </Styled.WrapperInformationContent>
    </Styled.WrapperVisitInformationCard>
  );
};
