import { $userSelection } from '@bees-force-shared/services';
import { useAuthenticationService } from 'admin-portal-shared-services';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface IRequest {
  url: string;
  config?: AxiosRequestConfig;
  body?: unknown;
}

const state = $userSelection.getState();
const vendorId = state?.operation?.vendorId ?? '';

export const axiosInstance = axios.create();

// eslint-disable-next-line
const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

authentication.enhancedAxios(
  axiosInstance as unknown as Parameters<typeof authentication.enhancedAxios>[0],
  {
    headers: [
      {
        country: userCountry ?? '',
        timezone: 'UTC',
        vendorId: vendorId,
      },
    ],
  },
);

export const api = {
  get: ({ url, config }: IRequest): Promise<AxiosResponse> => axiosInstance.get(url, config),

  post: ({ url, body, config }: IRequest): Promise<AxiosResponse> =>
    axiosInstance.post(url, body, config),

  delete: ({ url, config }: IRequest): Promise<AxiosResponse> => axiosInstance.delete(url, config),

  put: ({ url, body, config }: IRequest): Promise<AxiosResponse> =>
    axiosInstance.put(url, body, config),

  patch: ({ url, body, config }: IRequest): Promise<AxiosResponse> =>
    axiosInstance.patch(url, body, config),
};
