import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { Button, Calendar } from '@hexa-ui/components';

import { FILTERS_TEST_IDS, FILTERS_IDS } from '@/components/Overview/consts/OverviewConsts';
import { FilterModalCalendarProps } from '@/components/Overview/types/FilterModalCalendar';

import * as Styled from './FilterModalCalendar.styles';
import { useFilterModalCalendar } from './hooks/useFilterModalCalendar';

export const FilterModalCalendar = ({
  language,
  inputRef,
  handleOnSave,
  handleOnReset,
  handleOnClickOutside,
}: FilterModalCalendarProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const {
    calculateStyleModal,
    calendarRef,
    disableActions,
    handleActionButtons,
    onResetFilter,
    onSaveFilter,
  } = useFilterModalCalendar({ inputRef, handleOnSave, handleOnReset });

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutsideEvent = (event: MouseEvent) => {
      const getButtonLabelAttribute = event.target as HTMLElement;
      const eventId = getButtonLabelAttribute.getAttribute('id');
      /* istanbul ignore else */
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        eventId !== FILTERS_IDS.OTHER_LABEL_ID
      ) {
        handleOnClickOutside();
      }
    };
    document.addEventListener('mousedown', handleClickOutsideEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEvent);
    };
  }, [containerRef, handleOnClickOutside]);

  return (
    <Styled.FilterModal
      ref={containerRef}
      style={calculateStyleModal()}
      data-testid={FILTERS_TEST_IDS.FILTER_MODAL}>
      <Calendar
        legend={{
          orientation: 'vertical',
        }}
        mode="range"
        numberOfMonths={2}
        customLocale={language}
        onChange={handleActionButtons}
        hightlightedDates={[]}
        ref={calendarRef}
        data-testid={FILTERS_TEST_IDS.FILTER_MODAL_CALENDAR}
      />
      <Styled.WrapperButtonFilter>
        {disableActions ? (
          <Button
            data-testid={FILTERS_TEST_IDS.FILTER_BUTTON_RESET_CALENDAR}
            disabled={disableActions}>
            {formatMessage({ id: 'Components.FilterModalCalendar.Button.RESET' })}
          </Button>
        ) : (
          <Styled.ResetButton
            hasUnderline={false}
            onClick={onResetFilter}
            data-testid={FILTERS_TEST_IDS.FILTER_BUTTON_RESET_CALENDAR}>
            {formatMessage({ id: 'Components.FilterModalCalendar.Button.RESET' })}
          </Styled.ResetButton>
        )}
        <Button
          onClick={onSaveFilter}
          data-testid={FILTERS_TEST_IDS.FILTER_BUTTON_SAVE_CALENDAR}
          disabled={disableActions}>
          {formatMessage({ id: 'Components.FilterModalCalendar.Button.SAVE' })}
        </Button>
      </Styled.WrapperButtonFilter>
    </Styled.FilterModal>
  );
};
