interface DDCNameFormatter {
  ddc: {
    id: string;
    name: string;
    value?: string;
  };
  showAllMessage?: string;
}

export function ddcNameFormatter({ ddc, showAllMessage = 'Show all' }: DDCNameFormatter): string {
  return ddc.id ? `${ddc.id} / ${ddc.name}` : showAllMessage;
}
