import { LoadingContent, ErrorContent } from '@bees-force-shared/components';
import { Paragraph } from '@hexa-ui/components';

import { BadgeStatus } from '@/components/Atoms/BadgeStatus/BadgeStatus';
import { DateFormats } from '@/consts/GlobalConsts';
import {
  formatDateToLocaleDate,
  formatStringDateToNewDate,
} from '@/utils/dateFormatter/dateFormatter';

import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { useVisitDetails } from '../../hooks/useVisitDetails';
import { NextVisitInformationProps } from '../../types/VisitDetails.types';
import { EmptyCard } from '../EmptyCard/EmptyCard';
import * as Styled from './NextVisitContent.styles';

export const NextVisitContent = ({
  nextVisitDate,
  nextVisitStatus,
  isLoading,
  isError,
  onRefresh,
}: NextVisitInformationProps) => {
  const { locale } = useVisitDetails({});
  const {
    noNextVisitLabel,
    visitDateLabel,
    visitStatusLabel,
    loadingContent,
    errorContent,
    errorRefreshContent,
  } = useTranslation();

  if (isLoading) {
    return (
      <Styled.WrapperLoadingContent>
        <LoadingContent text={loadingContent} />
      </Styled.WrapperLoadingContent>
    );
  }

  if (isError) {
    return (
      <ErrorContent text={errorContent} refreshText={errorRefreshContent} onRefresh={onRefresh} />
    );
  }

  if (!nextVisitDate || !nextVisitStatus) {
    return <EmptyCard emptyMessage={noNextVisitLabel} />;
  }

  return (
    <Styled.WrapperInformationContent>
      <div>
        <Paragraph weight="semibold">{visitDateLabel}</Paragraph>
        <Paragraph>
          {formatDateToLocaleDate(
            formatStringDateToNewDate(nextVisitDate),
            locale,
            DateFormats.MEDIUM,
          )}
        </Paragraph>
      </div>
      <div>
        <Paragraph weight="semibold">{visitStatusLabel}</Paragraph>
        <BadgeStatus status={nextVisitStatus} />
      </div>
    </Styled.WrapperInformationContent>
  );
};
