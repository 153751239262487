import { Heading } from '@hexa-ui/components';

import { VISIT_INFORMATION_TEST_IDS } from '../../consts/VisitDetailsCardConsts';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { VisitInformationProps } from '../../types/VisitDetails.types';
import { VisitInformationContent } from '../VisitInformationContent.tsx/VisitInformationContent';
import * as Styled from './VisitInformation.styles';

export const VisitInformation = ({
  date,
  status,
  suggestedTime,
  minimumTime,
  maximumTime,
  isLoading,
  isError,
  onRefresh,
}: VisitInformationProps) => {
  const { visitInformationTitle } = useTranslation();

  return (
    <Styled.WrapperVisitInformationCard border="medium" elevated="minimal">
      <Heading data-testid={VISIT_INFORMATION_TEST_IDS.HEADER} size="H3">
        {visitInformationTitle}
      </Heading>

      <VisitInformationContent
        date={date}
        status={status}
        suggestedTime={suggestedTime}
        minimumTime={minimumTime}
        maximumTime={maximumTime}
        isLoading={isLoading}
        isError={isError}
        onRefresh={onRefresh}
      />
    </Styled.WrapperVisitInformationCard>
  );
};
