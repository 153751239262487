import { Badge, Button, Card, Paragraph } from '@hexa-ui/components';
import styled from 'styled-components';

export const FilterContainer = styled.div``;

interface FilterToggleButtonProps extends React.ComponentProps<typeof Button> {
  expanded?: string;
}

export const FilterToggleButton = styled(Button)<FilterToggleButtonProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.measure.space['space-2']};
  gap: ${({ theme }) => theme.measure.space['space-2']};
  border: ${({ expanded, theme }) =>
    expanded
      ? `1px solid ${theme.color.primitive.solid.neutral['neutral-100']}`
      : `1px solid  ${theme.color.primitive.transparent.black['black-12']}`};

  &:hover {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.color.primitive.solid.neutral['neutral-100']};
  }
`;

export const FilterPanelContent = styled(Card)`
  position: absolute;
  margin-top: ${({ theme }) => theme.measure.space['space-1']};
  z-index: 12;
  width: ${({ theme }) => theme.measure.dimension.multiplier['multiplier-128']};
`;

export const FilterContent = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.color.primitive.transparent.black['black-12']};
`;

export const TabsContainer = styled.div`
  width: 50%;
`;

export const TabItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterTab = styled(Paragraph)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.measure.space['space-4']};
  background-color: ${({ theme }) => theme.color.primitive.transparent.black['black-4']};
  border-bottom: 1px solid ${({ theme }) => theme.color.primitive.transparent.black['black-12']};
`;

export const ListItemsContainer = styled.div`
  border-left: 1px solid ${({ theme }) => theme.color.primitive.transparent.black['black-12']};
  padding: ${({ theme }) => theme.measure.space['space-4']};
`;

export const ListItem = styled.div`
  padding-bottom: ${({ theme }) => theme.measure.space['space-4']};
`;

export const FilterButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.measure.space['space-4']};
`;

export const FilterActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.measure.space['space-4']};
`;

export const CountAppliedFilters = styled(Badge.Counter)`
  background-color: ${({ theme }) => theme.color.primitive.solid.blue['blue-70']};
  height: max-content;
  min-width: 15px;

  p {
    color: ${({ theme }) => theme.color.primitive.solid.neutral['neutral-0']};
  }
`;

export const ClearButton = styled(Button)`
  border: none;
  color: ${({ theme }) => theme.color.primitive.solid.blue['blue-70']};

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
`;
