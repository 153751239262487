import analytics from '@/config/typewriter';
import { VisitListSegmentProps } from '@/types/AnalyticsTypes';

import { useAnalytics } from '../useAnalytics/useAnalytics';

interface UseAnalyticsVisitList {
  buttonName: string;
  visitId: string;
  componentName: string;
}

export const useAnalyticsVisitList = (): {
  dispatchVisitListSegment: (visitListValues: UseAnalyticsVisitList) => void;
} => {
  const { defaultProps } = useAnalytics();

  const dispatchVisitListSegment = (visitListValues: UseAnalyticsVisitList) => {
    analytics.visitList({
      ...defaultProps,
      [VisitListSegmentProps.ButtonName]: visitListValues.buttonName,
      [VisitListSegmentProps.VisitID]: visitListValues.visitId,
      [VisitListSegmentProps.ComponentName]: visitListValues.componentName,
      [VisitListSegmentProps.FailureReason]: '',
    });
  };

  return {
    dispatchVisitListSegment,
  };
};
