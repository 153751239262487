import { axiosInstance } from '@bees-force-shared/services';

import { UPDATE_VISIT_ENDPOINTS } from '@/constructors/Visits/VisitsConstructor';
import {
  UpdateVisitRequestBody,
  UpdateVisitRequestQuery,
  UpdateVisitResponse,
} from '@/types/domains/UpdateVisitDomain';

export async function updateVisit(
  params: UpdateVisitRequestQuery,
  body: UpdateVisitRequestBody,
): Promise<UpdateVisitResponse> {
  const response = await axiosInstance({
    method: 'PATCH',
    url: UPDATE_VISIT_ENDPOINTS.PATCH_EDIT_VISIT(params),
    headers: { vendorId: params.vendorId },
    data: body,
  });

  return response.data;
}
