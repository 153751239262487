export const visits = [
  {
    id: '001',
    date: '2025-05-01T17:28:52.590Z',
    status: 'scheduled',
    suggestedTime: '15',
    minimumTime: '5',
    maximumTime: '20',
    poc: {
      id: '02938483493',
      name: 'John Doe',
      email: 'johndoe@email.com',
      latitude: '-239948493',
      longitude: '-239848493',
    },
    bdr: {
      id: '02938483493',
      name: 'Jane Doe',
    },
    nextVisit: {
      date: '2024-05-01T17:28:52.590Z',
      status: 'scheduled',
    },
  },
  {
    id: '002',
    date: '2024-05-01T17:28:52.590Z',
    status: 'in_progress',
    suggestedTime: '5',
    minimumTime: '5',
    maximumTime: '10',
    poc: {
      id: '02938483493',
      name: 'John Doe',
      email: 'johndoe@email.com',
    },
    bdr: {
      id: '02938483493',
    },
    nextVisit: {
      date: '2024-05-01T17:28:52.590Z',
      status: 'in_progress',
    },
  },
  {
    id: '003',
    date: '2024-05-01T17:28:52.590Z',
    status: 'completed',
    suggestedTime: '15',
    minimumTime: '5',
    maximumTime: '20',
    poc: {
      id: '02938483493',
      name: 'John Doe',
      email: 'johndoe@email.com',
      latitude: '-239948493',
      longitude: '-239848493',
    },
    bdr: {
      id: '02938483493',
      name: 'Jane Doe',
    },
    nextVisit: {
      date: '2024-05-01T17:28:52.590Z',
      status: 'completed',
    },
  },
  {
    id: '004',
    date: '2024-05-01T17:28:52.590Z',
    status: 'justified',
    suggestedTime: '15',
    minimumTime: '5',
    maximumTime: '20',
    poc: {
      id: '02938483493',
      name: 'John Doe',
      email: 'johndoe@email.com',
      latitude: '-239948493',
      longitude: '-239848493',
    },
    nextVisit: {
      date: '2024-05-01T17:28:52.590Z',
      status: 'justified',
    },
  },
  {
    id: '005',
    date: '2024-05-01T17:28:52.590Z',
    status: 'canceled',
    suggestedTime: '15',
    minimumTime: '5',
    maximumTime: '20',
    poc: {
      id: '02938483493',
      name: 'John Doe',
      email: 'johndoe@email.com',
      latitude: '-239948493',
      longitude: '-239848493',
    },
    bdr: {
      id: '02938483493',
      name: 'Jane Doe',
    },
    nextVisit: {
      date: '2024-05-01T17:28:52.590Z',
      status: 'canceled',
    },
    canceledDueTo: 'user',
  },
  {
    id: '006',
    date: '2024-05-01T17:28:52.590Z',
    status: 'in_progress',
    suggestedTime: '5',
    minimumTime: '5',
    maximumTime: '10',
  },
  {
    id: '007',
    date: '2024-05-01T17:28:52.590Z',
    status: 'scheduled',
    suggestedTime: '15',
    minimumTime: '5',
    maximumTime: '20',
    poc: {
      id: '02938483493',
      name: 'John Doe',
      email: 'johndoe@email.com',
      latitude: '-239948493',
      longitude: '-239848493',
    },
    bdr: {
      id: '02938483493',
      name: 'Jane Doe',
    },
    nextVisit: {
      date: '2024-05-01T17:28:52.590Z',
      status: 'completed',
    },
  },
];
