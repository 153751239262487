import { Card } from '@hexa-ui/components';
import styled from 'styled-components';

export const WrapperVisitInformationCard = styled(Card)`
  padding-top: ${({ theme }) => theme.measure.space['space-6']};
  padding-right: ${({ theme }) => theme.measure.space['space-6']};
  padding-bottom: ${({ theme }) => theme.measure.space['space-8']};
  padding-left: ${({ theme }) => theme.measure.space['space-6']};
  gap: ${({ theme }) => theme.measure.space['space-8']};
`;

export const WrapperInformationContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: ${({ theme }) => theme.measure.space['space-8']};
`;

export const WrapperInformation = styled.div``;
