import { Paragraph, TextLink } from '@hexa-ui/components';
import styled, { css } from 'styled-components';

export const WrapperRefreshButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.measure.space['space-3']};

  > :is(a, p) {
    line-height: ${({ theme }) => theme.measure['line-height']['lineheight-3']};
  }
`;

interface RefreshButtonProps {
  disabled: boolean;
}

export const RefreshButton = styled(TextLink)<RefreshButtonProps>`
  align-content: center;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export const RefreshLabel = styled(Paragraph)`
  align-content: center;
`;

export const RefreshErrorLabel = styled(Paragraph)`
  align-content: center;
`;
