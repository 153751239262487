import MessageMap from '../i18n.d';

export const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  Pages: {
    Visits: {
      Title: {
        PAGE_VISITS: 'Visits',
      },
    },
  },
  Components: {
    Overview: {
      Title: {
        OVERVIEW: 'Overview',
        CANCELLED_VISITS: '% Canceled visits',
        BDRS_WITHOUT_VISITS: '% BDRs without visits',
        TOTAL_OF_VISITS: 'Total of visits',
      },
      Label: {
        CANCELLED_VISITS_TOTAL: ' of [total] Visits',
        BDRS_WITHOUT_VISITS_TOTAL: 'of [total] BDRs',
        TOTAL_OF_VISITS_COUNT: 'Total',
        LAST_UPDATE: 'Last updated [lastUpdate] hours ago.',
        FILTER_DESCRIPTION: 'Select a data range you would like to be displayed.',
      },
      Button: {
        REFRESH: 'Refresh',
      },
      Error: {
        REFRESH_ERROR: 'We were not able to refresh',
      },
      Filter: {
        Periods: {
          TODAY: 'Today',
          YESTERDAY: 'Yesterday',
          TOMORROW: 'Tomorrow',
          OTHER: 'Other',
        },
      },
      Graph: {
        TITLE: 'By status',
        SCHEDULED: 'Scheduled',
        IN_PROGRESS: 'In progress',
        COMPLETED: 'Completed',
        JUSTIFIED: 'Justified',
        CANCELED: 'Canceled',
      },
    },
    VisitTableCard: {
      SearchBar: {
        ACCOUNT_ID: 'Account ID',
        BDR_ID: 'BDR ID',
        BDR_NAME: 'BDR Name',
        SEARCH_BY: 'Search by',
      },
      Buttons: {
        SEARCH_BUTTON: 'Search',
        FILTERS_BUTTON: 'Filters',
        APPLY_BUTTON: 'Apply',
        CANCEL_BUTTON: 'Cancel',
        CLEAR_ALL_BUTTON: 'Clear all',
      },
      Title: {
        CARD_VISITS: 'Visits',
      },
      Error: {
        ACCOUNT_ID_ERROR: 'Account ID [id] does not exist.',
        BDR_ID_ERROR: 'BDR [id] does not exist.',
        BDR_NAME_ERROR: 'BDR [name] does not exist.',
      },
      StatusVisits: {
        STATUS: 'Status',
        SCHEDULED: 'Scheduled',
        IN_PROGRESS: 'In progress',
        COMPLETED: 'Completed',
        JUSTIFIED: 'Justified',
        CANCELED: 'Canceled',
      },
      TableVisits: {
        SHOWING_RESULTS: 'Showing [results] visits',
        BDR_ID: 'BDR ID',
        BDR_NAME: 'BDR Name',
        BDR_ACCOUNT_ID: 'Account ID',
        VISIT_DATE: 'Visit Date',
        MIN_TIME: 'Min. Time',
        MAX_TIME: 'Max. Time',
        MINUTES: 'minutes',
        QUANTITY_INDICATION: 'de',
      },
      Filter: {
        FILTERED_BY: 'Filtered by',
      },
    },
    ModalBDRsWithoutVisits: {
      Title: {
        BDRS_WITHOUT_VISITS: 'BDRs without visits',
      },
      Label: {
        SHOWING_RESULTS: 'Showing [results] results',
        BDR_ID: 'BDR ID',
        BDR_NAME: 'BDR Name',
        DATE_WITHOUT_VISITS: 'Date without visit',
        SEARCH_BY: 'Search by BDR ID',
        PAGINATION_INDICATION: 'of',
      },
    },
    VisitDetails: {
      Title: {
        VISIT_DETAILS: 'Visit Details',
        POC_INFORMATION: 'POC Information',
        NEXT_VISIT: 'Next Visit',
        BDR_INFORMATION: 'BDR Information',
        VISIT_INFORMATION: 'Visit Information',
        CANCEL_VISIT: 'Cancel Visit?',
      },
      Label: {
        VISIT_DATE: 'Visit Date',
        VISIT_STATUS: 'Visit Status',
        SUGGESTED_TIME: 'Suggested Time',
        MINIMUM_TIME: 'Minimum Time',
        MAXIMUM_TIME: 'Maximum Time',
        NO_NEXT_VISIT: 'There is no next visit scheduled for this POC.',

        POC_NAME: 'POC Name',
        POC_ID: 'POC ID',
        POC_LATITUDE: 'Latitude',
        POC_LONGITUDE: 'Longitude',
        NO_POC_INFORMATION: 'There is no POC information for this visit.',

        BDR_NAME: 'BDR Name',
        BDR_ID: 'BDR ID',
        NO_BDR_INFORMATION: 'There is no BDR information for this POC.',

        SHOW_VISIT_DETAILS: 'Show visit details',
        MINUTES: '[min] minutes',
        NO_DATA_AVAILABLE: 'No data available',
        CANCELED_DUE_TO: 'This visit was canceled by the system due to [error].',

        CANCEL_VISIT_DESCRIPTION:
          'This action can be undone as long as the date remains in the future.',
      },
      Button: {
        CANCEL_MODAL: 'Cancel',
        GO_BACK: 'No, go back',
        CANCEL_VISIT: 'Yes, cancel',
      },
      Toast: {
        SUCCESS: 'Visit canceled',
      },
    },
    FilterModalCalendar: {
      Button: {
        SAVE: 'Save',
        RESET: 'Reset',
      },
    },
    BadgeStatus: {
      Label: {
        SCHEDULED: 'Scheduled',
        IN_PROGRESS: 'In progress',
        COMPLETED: 'Completed',
        JUSTIFIED: 'Justified',
        CANCELED: 'Canceled',
      },
    },
    EditModal: {
      Label: {
        EDIT_VISIT: 'Edit visit',
        VISIT_DATE: 'Visit date',
        VISIT_STATUS: 'Visit status',
        NEXT_VISIT: 'Next visit',
        SCHEDULED: 'Scheduled',
        CANCELED: 'Canceled',
        NO_DATA_AVAILABLE: 'No data available',
        SAVED_CHANGE: 'Saved changes',
      },
      Button: {
        CANCEL: 'Cancel',
        SAVE_CHANGES: 'Save Changes',
        EDIT: 'Edit',
        SAVE: 'Save',
        RESET: 'Reset',
      },
    },
  },
};
