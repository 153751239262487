import { Heading } from '@hexa-ui/components';

import { BDR_INFORMATION_TEST_IDS } from '../../consts/VisitDetailsCardConsts';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { BdrInformationProps } from '../../types/VisitDetails.types';
import { BdrInformationContent } from '../BdrInformationContent/BdrInformationContent';
import * as Styled from './BdrInformation.styles';

export const BdrInformation = ({ bdrId, isLoading, isError, onRefresh }: BdrInformationProps) => {
  const { bdrInformationTitle } = useTranslation();

  return (
    <Styled.WrapperBdrInformationCard border="medium" elevated="minimal">
      <Heading data-testid={BDR_INFORMATION_TEST_IDS.HEADER} size="H3">
        {bdrInformationTitle}
      </Heading>
      <BdrInformationContent
        bdrId={bdrId}
        isLoading={isLoading}
        isError={isError}
        onRefresh={onRefresh}
      />
    </Styled.WrapperBdrInformationCard>
  );
};
