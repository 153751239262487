import { Card } from '@hexa-ui/components';
import styled from 'styled-components';

export const CardStatus = styled(Card)`
  padding: ${({ theme }) => theme.measure.space['space-6']};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.measure.space['space-4']};
  flex-basis: 100%;
`;

export const WrapperContentOverview = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.measure.space['space-4']};
  @media (max-width: 1340px) {
    flex-flow: row wrap;
  }
`;

export const WrapperHeaderOverview = styled.div`
  display: flex;
  flex-direction: row;
  place-content: space-between;
`;
