import { Heading, Paragraph } from '@hexa-ui/components';

import { ExternalLinkIcon } from '@/assets/icons/ExternalLinkIcon';
import { BadgeStatus } from '@/components/Atoms/BadgeStatus/BadgeStatus';
import { DateFormats } from '@/consts/GlobalConsts';
import { VisitDetailsSegmentProps } from '@/types/AnalyticsTypes';
import { formatDate } from '@/utils/dateFormatter/dateFormatter';

import { NEXT_VISIT_TEST_IDS } from '../../consts/VisitDetailsCardConsts';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { useVisitDetails } from '../../hooks/useVisitDetails';
import { EmptyCard } from '../EmptyCard/EmptyCard';
import { useSegmentVisitDetails } from './hooks/useSegmentVisitDetails/useSegmentVisitDetails';
import * as Styled from './NextVisit.styles';

export const NextVisit = ({ nextVisitDate, nextVisitStatus }) => {
  const { preferredLanguage } = useVisitDetails();
  const { dispatchVisitDetailsEvent } = useSegmentVisitDetails();
  const {
    visitDateLabel,
    visitStatusLabel,
    nextVisitTitle,
    showVisitDetailsLabel,
    noNextVisitLabel,
  } = useTranslation();

  if (!nextVisitDate || !nextVisitStatus) {
    return <EmptyCard title={nextVisitTitle} emptyMessage={noNextVisitLabel} />;
  }

  const handleLinkClick = () => {
    dispatchVisitDetailsEvent(
      VisitDetailsSegmentProps.VisitDetails,
      VisitDetailsSegmentProps.NextVisit,
    );
  };

  return (
    <Styled.WrapperNextVisitCard border="medium" elevated="minimal">
      <Styled.WrapperHeader>
        <Heading data-testid={NEXT_VISIT_TEST_IDS.HEADER} size="H3">
          {nextVisitTitle}
        </Heading>
        <Styled.Link hasUnderline={false} onClick={() => handleLinkClick()}>
          <ExternalLinkIcon />
          {showVisitDetailsLabel}
        </Styled.Link>
      </Styled.WrapperHeader>
      <Styled.WrapperInformationContent>
        <Styled.WrapperInformation>
          <Paragraph weight="semibold">{visitDateLabel}</Paragraph>
          <Paragraph>
            {formatDate(new Date(nextVisitDate), preferredLanguage, DateFormats.MEDIUM)}
          </Paragraph>
        </Styled.WrapperInformation>
        <Styled.WrapperInformation>
          <Paragraph weight="semibold">{visitStatusLabel}</Paragraph>
          <BadgeStatus status={nextVisitStatus} />
        </Styled.WrapperInformation>
      </Styled.WrapperInformationContent>
    </Styled.WrapperNextVisitCard>
  );
};
