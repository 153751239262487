import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDecision } from '@optimizely/react-sdk';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';

import { BadgeStatus } from '@/components/Atoms/BadgeStatus/BadgeStatus';
import {
  BUTTON_NAME_OPEN_VISIT_DETAILS,
  COMPONENT_NAME_VISIT_LIST,
} from '@/consts/AnalyticsConsts';
import { FEATURE_TOGGLE_VISIT_EFFECTIVE_ROUTE } from '@/consts/FeatureToggles';
import { DateFormats } from '@/consts/GlobalConsts';
import { PATH_BASE, PATH_HOME } from '@/consts/route';
import { Locales } from '@/enums/locales';
import { useAnalyticsVisitList } from '@/hooks/useAnalyticsVisitList/useAnalyticsVisitList';
import { setAccountId, setBdrId } from '@/stores/visitsParams/VisitsParamsEvents';
import {
  formatDateToLocaleDate,
  formatStringDateToNewDate,
} from '@/utils/dateFormatter/dateFormatter';

import {
  ACCESS_MAX_TIME,
  ACCESS_MIN_TIME,
  ACCESSOR_BDR_ACCOUNT_ID,
  ACCESSOR_BDR_ID,
  ACCESSOR_STATUS,
  ACCESSOR_VISIT_DATE,
  TableVisitsRowProps,
} from '../../consts/TableVisits';
import { useTranslation } from '../useTranslation/useTranslation';
import { useSegmentSortingVisits } from './hooks/useSegmentSortingVisits/useSegmentSortingVisits';

type SortDetails = {
  column?: string;
  direction?: 'ASC' | 'DESC';
};

export const useTableVisits = () => {
  const navigate = useNavigate();
  const { dispatchVisitListSegment } = useAnalyticsVisitList();

  const [sortDetails, setSortDetails] = useState<SortDetails>({});

  useSegmentSortingVisits({ column: sortDetails.column, direction: sortDetails.direction });

  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2() as {
    preferredLanguage: Locales;
    defaultLanguage: Locales;
  };
  const locale = preferredLanguage || defaultLanguage;

  const {
    bdrIdColumn,
    pocIdColumn,
    visitDateColumn,
    status,
    minTimeColumn,
    maxTimeColumn,
    minutes,
  } = useTranslation();

  const getMaxAndMinVisitTimeLabel = (timeInMinutes: number) => {
    const timeLabel =
      timeInMinutes > 1
        ? `${timeInMinutes} ${minutes}`
        : `${timeInMinutes} ${minutes.slice(0, -1)}`;
    return timeLabel.toLowerCase();
  };

  const [{ enabled: isFeatureVisitEffectiveRouteEnabled }] = useDecision(
    FEATURE_TOGGLE_VISIT_EFFECTIVE_ROUTE,
  );

  const columnsFeatureVisitEffectiveRouteEnabled = isFeatureVisitEffectiveRouteEnabled
    ? [
        {
          Header: minTimeColumn,
          accessor: ACCESS_MIN_TIME,
          disableSortBy: true,
          Cell: ({ value }) => getMaxAndMinVisitTimeLabel(value),
        },
        {
          Header: maxTimeColumn,
          accessor: ACCESS_MAX_TIME,
          disableSortBy: true,
          Cell: ({ value }) => getMaxAndMinVisitTimeLabel(value),
        },
      ]
    : [];

  const columns = [
    {
      Header: bdrIdColumn,
      accessor: ACCESSOR_BDR_ID,
      disableSortBy: true,
    },
    {
      Header: pocIdColumn,
      accessor: ACCESSOR_BDR_ACCOUNT_ID,
      disableSortBy: true,
    },
    {
      Header: status,
      accessor: ACCESSOR_STATUS,
      disableSortBy: true,
      Cell: ({ value }) => <BadgeStatus status={value} />,
    },
    {
      Header: visitDateColumn,
      accessor: ACCESSOR_VISIT_DATE,
      defaultSortAsc: true,
      Cell: ({ value }) => {
        return formatDateToLocaleDate(formatStringDateToNewDate(value), locale, DateFormats.MEDIUM);
      },
    },
    ...columnsFeatureVisitEffectiveRouteEnabled,
  ];

  const handleClickNavigate = useCallback(
    (rowData: TableVisitsRowProps) => {
      dispatchVisitListSegment({
        visitId: rowData.visitId,
        buttonName: BUTTON_NAME_OPEN_VISIT_DETAILS,
        componentName: COMPONENT_NAME_VISIT_LIST,
      });
      navigate(`${PATH_HOME}${PATH_BASE}/${rowData?.id}`);
    },
    [dispatchVisitListSegment, navigate],
  );

  const handleClearSearch = () => {
    setAccountId('');
    setBdrId('');
  };

  return {
    columns,
    handleClickNavigate,
    setSortDetails,
    handleClearSearch,
  };
};
