import { axiosInstance } from '@bees-force-shared/services';

import { VISIT_DETAILS_ENDPOINTS } from '@/constructors/Visits/VisitsConstructor';
import { GetVisitDetailsRequestQuery, VisitDetails } from '@/types/domains/GetVisitDetailsDomain';

export async function getVisitDetails(params: GetVisitDetailsRequestQuery): Promise<VisitDetails> {
  const response = await axiosInstance.get(VISIT_DETAILS_ENDPOINTS.GET_VISIT_DETAILS(params), {
    headers: { vendorId: params.vendorId },
  });

  return response.data;
}
