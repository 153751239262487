import { deleteVisit } from '@/services/visits/DeleteVisit';
import {
  DeleteVisitRequestHeader,
  DeleteVisitRequestQuery,
} from '@/types/domains/DeleteVisitDomain';

export const deleteVisitUseCase = async (
  query: DeleteVisitRequestQuery,
  header: DeleteVisitRequestHeader,
): Promise<void> => {
  await deleteVisit(query, header);
};
