import { LoadingContent, ErrorContent } from '@bees-force-shared/components';
import { Paragraph } from '@hexa-ui/components';

import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { BdrInformationProps } from '../../types/VisitDetails.types';
import { EmptyCard } from '../EmptyCard/EmptyCard';
import * as Styled from './BdrInformationContent.styles';

export const BdrInformationContent = ({
  bdrId,
  isLoading,
  isError,
  onRefresh,
}: BdrInformationProps) => {
  const { bdrIdLabel, noBdrInformationLabel, loadingContent, errorContent, errorRefreshContent } =
    useTranslation();

  if (isLoading) {
    return (
      <Styled.WrapperLoadingContent>
        <LoadingContent text={loadingContent} />
      </Styled.WrapperLoadingContent>
    );
  }

  if (isError) {
    return (
      <ErrorContent text={errorContent} refreshText={errorRefreshContent} onRefresh={onRefresh} />
    );
  }

  if (!bdrId) {
    return <EmptyCard emptyMessage={noBdrInformationLabel} />;
  }

  return (
    <Styled.WrapperInformationContent>
      <Paragraph weight="semibold">{bdrIdLabel}</Paragraph>
      <Paragraph>{bdrId}</Paragraph>
    </Styled.WrapperInformationContent>
  );
};
