import { Chip, Paragraph } from '@hexa-ui/components';

import { FILTERED_BY_TEST_IDS, FILTERS_VISITS_IDS } from '../../consts/FiltersVisits';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { FilteredByProps } from '../../types/FilterVisits';
import * as Styled from './FilteredBy.styles';

export const FilteredBy = ({ activeFilters, handleClearFilter }: FilteredByProps) => {
  const { filteredBy, scheduled, inProgress, canceled, justified, completed } = useTranslation();

  const filters = {
    [FILTERS_VISITS_IDS.scheduled]: scheduled,
    [FILTERS_VISITS_IDS.completed]: completed,
    [FILTERS_VISITS_IDS.canceled]: canceled,
    [FILTERS_VISITS_IDS.justified]: justified,
    [FILTERS_VISITS_IDS.inProgress]: inProgress,
  };

  if (!activeFilters || activeFilters.length === 0) {
    return null;
  }

  return (
    <Styled.FilteredByContainer data-testid={FILTERED_BY_TEST_IDS.FILTERED_BY_CONTAINER}>
      <Paragraph>{filteredBy}</Paragraph>
      {activeFilters?.map((filter) => (
        <Chip.Root key={filter} data-testid={FILTERED_BY_TEST_IDS.FILTERED_BY + filter}>
          <Chip.Label>{filters[filter]}</Chip.Label>
          <Chip.DeleteIcon onDelete={() => handleClearFilter(filter)} />
        </Chip.Root>
      ))}
    </Styled.FilteredByContainer>
  );
};
