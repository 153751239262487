export const VISIT_EDIT_IDS = {
  OPEN_MODAL_EDIT_BUTTON: 'open-modal-edit-button',
  CANCEL_MODAL_EDIT_BUTTON: 'cancel-modal-edit-button',
  SAVE_MODAL_EDIT_BUTTON: 'save-modal-edit-button',
  SELECT_STATUS_MODAL_EDIT: 'select-status-modal-edit',
  OPEN_CALENDAR_MODAL_EDIT_BUTTON: 'open-calendar-modal-edit-button',
  NEXT_VISIT_MODAL_EDIT: 'next-visit-modal-edit',
  SELECT_DATE_MODAL_EDIT: 'select-date-modal-edit',
  INPUT_TIME_MODAL_EDIT_SUGGESTED_TIME: 'input-time-modal-edit-suggested-time',
  INPUT_TIME_MODAL_EDIT_MAXIMUM_TIME: 'input-time-modal-edit-maximum-time',
  INPUT_TIME_MODAL_EDIT_MINIMUM_TIME: 'input-time-modal-edit-minimum-time',
};
