import { Button, Dialog, Heading, Paragraph } from '@hexa-ui/components';

import { TEST_IDS } from '@/components/ModalChangeDDC/consts/ModalChangeDDC';

import { SelectWithSearch } from './components/SelectWithSearch/SelectWithSearch';
import { useChangeDdc } from './hooks/useChangeDdc/useChangeDdc';
import { useModalChangeDDC } from './hooks/useModalChangeDDC';
import * as Styled from './ModalChangeDDC.styles';
import { ModalChangeDDCProps } from './types/ModalChangeDDCTypes';

export const ModalChangeDDC = ({ isOpen, onClose }: ModalChangeDDCProps) => {
  const { currentDdc, setCurrentDdc } = useModalChangeDDC({ isOpen });
  const { formatMessage, handleChangeClick } = useChangeDdc({ isOpen, onClose });

  return (
    <Dialog.Root
      contentCss={{
        width: '37.5rem',
      }}
      title={
        <Heading
          size="H3"
          aria-label={formatMessage({ id: 'Components.ModalChangeDDC.Title.CHANGE_DDC' })}>
          {formatMessage({ id: 'Components.ModalChangeDDC.Title.CHANGE_DDC' })}
        </Heading>
      }
      open={isOpen}
      onClose={onClose}
      data-testid="modal-change-ddc">
      <Paragraph size="small">
        {formatMessage({ id: 'Components.ModalChangeDDC.Paragraph.FIND_ANOTHER_DDC' })}
      </Paragraph>

      <Styled.SelectContainer>
        <Styled.Label>
          {formatMessage({ id: 'Components.ModalChangeDDC.Select.LABEL' })}
        </Styled.Label>
        <SelectWithSearch
          ddc={currentDdc}
          setDdc={setCurrentDdc}
          name="DDC"
          searchPlaceholder={formatMessage({
            id: 'Components.ModalChangeDDC.Select.Search.PLACEHOLDER',
          })}
          showAllMessage={formatMessage({
            id: 'Components.ModalChangeDDC.Select.SHOW_ALL',
          })}
        />
      </Styled.SelectContainer>

      <Styled.FooterModalContainer>
        <Button size="medium" variant="secondary" onClick={onClose} data-testid={TEST_IDS.CANCEL}>
          {formatMessage({ id: 'Components.ModalChangeDDC.Button.CANCEL' })}
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={() => handleChangeClick(currentDdc)}
          data-testid={TEST_IDS.CHANGE}>
          {formatMessage({ id: 'Components.ModalChangeDDC.Button.CHANGE' })}
        </Button>
      </Styled.FooterModalContainer>
    </Dialog.Root>
  );
};
