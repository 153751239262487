import { axiosInstance } from '@bees-force-shared/services';

import { VISIT_DETAILS_ENDPOINTS } from '@/constructors/Visits/VisitsConstructor';
import {
  DeleteVisitRequestHeader,
  DeleteVisitRequestQuery,
} from '@/types/domains/DeleteVisitDomain';

export async function deleteVisit(
  query: DeleteVisitRequestQuery,
  headers: DeleteVisitRequestHeader,
): Promise<void> {
  await axiosInstance.delete(VISIT_DETAILS_ENDPOINTS.DELETE_VISIT({ visitId: query.visitId }), {
    headers: { vendorId: headers.vendorId },
  });
}
