import { useQuery } from '@bees-force-shared/services';

import { GetAccountRequestQuery } from '@/types/domains/GetAccountDomain';
import { getAccountUseCase } from '@/useCases/account/GetAccountUseCase';

export function useAccount(requestQuery: GetAccountRequestQuery) {
  const account = useQuery({
    queryKey: ['account', requestQuery],
    queryFn: () => getAccountUseCase(requestQuery),
    enabled: !!requestQuery.accountId,
    refetchOnWindowFocus: false,
  });

  return {
    account,
  };
}
