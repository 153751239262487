import { useEffect, useState, useCallback } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { $userSelection } from '@bees-force-shared/services';
import { useUnit } from 'effector-react';

import {
  Ddc,
  UseDistributionCentersReturn,
} from '@/components/ModalChangeDDC/types/ModalChangeDDCTypes';
import { useQueryDistributionCenters } from '@/reactQueryHooks/distributionCenters/useQueryDistributionCenters';
import { setSelectedDdc } from '@/stores/userInfoStore/UserInfoEvents';
import { UserInfoStore } from '@/stores/userInfoStore/UserInfoStore';

const SEARCH_DEBOUNCE_TIME = 300;

function useDistributionCenters(): UseDistributionCentersReturn {
  const state = useUnit($userSelection);
  const vendorId = state?.operation?.vendorId ?? '';

  const [page, setPage] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [ddcName, setDdcName] = useState('');
  const [ddcList, setDdcList] = useState<Ddc[]>([]);
  const [metadataId, setMetadataId] = useState<string>();
  const [isDdcListDeprecated, setIsDdcListDeprecated] = useState<boolean>(true);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [invalidSearchTerm, setInvalidSearchTerm] = useState<string>('');

  const removeDuplicates = useCallback((ddcListToValidate: Ddc[]): Ddc[] => {
    return ddcListToValidate.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id),
    );
  }, []);

  const handleErrorResponse = useCallback(() => {
    setIsDdcListDeprecated(false);
    setPage(0);
    setHasError(true);
    setInvalidSearchTerm(ddcName);
  }, [ddcName]);

  const updateDdcList = useCallback(
    (newItems: Ddc[], shouldReplace) => {
      if (shouldReplace) {
        setDdcList(removeDuplicates(newItems));
      } else {
        setDdcList((prevList) => removeDuplicates([...prevList, ...newItems]));
      }
    },
    [removeDuplicates],
  );

  const { ddc: value } = useUnit(UserInfoStore);

  const { isLoadingDdc, ddcData, isSuccessDdc } = useQueryDistributionCenters({
    ddcName,
    handleErrorResponse,
    isDdcListDeprecated,
    page,
    vendorId,
  });

  const handleDdcScrollEnd = useCallback(() => {
    if (hasNextPage) {
      setIsDdcListDeprecated(true);
      setPage((prevPage) => prevPage + 1);
    }
  }, [hasNextPage]);

  const [infiniteScrollTriggerRef, { rootRef: infiniteScrollRootRef }] = useInfiniteScroll({
    loading: isLoadingDdc,
    hasNextPage: true,
    onLoadMore: handleDdcScrollEnd,
    rootMargin: '0px 0px 20px 0px',
  });

  const handleValueChange = useCallback(
    (selectedId: string) => {
      const selectedDdc = ddcList.find((ddc) => ddc.id === selectedId);

      if (selectedDdc) {
        setSelectedDdc({ ddc: { ...selectedDdc, value: selectedDdc.id } });
      } else {
        setSelectedDdc({ ddc: { id: '', name: '', value: 'default' } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ddcList, updateDdcList],
  );

  useEffect(() => {
    if (!isSuccessDdc || !ddcData) return;

    const content = ddcData.data.content;
    const pagination = ddcData.data.pagination;

    setHasError(false);

    const filteredContent = content;

    if (page === 0) {
      if (!value.id && isInitialLoad) {
        setDdcList([]);
      } else {
        updateDdcList(filteredContent, true);
      }
    } else {
      updateDdcList(filteredContent, false);
    }

    setIsDdcListDeprecated(false);
    setIsInitialLoad(false);
    setHasNextPage(pagination.totalPages > page + 1);
  }, [ddcData, isSuccessDdc, page, value, isInitialLoad, updateDdcList]);

  useEffect(() => {
    if (isSelectOpen) {
      setIsDdcListDeprecated(true);
    } else {
      setHasError(false);
      setInvalidSearchTerm('');
      setDdcName('');
      setPage(0);
      setHasNextPage(false);
      setIsDdcListDeprecated(true);
      setMetadataId('');
    }
  }, [isSelectOpen]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setPage(0);
      setMetadataId(ddcName);
      setIsDdcListDeprecated(true);
      setHasError(false);
    }, SEARCH_DEBOUNCE_TIME);

    return () => clearTimeout(timeOutId);
  }, [ddcName, metadataId, value, invalidSearchTerm]);

  useEffect(() => {
    if (isInitialLoad && isSuccessDdc) {
      setIsInitialLoad(false);
    }
  }, [isSuccessDdc, isInitialLoad]);

  return {
    ddcList,
    ddcData: ddcData?.data.content,
    value,
    isLoadingDdc,
    hasError,
    setDdcName,
    handleValueChange,
    infiniteScrollRootRef,
    infiniteScrollTriggerRef,
    setIsSelectOpen,
  };
}

export { useDistributionCenters };
