import { VisitDetailsCard } from '@/components/VisitDetailsCard/VisitDetailsCard';
import { VisitsActions } from '@/components/VisitsActions/VisitsActions';

import { useVisits } from './hooks/useVisitDetails';
import * as Styled from './VisitDetails.styles';

export const VisitDetails = (): JSX.Element => {
  useVisits();

  return (
    <Styled.VisitsPage>
      <VisitsActions disabled={true} />
      <VisitDetailsCard />
    </Styled.VisitsPage>
  );
};
