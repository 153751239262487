export enum DateFormats {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
  SHORT_REVERSE = 'shortReverse',
  LONG_ORDINAL = 'longOrdinal',
}

export const STATUS = {
  JUSTIFIED: 'justified',
  SCHEDULED: 'scheduled',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
};
