import { constructEndpoint } from '@bees-force-shared/services';

import { SERVICE_VISITS_ADMIN_BFF } from '@/consts/Endpoints';
import { GetAccountRequestQuery } from '@/types/domains/GetAccountDomain';

enum ENDPOINTS {
  GET_ACCOUNT = 'account',
}

export const ACCOUNT_ENDPOINTS = {
  GET_ACCOUNT: (params: GetAccountRequestQuery) =>
    constructEndpoint({
      service: SERVICE_VISITS_ADMIN_BFF,
      endpoint: ENDPOINTS.GET_ACCOUNT,
      queryParams: { ...params },
    }),
};
