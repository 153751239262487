import { SEARCH_PARAMETERS_SEGMENT } from '@/components/TableVisitCard/consts/TableVisits';
import analytics, { ScreenNameApp } from '@/config/typewriter';
import { SEARCH_PARAMETERS } from '@/enums/searchEnum';
import { useAnalytics } from '@/hooks/useAnalytics/useAnalytics';
import { ComponentsName, SearchTableVisitsProps } from '@/types/AnalyticsTypes';

export const useSegmentSearchVisits = () => {
  const { defaultProps } = useAnalytics();

  const dispatchSearchEvent = (searchValue, filterValue) => {
    const formattedButton =
      filterValue === SEARCH_PARAMETERS.ACCOUNT_ID
        ? SEARCH_PARAMETERS_SEGMENT.ACCOUNT_ID
        : SEARCH_PARAMETERS_SEGMENT.BDR_ID;

    analytics.searchVisits({
      ...defaultProps,
      [SearchTableVisitsProps.buttonName]: formattedButton,
      [SearchTableVisitsProps.searchContent]: searchValue,
      [SearchTableVisitsProps.componentName]: ComponentsName.tableVisits,
      [SearchTableVisitsProps.screenNameApp]: ScreenNameApp.Visit,
      [SearchTableVisitsProps.failureReason]: '',
    });
  };

  return {
    dispatchSearchEvent,
  };
};
