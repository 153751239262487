import MessageMap from '../i18n.d';

export const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Apenas opção em inglês disponível',
  },
  Pages: {
    Visits: {
      Title: {
        PAGE_VISITS: 'Visitas',
      },
    },
  },
  Components: {
    Overview: {
      Title: {
        OVERVIEW: 'Visão Geral',
        CANCELLED_VISITS: '% Visitas canceladas',
        BDRS_WITHOUT_VISITS: '% BDRs sem visitas',
        TOTAL_OF_VISITS: 'Total de visitas',
      },
      Label: {
        CANCELLED_VISITS_TOTAL: ' de [total] Visitas',
        BDRS_WITHOUT_VISITS_TOTAL: 'de [total] BDRs',
        TOTAL_OF_VISITS_COUNT: 'Total',
        LAST_UPDATE: 'Última atualização [lastUpdate] horas atrás.',
        FILTER_DESCRIPTION: 'Selecione o intervalo de dados que você gostaria de ver.',
      },
      Button: {
        REFRESH: 'Atualizar',
      },
      Error: {
        REFRESH_ERROR: 'Não foi possível atualizar',
      },
      Filter: {
        Periods: {
          TODAY: 'Hoje',
          YESTERDAY: 'Ontem',
          TOMORROW: 'Amanhã',
          OTHER: 'Outro',
        },
      },
      Graph: {
        TITLE: 'Por status',
        SCHEDULED: 'Agendada',
        IN_PROGRESS: 'Em andamento',
        COMPLETED: 'Concluída',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
      },
    },
    VisitTableCard: {
      SearchBar: {
        ACCOUNT_ID: 'ID da Conta',
        BDR_ID: 'ID do BDR',
        BDR_NAME: 'Nome do BDR',
        SEARCH_BY: 'Pesquisar por',
      },
      Buttons: {
        SEARCH_BUTTON: 'Pesquisar',
        FILTERS_BUTTON: 'Filtros',
        APPLY_BUTTON: 'Aplicar',
        CANCEL_BUTTON: 'Cancelar',
        CLEAR_ALL_BUTTON: 'Limpar tudo',
      },
      Title: {
        CARD_VISITS: 'Visitas',
      },
      Error: {
        ACCOUNT_ID_ERROR: 'ID da conta [id] não existe.',
        BDR_ID_ERROR: 'BDR [id] não existe.',
        BDR_NAME_ERROR: 'BDR [name] não existe.',
      },
      StatusVisits: {
        STATUS: 'Status',
        SCHEDULED: 'Agendada',
        IN_PROGRESS: 'Em andamento',
        COMPLETED: 'Concluída',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
      },
      TableVisits: {
        SHOWING_RESULTS: 'Mostrando [results] visitas',
        BDR_ID: 'ID do BDR',
        BDR_NAME: 'Nome do BDR',
        BDR_ACCOUNT_ID: 'ID da conta',
        VISIT_DATE: 'Data da visita',
        MIN_TIME: 'Tempo Min.',
        MAX_TIME: 'Tempo Máx.',
        MINUTES: 'Minutos',
        QUANTITY_INDICATION: 'de',
      },
      Filter: {
        FILTERED_BY: 'Filtrado por',
      },
    },
    ModalBDRsWithoutVisits: {
      Title: {
        BDRS_WITHOUT_VISITS: 'BDRs sem visitas',
      },
      Label: {
        SHOWING_RESULTS: 'Mostrando [results] resultados',
        BDR_ID: 'ID do BDR',
        BDR_NAME: 'Nome do BDR',
        DATE_WITHOUT_VISITS: 'Data sem visita',
        SEARCH_BY: 'Pesquisar por ID do BDR',
        PAGINATION_INDICATION: 'de',
        RANGE_INDICATION: 'a',
      },
    },
    VisitDetails: {
      Title: {
        VISIT_DETAILS: 'Detalhes da visita',
        POC_INFORMATION: 'Informação de POC',
        NEXT_VISIT: 'Próxima visita',
        BDR_INFORMATION: 'Informação de BDR',
        VISIT_INFORMATION: 'Informação da visita',
        CANCEL_VISIT: 'Cancelar visita?',
      },
      Label: {
        VISIT_DATE: 'Data da visita',
        VISIT_STATUS: 'Status da visita',
        SUGGESTED_TIME: 'Tempo sugerido',
        MINIMUM_TIME: 'Tempo mínimo',
        MAXIMUM_TIME: 'Tempo máximo',
        NO_NEXT_VISIT: 'Não há próxima visita agendada para este PDV.',

        POC_NAME: 'Nome do POC',
        POC_ID: 'ID do POC',
        POC_LATITUDE: 'Latitude',
        POC_LONGITUDE: 'Longitude',
        NO_POC_INFORMATION: 'Não há informações de POC para esta visita.',

        BDR_NAME: 'Nome do BDR',
        BDR_ID: 'Identificação do BDR',
        NO_BDR_INFORMATION: 'Não há informações do BDR para este POC.',

        SHOW_VISIT_DETAILS: 'Mostrar detalhes da visita',
        MINUTES: '[min] minutos',
        NO_DATA_AVAILABLE: 'Nenhum dado disponível',
        CANCELED_DUE_TO: 'Esta visita foi cancelada pelo sistema devido a [error].',

        CANCEL_VISIT_DESCRIPTION:
          'Esta ação pode ser desfeita enquanto a data permanecer no futuro.',
      },
      Button: {
        CANCEL_MODAL: 'Cancelar',
        GO_BACK: 'Não, voltar',
        CANCEL_VISIT: 'Sim, cancelar',
      },
      Toast: {
        SUCCESS: 'Visita cancelada',
      },
    },
    FilterModalCalendar: {
      Button: {
        SAVE: 'Salvar',
        RESET: 'Reiniciar',
      },
    },
    BadgeStatus: {
      Label: {
        STATUS: 'Status',
        SCHEDULED: 'Agendada',
        IN_PROGRESS: 'Em andamento',
        COMPLETED: 'Concluída',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
      },
    },
    EditModal: {
      Label: {
        EDIT_VISIT: 'Editar visita',
        VISIT_DATE: 'Data da visita',
        VISIT_STATUS: 'Status da visita',
        NEXT_VISIT: 'Próxima visita',
        SCHEDULED: 'Agendado',
        CANCELED: 'Cancelado',
        NO_DATA_AVAILABLE: 'Nenhum dado disponível',
        SAVED_CHANGE: 'Alterações salvas',
      },
      Button: {
        CANCEL: 'Cancelar',
        SAVE_CHANGES: 'Salvar alterações',
        EDIT: 'Editar',
        RESET: 'Reiniciar',
        SAVE: 'Salvar',
      },
    },
  },
};
