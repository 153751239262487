import { Combobox, ComboboxList } from '@ariakit/react/combobox';
import { tokens } from '@bees-force-shared/theme';
import { Content, Icon, Item, ItemIndicator, Trigger } from '@radix-ui/react-select';
import styled from 'styled-components';

export const SelectContent = styled(Content)`
  z-index: 50;
  min-width: var(--radix-popper-anchor-width);
  box-shadow: ${tokens.shadow.elevation['elevation-4']};
  border-radius: ${tokens.measure['border-radius'].small};
  color: ${tokens.color.foundation.txt.default};
  background-color: ${tokens.color.foundation.bg['primary-clear']};
  max-height: min(var(--radix-select-content-available-height), 336px);
`;

export const SelectTrigger = styled(Trigger)`
  width: 100%;
  border: 1px solid ${tokens.color.foundation.border.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${tokens.color.foundation.bg['primary-clear']};
  font-family: ${tokens.fonts['font-family']['Work Sans']};
  font-weight: ${tokens.fonts['font-weight'].medium};
  font-size: ${tokens.measure['font-size']['fontsize-3']};
  line-height: ${tokens.measure['line-height']['lineheight-6']};
  margin-top: ${tokens.measure.space['space-1']};
  color: ${tokens.color.foundation.txt.default};
  padding-top: ${tokens.measure.space['space-3']};
  padding-bottom: ${tokens.measure.space['space-3']};
  padding-left: ${tokens.measure.space['space-4']};
  padding-right: ${tokens.measure.space['space-4']};
  height: 48px;
  border-radius: ${tokens.measure['border-radius'].small};

  &:focus {
    outline-width: 1px !important;
    outline-style: solid !important;
  }
  &:hover {
    border: 1px solid ${tokens.color.foundation.border.default};
  }
  &:disabled {
    color: ${tokens.color.foundation.txt.disabled};
    border: 1px solid ${tokens.color.foundation.border.disabled};
    cursor: auto;
  }
`;

export const ValueContainer = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: left;
`;

const ItemStyle = `  position: relative;
  display: flex;
  font-family: ${tokens.fonts['font-family']['Work Sans']};
  height: 2.5rem;
  cursor: default;
  align-items: center;
  border-radius: 0.25rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  color: black;
  outline: 2px solid transparent;
  outline-offset: 2px;
  `;

export const SelectItem = styled(Item)`
  scroll-margin-top: 0.25rem;
  scroll-margin-bottom: 0.25rem;
  ${ItemStyle}
  &[data-active-item] {
    background-color: ${tokens.color.foundation.bg.info.secondary};
  }
`;

export const SelectItemNotFound = styled.span`
  ${ItemStyle}
  color: ${tokens.color.foundation.txt.secondary};
`;

export const ComboboxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  padding-bottom: 0px;
`;

export const ComboboxIcon = styled.div`
  display: flex;
  pointer-events: none;
  position: absolute;
  left: 1.1875rem;
  color: ${tokens.color.foundation.icon.secondary};
`;

export const ComboboxComponent = styled(Combobox)`
  height: 3.25rem;
  padding-right: 1rem;
  padding-left: 3.25rem;
  font-family: ${tokens.fonts['font-family']['Work Sans']};
  font-weight: ${tokens.fonts['font-weight'].normal};
  font-size: ${tokens.measure['font-size']['fontsize-3']};
  line-height: ${tokens.measure['line-height']['lineheight-6']};
  color: ${tokens.color.component.txt.input.default};
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 100%;
  border: none;
  border-bottom: 1px solid ${tokens.color.foundation.border.secondary};

  &::placeholder {
    color: ${tokens.color.foundation.txt.secondary};
  }
`;

export const Listbox = styled(ComboboxList)`
  overflow-y: auto;
  padding: 0.25rem;
`;

export const Indicator = styled(ItemIndicator)`
  display: flex;
  position: absolute;
  right: 0.375rem;
  color: ${tokens.color.foundation.icon.info};
`;

export const SelectIcon = styled(Icon)`
  display: flex;
`;

export const LoadingInfinityScroll = styled.div`
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  height: 20px;
  margin-block: 0.5rem;
  justify-content: center;
  align-items: center;
`;
