import styled from 'styled-components';

export const VisitsPage = styled.div`
  margin: 0rem 1.5rem;
  padding-bottom: 2.5rem;
`;

export const VisitsActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
