import analytics, { ScreenNameApp } from '@/config/typewriter';
import { ComponentName } from '@/enums/filterVisits';
import { useAnalytics } from '@/hooks/useAnalytics/useAnalytics';
import { FilterVisitsProps } from '@/types/AnalyticsTypes';

export const useSegmentFilterVisits = () => {
  const { defaultProps } = useAnalytics();

  const callFilterVisitsEvent = (buttonName: string, selectedStatus: string[]) => {
    const formattedStatus = selectedStatus.map((status) => status.split('-')[1]);

    const filterVisitsProps = {
      ...defaultProps,
      [FilterVisitsProps.screenNameApp]: ScreenNameApp.Visit,
      [FilterVisitsProps.componentName]: ComponentName.filterVisits,
      [FilterVisitsProps.buttonName]: buttonName,
      [FilterVisitsProps.status]: formattedStatus,
      [FilterVisitsProps.failureReason]: '',
    };
    analytics.filterVisits(filterVisitsProps);
  };

  return {
    callFilterVisitsEvent,
  };
};
