import { queryClient } from '@bees-force-shared/business-components';
import { useMutation, useQuery } from '@bees-force-shared/services';

import { GetVisitDetailsRequestQuery } from '@/types/domains/GetVisitDetailsDomain';
import { UpdateVisitRequestBody } from '@/types/domains/UpdateVisitDomain';
import { deleteVisitUseCase } from '@/useCases/visits/DeleteVisitUseCase';
import { getVisitDetailsUseCase } from '@/useCases/visits/GetVisitDetailsUseCase';
import { updateVisitUseCase } from '@/useCases/visits/UpdateVisitUseCase';

export function useQueryVisitDetails(params: GetVisitDetailsRequestQuery) {
  const visitDetails = useQuery({
    queryKey: ['visitDetails', params],
    queryFn: () => {
      if (!params.id) return;
      return getVisitDetailsUseCase(params);
    },
    retry: 3,
    refetchOnWindowFocus: false,
  });

  const patchVisit = useMutation({
    mutationKey: ['visitDetails', params],
    mutationFn: ({ id, data }: { id: string; data: UpdateVisitRequestBody }) =>
      updateVisitUseCase({ ...params, id }, data),
    onSuccess: () => {
      queryClient.invalidateQueries(['visitDetails']);
      queryClient.invalidateQueries(['visits']);
    },
  });

  const deleteVisit = useMutation({
    mutationKey: ['visitDetails'],
    mutationFn: (visitId: string) =>
      deleteVisitUseCase({ visitId }, { vendorId: params.vendorId as string }),
    onSuccess: () => {
      queryClient.invalidateQueries(['visitDetails']);
      queryClient.invalidateQueries(['visits']);
    },
  });

  return {
    visitDetails,
    actions: {
      patchVisit,
      deleteVisit,
    },
  };
}
