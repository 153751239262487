import { theme } from '@hexa-ui/theme';
import styled from 'styled-components';

export const WrapperHorizontalStackedBar = styled.div`
  background-color: ${theme.colors.neutral0.value};
  width: 100%;
`;

export const WrapperOverviewInfoContainer = styled.div``;

export const WrapperOverviewInfo = styled.div`
  display: flex;
  gap: 4px;
`;

export const WrapperOverviewInfoPercentage = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const WrapperHorizontalStackedBarStatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const WrapperHorizontalStackedBarStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    margin-right: 8px;
  }
`;
export const WrapperHorizontalStackedBarStatusBar = styled.div`
  border-radius: 10px;
  width: 100%;
  height: 8px;
  margin: 4px 0px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
`;

export const WrapperHorizontalStackedBarDot = styled.div<{ color?: string }>`
  background-color: ${({ color }) => color};
  border-radius: 50px;
  width: 12px;
  height: 12px;
  margin: 2px 3px;
`;

export const WrapperHorizontalStackedBarPiece = styled.div<{
  color?: string;
  width?: number;
  brstart?: string;
  brend?: string;
}>`
  background-color: ${({ color }) => color};
  width: ${({ width }) => width || 0}%;
  border-top-left-radius: ${({ brstart }) => brstart};
  border-top-right-radius: ${({ brend }) => brend};

  border-bottom-left-radius: ${({ brstart }) => brstart};
  border-bottom-right-radius: ${({ brend }) => brend};
  height: 12px;
`;
