import { useQuery } from '@bees-force-shared/services';
import { AxiosError, AxiosResponse } from 'axios';

import { DDCResponse } from '@/components/ModalChangeDDC/types/ModalChangeDDCTypes';
import { GetDistributionCentersRequestQuery } from '@/types/domains/GetDistributionCentersDomain';
import { getDistributionCentersUseCase } from '@/useCases/distributionCenters/GetDistributionCentersUseCase';

export function useQueryDistributionCenters({
  page,
  vendorId,
  ddcName,
  handleErrorResponse,
  isDdcListDeprecated,
}: GetDistributionCentersRequestQuery) {
  const {
    isLoading: isLoadingDdc,
    isSuccess: isSuccessDdc,
    data: ddcData,
  } = useQuery<AxiosResponse<DDCResponse>, AxiosError>({
    queryKey: ['ddc-list', { page, vendorId, ddcName }],
    queryFn: ({ signal }) => getDistributionCentersUseCase({ vendorId, page, signal, ddcName }),
    onError: () => {
      handleErrorResponse();
    },
    enabled: isDdcListDeprecated,
    retry: (failureCount, error) => {
      if (error?.response?.status === 400) {
        return false;
      }
      return failureCount < 3;
    },
  });

  return {
    isLoadingDdc,
    isSuccessDdc,
    ddcData,
  };
}
