import { $userSelection } from '@bees-force-shared/services';
import { useUnit } from 'effector-react';

import { ErrorImage } from '@/components/Atoms/ErrorImage/ErrorImage';
import { useQueryVisitList } from '@/reactQueryHooks/visits/useQueryVisitList';
import { FiltersKpisParametersStore } from '@/stores/filtersKpisStore/FiltersKpisParametersStore';
import { UserInfoStore } from '@/stores/userInfoStore/UserInfoStore';

import { TABLE_VISITS_TEST_IDS } from '../../consts/TableVisits';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import * as Styled from './ErrorContent.styles';

export const ErrorContent = (): JSX.Element => {
  const { startDate, endDate } = useUnit(FiltersKpisParametersStore);

  const state = useUnit($userSelection);
  const vendorId = state?.operation?.vendorId ?? '';
  const { ddc } = useUnit(UserInfoStore);

  const { reloadButton, errorContent } = useTranslation();

  const useQueryVisitListParams = {
    vendorId: vendorId,
    startDate,
    endDate,
    page: 1,
  };

  if (ddc.id !== '') {
    useQueryVisitListParams['deliveryCenterId'] = ddc.id;
  }

  const { refetchVisits } = useQueryVisitList(useQueryVisitListParams);

  return (
    <Styled.ErrorContentContainer data-testid={TABLE_VISITS_TEST_IDS.ERROR_CONTENT}>
      <ErrorImage />

      <Styled.ErrorContentLabel>{errorContent}</Styled.ErrorContentLabel>
      <Styled.ErrorContentButton
        elevated
        size="medium"
        variant="secondary"
        onClick={() => refetchVisits()}>
        <Styled.ErrorButtonIcon />
        {reloadButton}
      </Styled.ErrorContentButton>
    </Styled.ErrorContentContainer>
  );
};
