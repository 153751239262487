import { Button, Card, Heading } from '@hexa-ui/components';

import { FilteredBy } from './components/FilteredBy/FilteredBy';
import { FilterVisits } from './components/FiltersVisits/FiltersVisits';
import { SearchWithSelect } from './components/SearchWithSelect/SearchWithSelect';
import { TableVisits } from './components/TableVisits/TableVisits';
import { useSearchVisits } from './hooks/useSearchVisits/useSearchVisits';
import { useTableVisitCard } from './hooks/useTableVisitCard/useTableVisitCard';
import { useTranslation } from './hooks/useTranslation/useTranslation';
import * as Styled from './TableVisitCard.styles';
import { CARD_COMPONENT_TEST_IDS as TEST_IDS } from './TableVisitConsts';

export const TableVisitCard = () => {
  const { searchButton, titleCardVisits } = useTranslation();
  const { activeFilters, onApplyFilter, onRemoveFilter } = useTableVisitCard();

  const { searchValue, setFilterValue, setSearchValue, onClickSearch, onClearSearch, filterValue } =
    useSearchVisits();

  return (
    <Card
      border="medium"
      elevated="small"
      style={{ padding: 0, marginTop: 24, marginBottom: 24 }}
      data-testid={TEST_IDS.TABLE_VISIT_CARD}>
      <Styled.VisitTableCardHeader>
        <Heading size="H3" style={{ marginBottom: 16 }} data-testid={TEST_IDS.TABLE_VISIT_HEADER}>
          {titleCardVisits}
        </Heading>
        <Styled.VisitTableCardContainer>
          <FilterVisits onApplyFilter={onApplyFilter} appliedFilters={activeFilters} />
          <SearchWithSelect
            filterValue={filterValue}
            searchValue={searchValue || ''}
            onChangeFilterValue={setFilterValue}
            onChangeSearch={(e) => setSearchValue(e.target.value)}
            onClear={onClearSearch}
            data-testid={TEST_IDS.SEARCH_WITH_SELECT}
          />
          <Button
            size="medium"
            variant="secondary"
            onClick={onClickSearch}
            data-testid={TEST_IDS.SEARCH_BUTTON}
            style={{ minWidth: 100 }}>
            {searchButton}
          </Button>
        </Styled.VisitTableCardContainer>
        <FilteredBy activeFilters={activeFilters} handleClearFilter={onRemoveFilter} />
      </Styled.VisitTableCardHeader>
      <TableVisits />
    </Card>
  );
};
