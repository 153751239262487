import React from 'react';

import { ComboboxProvider as OriginalComboboxProvider } from '@ariakit/react/combobox';

interface ExtendedComboboxProviderProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  resetValueOnHide: boolean;
  includesBaseElement: boolean;
  setValue: (value: string) => void;
}

export const ComboboxProvider = OriginalComboboxProvider as React.FC<ExtendedComboboxProviderProps>;
