export interface UserInfoState {
  ddc: {
    id: string;
    name: string;
    value?: string;
  };
}

export const userInfoInitialState: UserInfoState = {
  ddc: {
    id: '',
    name: '',
    value: '',
  },
};

export const LOCAL_STORAGE_KEY = '@ddc_1.0.0';
