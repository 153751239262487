import { useIntl } from 'react-intl';

import { LoadingContent, ErrorPage } from '@bees-force-shared/components';
import { useOperations } from '@bees-force-shared/services';

import { Overview } from '@/components/Overview/Overview';
import { TableVisitCard } from '@/components/TableVisitCard/TableVisitCard';

import * as Styled from './VisitsContent.styles';

export const VisitsContent = () => {
  const { formatMessage } = useIntl();
  const { operations } = useOperations();

  if (operations.isFetching) {
    return (
      <Styled.WrapperLoadingStatus>
        <LoadingContent text={formatMessage({ id: 'Pages.Visits.Status.LOADING' })} />
      </Styled.WrapperLoadingStatus>
    );
  }

  if (operations.isError) {
    return (
      <ErrorPage
        subtitle={formatMessage({
          id: 'Pages.Visits.Status.Error.SUBTITLE',
        })}
        errorMessage={formatMessage({
          id: 'Pages.Visits.Status.Error.ERROR_MESSAGE',
        })}
        onRefreshText={formatMessage({
          id: 'Pages.Visits.Status.Error.ON_REFRESH',
        })}
        onRefresh={() => operations.refetch()}
      />
    );
  }

  return (
    <>
      <Overview />
      <TableVisitCard />
    </>
  );
};
