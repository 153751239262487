import { LoadingContent, EmptyContent } from '@bees-force-shared/components';
import { $userSelection, useFeatureToggleEnabledByVendorId } from '@bees-force-shared/services';
import { Heading } from '@hexa-ui/components';
import { useUnit } from 'effector-react';

import { FEATURE_TOGGLE_ENABLED_BDR_WITHOUT_VISITS } from '@/consts/FeatureToggles';
import { useQueryKpis } from '@/reactQueryHooks/visits/useQueryKpis';
import { useQueryVisitList } from '@/reactQueryHooks/visits/useQueryVisitList';
import { FiltersKpisParametersStore } from '@/stores/filtersKpisStore/FiltersKpisParametersStore';
import { UserInfoStore } from '@/stores/userInfoStore/UserInfoStore';
import { useVisitsParamStore } from '@/stores/visitsParams/VisitsParamsStore';
import { getFormattedMessage } from '@/utils/getFormattedMessage/getFormattedMessage';
import { quantityFormatter } from '@/utils/quantityFormatter/quantityFormatter';

import { ModalBDRsWithoutVisits } from '../ModalBDRsWithoutVisits/ModalBDRsWithoutVisits';
import { useTranslation } from '../TableVisitCard/hooks/useTranslation/useTranslation';
import { CardComponent } from './components/CardComponent/CardComponent';
import { ErrorComponent } from './components/ErrorComponent/ErrorComponent';
import { Filters } from './components/Filters/Filters';
import { OverviewRefreshData } from './components/OverviewRefreshData/OverviewRefreshData';
import { TotalVisitsCard } from './components/TotalVisitsCard/TotalVisitsCard';
import { OVERVIEW } from './consts/OverviewConsts';
import { useOverview } from './hooks/useOverview';
import * as Styled from './Overview.styles';

export const Overview = (): JSX.Element => {
  const { ddc } = useUnit(UserInfoStore);

  const { startDate, endDate } = useUnit(FiltersKpisParametersStore);
  const { status } = useVisitsParamStore();

  const { noContent } = useTranslation();

  const state = useUnit($userSelection);
  const vendorId = state?.operation?.vendorId ?? '';

  const useQueryVisitListParams = {
    vendorId: vendorId,
    startDate,
    endDate,
    status,
    page: 1,
  };

  const useQueryKpisParams = {
    vendorId: [vendorId],
    startDate,
    endDate,
  };

  if (ddc.id !== '') {
    useQueryKpisParams['deliveryCenterId'] = ddc.id;
    useQueryVisitListParams['deliveryCenterId'] = ddc.id;
  }

  const { kpis, percentageCanceledVisits, refreshKpisData, lastTimeUpdate, errorFromRefresh } =
    useQueryKpis(useQueryKpisParams);

  const { errorFromRefresh: errorFromRefreshVisits, refreshVisitsData } =
    useQueryVisitList(useQueryVisitListParams);

  const { cardRef, formatMessage, handleClickRefreshButton, handleOpenOrCloseModal, isModalOpen } =
    useOverview();

  const { isEnabled: showBDRCard } = useFeatureToggleEnabledByVendorId(
    FEATURE_TOGGLE_ENABLED_BDR_WITHOUT_VISITS,
  );

  const hasError = Boolean((errorFromRefresh || errorFromRefreshVisits) && !kpis.isFetching);
  const lastTimeUpdateMessage =
    lastTimeUpdate &&
    getFormattedMessage(formatMessage({ id: 'Components.Overview.Label.LAST_UPDATE' }), {
      lastUpdate: lastTimeUpdate,
    });

  const kpisIsEmpty = Number(kpis.data?.totalOfVisits) === 0;
  const kpisWithData = !kpis.isFetching && kpis.data && !kpisIsEmpty;

  return (
    <Styled.CardStatus ref={cardRef} border="medium" elevated="small">
      <ModalBDRsWithoutVisits isOpen={isModalOpen} onClose={handleOpenOrCloseModal} />
      <Styled.WrapperHeaderOverview>
        <Heading size="H3">{formatMessage({ id: 'Components.Overview.Title.OVERVIEW' })}</Heading>
        <OverviewRefreshData
          isRefetching={kpis.isFetching}
          lastTimeUpdate={lastTimeUpdateMessage}
          hasError={hasError}
          errorMessage={formatMessage({
            id: 'Components.Overview.Error.REFRESH_ERROR',
          })}
          handleClickRefresh={() => handleClickRefreshButton(refreshKpisData, refreshVisitsData)}
        />
      </Styled.WrapperHeaderOverview>

      <Filters />

      {kpis.isFetching && (
        <LoadingContent
          text={formatMessage({ id: 'Components.Overview.LOADING' })}
          testId="loading"
        />
      )}

      {kpis.isError && !kpis.data && (
        <ErrorComponent
          text={formatMessage({ id: 'Components.Overview.Error.DEFAULT_ERROR' })}
          loading={kpis.isFetching}
          buttonText={formatMessage({ id: 'Components.Overview.Error.RELOAD_BUTTON' })}
          onRefresh={() => kpis.refetch()}
        />
      )}

      {kpisIsEmpty && (
        <EmptyContent
          noContentText={noContent}
          style={{ paddingTop: 28, paddingBottom: 20, minHeight: 'unset' }}
        />
      )}

      {kpisWithData && (
        <Styled.WrapperContentOverview data-testid={OVERVIEW.CONTENT_OVERVIEW}>
          <TotalVisitsCard totalVisits={quantityFormatter(kpis.data.totalOfVisits)} />

          <CardComponent
            title={formatMessage({
              id: 'Components.Overview.Title.CANCELLED_VISITS',
            })}
            count={quantityFormatter(kpis.data.totalCancelledVisits)}
            total={getFormattedMessage(
              formatMessage({
                id: 'Components.Overview.Label.CANCELLED_VISITS_TOTAL',
              }),
              { total: quantityFormatter(kpis.data.totalOfVisits) },
            )}
            percentage={`${percentageCanceledVisits()}%`}
          />

          {showBDRCard && (
            <CardComponent
              title={formatMessage({
                id: 'Components.Overview.Title.BDRS_WITHOUT_VISITS',
              })}
              count={'10'}
              total={getFormattedMessage(
                formatMessage({
                  id: 'Components.Overview.Label.BDRS_WITHOUT_VISITS_TOTAL',
                }),
                { total: '10' },
              )}
              percentage={'5%'}
              isClickable
              handleCardClick={handleOpenOrCloseModal}
            />
          )}
        </Styled.WrapperContentOverview>
      )}
    </Styled.CardStatus>
  );
};
