import { LoadingContent, ErrorContent } from '@bees-force-shared/components';
import { Paragraph } from '@hexa-ui/components';

import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { PocInformationProps } from '../../types/VisitDetails.types';
import { EmptyCard } from '../EmptyCard/EmptyCard';
import * as Styled from './PocInformationContent.styles';

const renderInformation = (label: string, value: string | undefined, noDataLabel: string) => (
  <Styled.WrapperInformation>
    <Paragraph weight="semibold">{label}</Paragraph>
    <Paragraph>{value ? value : noDataLabel}</Paragraph>
  </Styled.WrapperInformation>
);

export const PocInformationContent = ({
  pocName = '',
  pocId = '',
  pocLatitude = '',
  pocLongitude = '',
  loading = false,
  hasError = false,
  onRefresh,
}: PocInformationProps) => {
  const {
    pocNameLabel,
    pocIdLabel,
    pocLatitudeLabel,
    pocLongitudeLabel,
    noDataAvailableLabel,
    noPocInformationLabel,
    loadingContent,
    errorContent,
    errorRefreshContent,
  } = useTranslation();

  if (loading) {
    return (
      <Styled.WrapperLoadingContent>
        <LoadingContent text={loadingContent} />
      </Styled.WrapperLoadingContent>
    );
  }

  if (hasError) {
    return (
      <ErrorContent text={errorContent} refreshText={errorRefreshContent} onRefresh={onRefresh} />
    );
  }

  if (!pocName && !pocId && !pocLatitude && !pocLongitude) {
    return <EmptyCard emptyMessage={noPocInformationLabel} />;
  }
  return (
    <Styled.WrapperInformationContent>
      {renderInformation(pocNameLabel, pocName, noDataAvailableLabel)}
      {renderInformation(pocIdLabel, pocId, noDataAvailableLabel)}
      {renderInformation(pocLatitudeLabel, pocLatitude, noDataAvailableLabel)}
      {renderInformation(pocLongitudeLabel, pocLongitude, noDataAvailableLabel)}
    </Styled.WrapperInformationContent>
  );
};
