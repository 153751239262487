import { ModalChangeOperation } from '@/components/ModalChangeOperation/ModalChangeOperation';
import { VisitOperationDDCFilter } from '@/components/VisitOperationDDCFilters/VisitOperationDDCFilters';
import { IVisitsActionsProps } from '@/types/components/VisitsActions/VisitsActions';

import * as Styled from './VisitsActions.styles';

export const VisitsActions = ({ disabled }: IVisitsActionsProps) => {
  return (
    <Styled.Container>
      <VisitOperationDDCFilter disabled={disabled} />
      <ModalChangeOperation disabled={disabled} />
    </Styled.Container>
  );
};
