export interface UseSegmentSortingVisitsProps {
  column?: string;
  direction: 'ASC' | 'DESC' | undefined;
}

import { ScreenNameApp } from '@/config/typewriter';

export enum CommonSegmentProp {
  ddc = 'ddc',
  country = 'country',
  deviceLanguage = 'device_language',
  email = 'email',
  userSelectedLanguage = 'user_selected_language',
  vendorGroupId = 'vendor_group_id',
  vendorId = 'vendor_id',
  sessionId = 'session_id',
  userGroups = 'user_groups',
  screenNameApp = 'screen_name_app',
  featureName = 'feature_name',
}

export enum ComponentsName {
  tableVisits = 'table_visits',
  filters = 'filters',
  nextVisit = 'next_visit',
  cancelVisit = 'cancel_visit',
  editVisit = 'edit_visit',
  modalChangeDDC = 'modal_change_ddc_on_visits',
  editOperationVisit = 'edit_operation_visit',
}

export enum SearchTableVisitsProps {
  buttonName = 'button_name',
  componentName = 'component_name',
  failureReason = 'failure_reason',
  screenNameApp = 'screen_name_app',
  searchContent = 'search_content',
}

export enum OnSortVisitListProps {
  componentName = 'component_name',
  orderPriority = 'order_priority',
  screenNameApp = 'screen_name_app',
  sortingInfo = 'sorting_info',
  visit = 'visit',
  visit_date = 'visit_date',
  failureReason = 'failure_reason',
  unknownError = 'unknown_error',
}

export enum FilterVisitsProps {
  screenNameApp = 'screen_name_app',
  componentName = 'component_name',
  buttonName = 'button_name',
  status = 'status',
  failureReason = 'failure_reason',
}

export enum VisitListSegmentProps {
  ButtonName = 'button_name',
  VisitID = 'visit_id',
  ComponentName = 'component_name',
  FailureReason = 'failure_reason',
  unknownError = 'unknown_error',
}

export enum onRefreshVisitListSegmentProps {
  ButtonName = 'button_name',
  FailureReason = 'failure_reason',
  ComponentName = 'component_name',
  unknownError = 'unknown_error',
}

export enum DateFilterSegmentProps {
  ScreenNameApp = 'screen_name_app',
  ButtonName = 'button_name',
  SelectedDateFilter = 'selectedDateFilter',
  Date = 'date',
  FailureReason = 'failure_reason',
  ComponentName = 'component_name',
  UnknownError = 'unknown_error',
}

export enum VisitDetailsSegmentProps {
  BackVisitList = 'back_visit_list',
  VisitDetails = 'visit_details',
  NextVisit = 'next_visit',
  ButtonName = 'button_name',
  FailureReason = 'failure_reason',
  ComponentName = 'component_name',
  unknownError = 'unknown_error',
}

export enum VisitEditSegmentProps {
  ButtonName = 'button_name',
  VisitID = 'visit_id',
  VisitStatus = 'visit_status',
  OldDate = 'old_date',
  NewDate = 'new_date',
  FailureReason = 'failure_reason',
  ComponentName = 'component_name',
  unknownError = 'unknown_error',
  Edit = 'edit',
}

export enum VisitCancelSegmentProps {
  CancelVisit = 'cancel_visit',
  ButtonName = 'button_name',
  Cancel = 'cancel',
  Back = 'back',
  VisitID = 'visit_id',
  FailureReason = 'failure_reason',
  ComponentName = 'component_name',
  unknownError = 'unknown_error',
}

export enum OperationSelectedSegmentProps {
  componentName = 'component_name',
  countryName = 'country_name',
  operationIdUpdate = 'operation_id_update',
  countryNameUpdate = 'country_name_update',
  organizationNameUpdate = 'organization_name_update',
  vendorIdNameUpdate = 'vendor_id_name_update',
  sellerNameUpdate = 'seller_name_update',
  unknownError = 'unknown_error',
  FailureReason = 'failure_reason',
}

export enum DdcSelected {
  FailureReason = 'failure_reason',
  ComponentName = 'component_name',
  DdcUpdate = 'ddc_update',
}

export interface VisitCancelState {
  buttonName: VisitCancelSegmentProps.Back | VisitCancelSegmentProps.Cancel;
  componentName: string;
  visitID: string | null;
}

export interface VisitEditState {
  [VisitEditSegmentProps.VisitID]: string | null;
  [VisitEditSegmentProps.VisitStatus]: string;
  [VisitEditSegmentProps.OldDate]: string;
  [VisitEditSegmentProps.NewDate]: string;
}

export interface SegmentPropsAction {
  prop: string;
  value: unknown;
}

export interface DateFilterPropsState {
  [DateFilterSegmentProps.SelectedDateFilter]: string;
  [DateFilterSegmentProps.Date]: string;
}

export interface CommonSegmentPropsState {
  [CommonSegmentProp.country]: string | null;
  [CommonSegmentProp.ddc]: string;
  [CommonSegmentProp.deviceLanguage]: string;
  [CommonSegmentProp.email]: string;
  [CommonSegmentProp.screenNameApp]: ScreenNameApp;
  [CommonSegmentProp.userSelectedLanguage]: string;
  [CommonSegmentProp.vendorGroupId]: string;
  [CommonSegmentProp.vendorId]: string;
  [CommonSegmentProp.featureName]: string;
  [CommonSegmentProp.userGroups]: string[];
  [CommonSegmentProp.sessionId]: string;
}

export interface VisitListSegmentPropsState extends CommonSegmentPropsState {
  [VisitListSegmentProps.ButtonName]: string;
  [VisitListSegmentProps.VisitID]: string;
  [VisitListSegmentProps.FailureReason]: string;
  [VisitListSegmentProps.ComponentName]: string;
}
