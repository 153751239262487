import styled, { css } from 'styled-components';

export const TableWrapper = styled.div`
  [data-testid='SortDescending'],
  [data-testid='SortAscending'],
  [data-testid='SortDefault'] {
    height: 20px;
    width: 20px;
    margin-left: 18px;
  }

  [data-testid='SortDefault'] {
    visibility: inherit;
  }
`;

type TableProps = {
  hidden?: boolean;
};

export const TableContainer = styled.div<TableProps>`
  ${({ hidden }) => css`
    visibility: ${hidden ? 'hidden' : 'visible'};
  `}
`;
