import analytics from '@/config/typewriter';
import { useAnalytics } from '@/hooks/useAnalytics/useAnalytics';
import { ComponentsName, OperationSelectedSegmentProps } from '@/types/AnalyticsTypes';

import { OperationData } from '../../types/ModalChangeOperationTypes';

export const useSegmentOperationSelected = () => {
  const { defaultProps } = useAnalytics();

  const dispatchOperationSelectedEvent = (operation: OperationData) => {
    const mapOperationSelectedProps = (operationData: OperationData, failureReason = '') => ({
      ...defaultProps,
      [OperationSelectedSegmentProps.componentName]: ComponentsName.editOperationVisit,
      [OperationSelectedSegmentProps.countryNameUpdate]: operationData.country,
      [OperationSelectedSegmentProps.operationIdUpdate]: operationData.organization?.id ?? '',
      [OperationSelectedSegmentProps.organizationNameUpdate]:
        operationData.organization?.name ?? '',
      [OperationSelectedSegmentProps.vendorIdNameUpdate]: operationData.vendorId ?? '',
      [OperationSelectedSegmentProps.sellerNameUpdate]: operationData.seller?.name ?? '',
      [OperationSelectedSegmentProps.FailureReason]: failureReason,
    });
    try {
      analytics.operationSelected(mapOperationSelectedProps(operation));
    } catch (error) {
      const failureReason =
        error instanceof Error ? error.message : OperationSelectedSegmentProps.unknownError;
      analytics.operationSelected(mapOperationSelectedProps(operation, failureReason));
    }
  };

  return {
    dispatchOperationSelectedEvent,
  };
};
