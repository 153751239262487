import { Heading } from '@hexa-ui/components';

import { ExternalLinkIcon } from '@/assets/icons/ExternalLinkIcon';

import { NEXT_VISIT_TEST_IDS } from '../../consts/VisitDetailsCardConsts';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { NextVisitInformationProps } from '../../types/VisitDetails.types';
import { NextVisitContent } from '../NextVisitContent.tsx/NextVisitContent';
import { useNextVisit } from './hooks/useNextVisit';
import * as Styled from './NextVisit.styles';

export const NextVisit = ({
  nextVisitDate,
  nextVisitStatus,
  isLoading,
  isError,
  onRefresh,
  nextVisitId,
}: NextVisitInformationProps) => {
  const { handleLinkClick } = useNextVisit();
  const { nextVisitTitle, showVisitDetailsLabel } = useTranslation();
  const shouldShowShowVisitLink = nextVisitDate && nextVisitStatus;

  return (
    <Styled.WrapperNextVisitCard border="medium" elevated="minimal">
      <Styled.WrapperHeader>
        <Heading data-testid={NEXT_VISIT_TEST_IDS.HEADER} size="H3">
          {nextVisitTitle}
        </Heading>
        {shouldShowShowVisitLink && (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <Styled.Link hasUnderline={false} onClick={() => handleLinkClick(nextVisitId!)}>
            <ExternalLinkIcon />
            {showVisitDetailsLabel}
          </Styled.Link>
        )}
      </Styled.WrapperHeader>
      <NextVisitContent
        nextVisitDate={nextVisitDate}
        nextVisitStatus={nextVisitStatus}
        isLoading={isLoading}
        isError={isError}
        onRefresh={onRefresh}
      />
    </Styled.WrapperNextVisitCard>
  );
};
