import { Fragment } from 'react';

import { RENDER_CONDITION_TEST_ID } from '@/components/NoVisual/RenderCondition/consts/RenderCondition';
import { IRenderCondition } from '@/types/components/NoVisual/RenderCondition';

export const RenderCondition = ({ children, condition }: IRenderCondition): JSX.Element => {
  if (!condition) return <Fragment data-testid={RENDER_CONDITION_TEST_ID}></Fragment>;

  return <Fragment>{children}</Fragment>;
};
