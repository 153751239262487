import { Heading, Paragraph } from '@hexa-ui/components';

import { POC_INFORMATION_TEST_IDS } from '../../consts/VisitDetailsCardConsts';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { PocInformationProps } from '../../types/VisitDetails.types';
import { EmptyCard } from '../EmptyCard/EmptyCard';
import * as Styled from './PocInformation.styles';

const renderInformation = (label: string, value: string | undefined, noDataLabel: string) => (
  <Styled.WrapperInformation>
    <Paragraph weight="semibold">{label}</Paragraph>
    <Paragraph>{value ? value : noDataLabel}</Paragraph>
  </Styled.WrapperInformation>
);

export const PocInformation = ({
  pocName = '',
  pocId = '',
  pocLatitude = '',
  pocLongitude = '',
}: PocInformationProps) => {
  const {
    pocInformationTitle,
    pocNameLabel,
    pocIdLabel,
    pocLatitudeLabel,
    pocLongitudeLabel,
    noDataAvailableLabel,
    noPocInformationLabel,
  } = useTranslation();

  if (!pocName && !pocId && !pocLatitude && !pocLongitude) {
    return <EmptyCard title={pocInformationTitle} emptyMessage={noPocInformationLabel} />;
  }

  return (
    <Styled.WrapperPocInformationCard border="medium" elevated="minimal">
      <Heading data-testid={POC_INFORMATION_TEST_IDS.HEADER} size="H3">
        {pocInformationTitle}
      </Heading>
      <Styled.WrapperInformationContent>
        {renderInformation(pocNameLabel, pocName, noDataAvailableLabel)}
        {renderInformation(pocIdLabel, pocId, noDataAvailableLabel)}
        {renderInformation(pocLatitudeLabel, pocLatitude, noDataAvailableLabel)}
        {renderInformation(pocLongitudeLabel, pocLongitude, noDataAvailableLabel)}
      </Styled.WrapperInformationContent>
    </Styled.WrapperPocInformationCard>
  );
};
