import { formatDistanceToNow } from 'date-fns';

import { loadLocale } from './loadDateLocale';

export const formatUpdateTime = (updatedAt: string, currentLocale: string) => {
  const locale = loadLocale(currentLocale);

  const formattedLastTimeUpdate = formatDistanceToNow(new Date(updatedAt), {
    locale,
  });

  return formattedLastTimeUpdate;
};
