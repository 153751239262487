import { Paragraph } from '@hexa-ui/components';

import { EmptyCardProps } from '../../types/VisitDetails.types';
import * as Styled from './EmptyCard.styles';

export const EmptyCard: React.FC<EmptyCardProps> = ({ emptyMessage }) => {
  return (
    <Styled.WrapperEmptyContent>
      <Paragraph colortype="secondary">{emptyMessage}</Paragraph>
    </Styled.WrapperEmptyContent>
  );
};
