import { getVisitDetails } from '@/services/visits/GetVisitDetails';
import { GetVisitDetailsRequestQuery, VisitDetails } from '@/types/domains/GetVisitDetailsDomain';

export const getVisitDetailsUseCase = async (
  params: GetVisitDetailsRequestQuery,
): Promise<VisitDetails> => {
  const response = await getVisitDetails(params);

  return response;
};
