import { useRef, useState } from 'react';

export function useSelectWithSearch() {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  return {
    isOpen,
    setIsOpen,
    containerRef,
  };
}
