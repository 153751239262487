import { useEffect } from 'react';

import analytics, { ScreenNameApp } from '@/config/typewriter';
import { useAnalytics } from '@/hooks/useAnalytics/useAnalytics';
import {
  ComponentsName,
  DateFilterPropsState,
  DateFilterSegmentProps,
} from '@/types/AnalyticsTypes';

export const useSegmentDateFilter = (
  dateFilter: DateFilterPropsState | null,
  resetDateFilter: () => void,
) => {
  const { defaultProps } = useAnalytics();

  useEffect(() => {
    if (!dateFilter) return;

    const segmentDateFilterProps = {
      ...defaultProps,
      [DateFilterSegmentProps.ButtonName]: dateFilter.selectedDateFilter,
      [DateFilterSegmentProps.Date]: JSON.stringify(dateFilter.date),
      [DateFilterSegmentProps.ComponentName]: ComponentsName.filters,
      [DateFilterSegmentProps.ScreenNameApp]: ScreenNameApp.Visit,
      [DateFilterSegmentProps.FailureReason]: '',
    };

    try {
      analytics.dateFilterVisits(segmentDateFilterProps);
    } catch (error) {
      analytics.dateFilterVisits({
        ...segmentDateFilterProps,
        [DateFilterSegmentProps.FailureReason]:
          error instanceof Error ? error.message : DateFilterSegmentProps.UnknownError,
      });
    } finally {
      resetDateFilter();
    }
  }, [dateFilter, defaultProps, resetDateFilter]);
};
