import { Order, VisitStatus } from '@/api/services/visits/types';

export interface VisitsState {
  vendorId: string;
  visitId: string;
  deliveryCenterId: string | null;
  bdrId?: string;
  accountId?: string;
  status: VisitStatus | null;
  page: number | null;
  pageSize: number | null;
  visitDate: string | null;
  startDate: string | null;
  endDate: string | null;
  sort: Order | null;
}

export const initialState: VisitsState = {
  vendorId: '',
  visitId: '',
  deliveryCenterId: null,
  status: null,
  page: null,
  pageSize: null,
  visitDate: null,
  startDate: null,
  endDate: null,
  sort: null,
};
