export const ACCESSOR_BDR_ID = 'bdrId';
export const ACCESSOR_BDR_ACCOUNT_ID = 'accountId';
export const ACCESSOR_BDR_NAME = 'name';
export const ACCESSOR_STATUS = 'status';
export const ACCESSOR_VISIT_DATE = 'visitDate';
export const ACCESS_MIN_TIME = 'estimatedTimeMin';
export const ACCESS_MAX_TIME = 'estimatedTimeMax';

export const STATUS = {
  JUSTIFIED: 'Justified',
  SCHEDULED: 'Scheduled',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
};

export const SEARCH_PARAMETERS_SEGMENT = {
  BDR_ID: 'bdr_id',
  ACCOUNT_ID: 'account_id',
};

export const TABLE_VISITS_TEST_IDS = {
  LOADING_CONTENT: 'table-visit-loading-content',
  ERROR_CONTENT: 'table-visit-error-content',
  EMPTY_CONTENT: 'table-visit-empty-content',
};

export interface TableVisitsRowProps {
  id: string;
  visitId: string;
  accountId: string;
  status: string;
  visitDate: string;
  estimatedTimeMin?: number;
  estimatedTimeMax?: number;
}
