import React from 'react';

import { Button } from '@hexa-ui/components';
import { RotateCw } from '@hexa-ui/icons';

import * as Styled from './ErrorComponent.style';

type ErrorComponentProps = {
  text: string;
  loading?: boolean;
  buttonText: string;
  onRefresh: () => void;
};

export const ErrorComponent: React.FC<ErrorComponentProps> = ({
  text,
  loading,
  buttonText,
  onRefresh,
}) => {
  const MemoizedIcon = React.useMemo(() => <RotateCw />, []);

  return (
    <Styled.ErrorContainer data-testid="error-container">
      <span data-testid="error-message">{text}</span>
      <Button
        data-testid="error-refresh-button"
        leading
        size="small"
        variant="secondary"
        disabled={loading}
        icon={() => MemoizedIcon}
        onClick={onRefresh}>
        {buttonText}
      </Button>
    </Styled.ErrorContainer>
  );
};
