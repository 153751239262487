export const ErrorImage = (): JSX.Element => {
  return (
    <svg
      data-testid="error-image"
      width="128"
      height="129"
      viewBox="0 0 128 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <mask id="mask0_2624_15205" maskUnits="userSpaceOnUse" x="0" y="14" width="49" height="49">
          <path
            d="M24.1148 62.3496C37.4012 62.3496 48.1719 51.5789 48.1719 38.2925C48.1719 25.0061 37.4012 14.2354 24.1148 14.2354C10.8284 14.2354 0.0576172 25.0061 0.0576172 38.2925C0.0576172 51.5789 10.8284 62.3496 24.1148 62.3496Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_2624_15205)">
          <path
            d="M24.1148 62.3496C37.4012 62.3496 48.1719 51.5789 48.1719 38.2925C48.1719 25.0061 37.4012 14.2354 24.1148 14.2354C10.8284 14.2354 0.0576172 25.0061 0.0576172 38.2925C0.0576172 51.5789 10.8284 62.3496 24.1148 62.3496Z"
            fill="url(#paint0_linear_2624_15205)"
          />
        </g>
      </g>
      <mask id="mask1_2624_15205" maskUnits="userSpaceOnUse" x="2" y="14" width="126" height="89">
        <path
          d="M106.172 60.6353C106.172 83.5496 87.6008 102.121 64.6865 102.121C41.7722 102.121 23.2008 83.5496 23.2008 60.6353L2.80078 60.921L10.9722 14.4067H121.372L127.829 60.921L106.172 60.6353Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2624_15205)">
        <mask id="mask2_2624_15205" maskUnits="userSpaceOnUse" x="23" y="19" width="84" height="84">
          <path
            d="M64.6869 102.064C87.5988 102.064 106.173 83.4899 106.173 60.578C106.173 37.6661 87.5988 19.0923 64.6869 19.0923C41.775 19.0923 23.2012 37.6661 23.2012 60.578C23.2012 83.4899 41.775 102.064 64.6869 102.064Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_2624_15205)">
          <path
            d="M64.6869 102.064C87.5988 102.064 106.173 83.4899 106.173 60.578C106.173 37.6661 87.5988 19.0923 64.6869 19.0923C41.775 19.0923 23.2012 37.6661 23.2012 60.578C23.2012 83.4899 41.775 102.064 64.6869 102.064Z"
            fill="url(#paint1_linear_2624_15205)"
          />
        </g>
      </g>
      <g opacity="0.5">
        <mask
          id="mask3_2624_15205"
          maskUnits="userSpaceOnUse"
          x="104"
          y="28"
          width="15"
          height="15">
          <path
            d="M111.772 42.5211C115.717 42.5211 118.915 39.3231 118.915 35.3782C118.915 31.4333 115.717 28.2354 111.772 28.2354C107.827 28.2354 104.629 31.4333 104.629 35.3782C104.629 39.3231 107.827 42.5211 111.772 42.5211Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_2624_15205)">
          <path
            d="M111.772 42.5211C115.717 42.5211 118.915 39.3231 118.915 35.3782C118.915 31.4333 115.717 28.2354 111.772 28.2354C107.827 28.2354 104.629 31.4333 104.629 35.3782C104.629 39.3231 107.827 42.5211 111.772 42.5211Z"
            fill="url(#paint2_linear_2624_15205)"
          />
        </g>
      </g>
      <g opacity="0.5">
        <mask id="mask4_2624_15205" maskUnits="userSpaceOnUse" x="4" y="72" width="16" height="15">
          <path
            d="M12.0003 86.7496C15.9452 86.7496 19.1431 83.5516 19.1431 79.6067C19.1431 75.6618 15.9452 72.4639 12.0003 72.4639C8.05539 72.4639 4.85742 75.6618 4.85742 79.6067C4.85742 83.5516 8.05539 86.7496 12.0003 86.7496Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_2624_15205)">
          <path
            d="M12.0003 86.7496C15.9452 86.7496 19.1431 83.5516 19.1431 79.6067C19.1431 75.6618 15.9452 72.4639 12.0003 72.4639C8.05539 72.4639 4.85742 75.6618 4.85742 79.6067C4.85742 83.5516 8.05539 86.7496 12.0003 86.7496Z"
            fill="url(#paint3_linear_2624_15205)"
          />
        </g>
      </g>
      <g opacity="0.5">
        <path
          d="M88.1719 95.2644H41.029C39.4862 95.2644 38.1719 94.0073 38.1719 92.4073V77.0359C38.1719 75.493 39.429 74.1787 41.029 74.1787H88.1719C89.7147 74.1787 91.029 75.4359 91.029 77.0359V92.4644C91.029 94.0073 89.7719 95.2644 88.1719 95.2644Z"
          fill="white"
        />
        <path
          d="M60.6865 85.6075V83.8932C60.6865 83.6075 60.9151 83.3218 61.258 83.3218H84.5151C84.8008 83.3218 85.0865 83.5503 85.0865 83.8932V85.6075C85.0865 85.8932 84.858 86.1789 84.5151 86.1789H61.258C60.9151 86.1789 60.6865 85.9503 60.6865 85.6075Z"
          fill="#818181"
          fillOpacity="0.5"
        />
        <path
          d="M60.6865 79.7789V78.0646C60.6865 77.7789 60.9151 77.4932 61.258 77.4932H86.9722C87.258 77.4932 87.5437 77.7217 87.5437 78.0646V79.7789C87.5437 80.0646 87.3151 80.3503 86.9722 80.3503H61.258C60.9151 80.3503 60.6865 80.0646 60.6865 79.7789Z"
          fill="#818181"
          fillOpacity="0.5"
        />
        <path
          d="M60.6865 91.4356V89.7213C60.6865 89.4356 60.9151 89.1499 61.258 89.1499H78.858C79.1437 89.1499 79.4294 89.3785 79.4294 89.7213V91.4356C79.4294 91.7213 79.2008 92.007 78.858 92.007H61.258C60.9151 92.007 60.6865 91.7785 60.6865 91.4356Z"
          fill="#818181"
          fillOpacity="0.5"
        />
        <path
          d="M42.5144 77.6646H56.4572C56.9144 77.6646 57.3144 78.0646 57.3144 78.5217V91.2074C57.3144 91.6646 56.9144 92.0646 56.4572 92.0646H42.5144C42.0572 92.0646 41.6572 91.6646 41.6572 91.2074V78.5217C41.6572 78.0646 42.0572 77.6646 42.5144 77.6646Z"
          fill="#818181"
          fillOpacity="0.5"
        />
      </g>
      <path
        d="M88.1719 69.2073H41.029C39.4862 69.2073 38.1719 67.9501 38.1719 66.3501V51.0359C38.1719 49.493 39.429 48.1787 41.029 48.1787H88.1719C89.7147 48.1787 91.029 49.4359 91.029 51.0359V66.4073C91.029 67.9501 89.7719 69.2073 88.1719 69.2073Z"
        fill="white"
      />
      <path
        d="M60.6865 59.5504V57.8361C60.6865 57.5504 60.9151 57.2646 61.258 57.2646H84.5151C84.8008 57.2646 85.0865 57.4932 85.0865 57.8361V59.5504C85.0865 59.8361 84.858 60.1218 84.5151 60.1218H62.1395C61.5528 60.1218 60.6865 60.137 60.6865 59.5504Z"
        fill="#818181"
        fillOpacity="0.5"
      />
      <path
        d="M60.6865 53.7217V52.0075C60.6865 51.7217 60.9151 51.436 61.258 51.436H86.9722C87.258 51.436 87.5437 51.6646 87.5437 52.0075V53.7217C87.5437 54.0075 87.3151 54.2932 86.9722 54.2932H62.1395C61.5528 54.2932 60.6865 54.3084 60.6865 53.7217Z"
        fill="#818181"
        fillOpacity="0.5"
      />
      <path
        d="M60.6865 65.3785V63.6642C60.6865 63.3785 60.9151 63.0928 61.258 63.0928H78.858C79.1437 63.0928 79.4294 63.3213 79.4294 63.6642V65.3785C79.4294 65.6642 79.2008 65.9499 78.858 65.9499H61.258C60.9151 65.9499 60.6865 65.6642 60.6865 65.3785Z"
        fill="#818181"
        fillOpacity="0.5"
      />
      <path
        d="M42.5144 51.6074H56.4572C56.9144 51.6074 57.3144 52.0074 57.3144 52.4646V65.1503C57.3144 65.6074 56.9144 66.0074 56.4572 66.0074H42.5144C42.0572 66.0074 41.6572 65.6074 41.6572 65.1503V52.4646C41.6572 52.0074 42.0572 51.6074 42.5144 51.6074Z"
        fill="#818181"
        fillOpacity="0.5"
      />
      <path
        d="M88.1719 43.1497H41.029C39.4862 43.1497 38.1719 41.8925 38.1719 40.2925V24.9211C38.1719 23.3783 39.429 22.064 41.029 22.064H88.1719C89.7147 22.064 91.029 23.3211 91.029 24.9211V40.2925C91.029 41.8354 89.7719 43.1497 88.1719 43.1497Z"
        fill="white"
      />
      <path
        d="M60.6865 33.4356V31.7213C60.6865 31.4356 60.9151 31.1499 61.258 31.1499H84.5151C84.8008 31.1499 85.0865 31.3785 85.0865 31.7213V33.4356C85.0865 33.7213 84.858 34.007 84.5151 34.007H61.258C60.9151 34.007 60.6865 33.7785 60.6865 33.4356Z"
        fill="#818181"
        fillOpacity="0.5"
      />
      <path
        d="M60.6865 27.607V25.8927C60.6865 25.607 60.9151 25.3213 61.258 25.3213H86.9722C87.258 25.3213 87.5437 25.5499 87.5437 25.8927V27.607C87.5437 27.8927 87.3151 28.1784 86.9722 28.1784H61.258C60.9151 28.1784 60.6865 27.9499 60.6865 27.607Z"
        fill="#818181"
        fillOpacity="0.5"
      />
      <path
        d="M60.6865 39.2637V37.5495C60.6865 37.2637 60.9151 36.978 61.258 36.978H78.858C79.1437 36.978 79.4294 37.2066 79.4294 37.5495V39.2637C79.4294 39.5495 79.2008 39.8352 78.858 39.8352H61.258C60.9151 39.8352 60.6865 39.6066 60.6865 39.2637Z"
        fill="#818181"
        fillOpacity="0.5"
      />
      <path
        d="M42.5144 25.4927H56.4572C56.9144 25.4927 57.3144 25.8927 57.3144 26.3498V39.0355C57.3144 39.4927 56.9144 39.8927 56.4572 39.8927H42.5144C42.0572 39.8927 41.6572 39.4927 41.6572 39.0355V26.407C41.6572 25.8927 42.0572 25.4927 42.5144 25.4927Z"
        fill="#818181"
        fillOpacity="0.5"
      />
      <g opacity="0.5">
        <mask
          id="mask5_2624_15205"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="64"
          y="56"
          width="59"
          height="59">
          <path
            d="M93.5998 114.35C109.442 114.35 122.285 101.507 122.285 85.6642C122.285 69.8216 109.442 56.9785 93.5998 56.9785C77.7571 56.9785 64.9141 69.8216 64.9141 85.6642C64.9141 101.507 77.7571 114.35 93.5998 114.35Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_2624_15205)">
          <path
            d="M93.5998 114.35C109.442 114.35 122.285 101.507 122.285 85.6642C122.285 69.8216 109.442 56.9785 93.5998 56.9785C77.7571 56.9785 64.9141 69.8216 64.9141 85.6642C64.9141 101.507 77.7571 114.35 93.5998 114.35Z"
            fill="url(#paint4_linear_2624_15205)"
          />
        </g>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.721 85.4368C107.721 93.259 101.392 99.5879 93.5701 99.5879C85.7478 99.5879 79.4189 93.259 79.4189 85.4368C79.4189 77.6145 85.7478 71.2856 93.5701 71.2856C101.392 71.2856 107.721 77.6145 107.721 85.4368Z"
        fill="#D85951"
      />
      <path
        opacity="0.16"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.467 90.6281L96.9119 87.0726L100.467 83.517C101.392 82.5926 101.392 81.0992 100.467 80.1748C99.543 79.2503 98.0496 79.2503 97.1252 80.1748L93.5696 83.7303L90.0141 80.1748C89.0896 79.2503 87.5963 79.2503 86.6718 80.1748C85.7474 81.0992 85.7474 82.5926 86.6718 83.517L90.2274 87.0726L86.6718 90.6281C85.7474 91.5526 85.7474 93.0459 86.6718 93.9703C87.5963 94.8948 89.0896 94.8948 90.0141 93.9703L93.5696 90.4148L97.1252 93.9703C98.0496 94.8948 99.543 94.8948 100.467 93.9703C101.392 93.0459 101.392 91.5526 100.467 90.6281Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.467 89.2058L96.9119 85.6502L100.467 82.0946C101.392 81.1702 101.392 79.6769 100.467 78.7524C99.543 77.828 98.0496 77.828 97.1252 78.7524L93.5696 82.308L90.0141 78.7524C89.0896 77.828 87.5963 77.828 86.6718 78.7524C85.7474 79.6769 85.7474 81.1702 86.6718 82.0946L90.2274 85.6502L86.6718 89.2058C85.7474 90.1302 85.7474 91.6235 86.6718 92.548C87.5963 93.4724 89.0896 93.4724 90.0141 92.548L93.5696 88.9924L97.1252 92.548C98.0496 93.4724 99.543 93.4724 100.467 92.548C101.392 91.6235 101.392 90.1302 100.467 89.2058Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2624_15205"
          x1="0.0516743"
          y1="38.2894"
          x2="48.2182"
          y2="38.2894"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E0E0E4" />
          <stop offset="1" stopColor="#EFEFF0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2624_15205"
          x1="23.1946"
          y1="60.5717"
          x2="106.172"
          y2="60.5717"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E0E0E4" />
          <stop offset="1" stopColor="#EFEFF0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2624_15205"
          x1="104.627"
          y1="35.3738"
          x2="118.933"
          y2="35.3738"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E0E0E4" />
          <stop offset="1" stopColor="#EFEFF0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2624_15205"
          x1="4.86914"
          y1="79.6066"
          x2="19.1753"
          y2="79.6066"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E0E0E4" />
          <stop offset="1" stopColor="#EFEFF0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2624_15205"
          x1="64.9169"
          y1="85.6883"
          x2="122.297"
          y2="85.6883"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E0E0E4" />
          <stop offset="1" stopColor="#EFEFF0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
