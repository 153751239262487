import { Heading } from '@hexa-ui/components';

import { POC_INFORMATION_TEST_IDS } from '../../consts/VisitDetailsCardConsts';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { PocInformationProps } from '../../types/VisitDetails.types';
import { PocInformationContent } from '../PocInformationContent/PocInformationContent';
import * as Styled from './PocInformation.styles';

export const PocInformation = ({
  pocName = '',
  pocId = '',
  pocLatitude = '',
  pocLongitude = '',
  loading = false,
  hasError = false,
  onRefresh,
}: PocInformationProps) => {
  const { pocInformationTitle } = useTranslation();

  return (
    <Styled.WrapperPocInformationCard border="medium" elevated="minimal">
      <Heading data-testid={POC_INFORMATION_TEST_IDS.HEADER} size="H3">
        {pocInformationTitle}
      </Heading>
      <PocInformationContent
        pocName={pocName}
        pocId={pocId}
        pocLatitude={pocLatitude}
        pocLongitude={pocLongitude}
        loading={loading}
        hasError={hasError}
        onRefresh={onRefresh}
      />
    </Styled.WrapperPocInformationCard>
  );
};
