import { axiosInstance } from '@bees-force-shared/services';

import { ACCOUNT_ENDPOINTS } from '@/constructors/Account/AccountConstructor';
import { GetAccountRequestQuery, GetAccountResponse } from '@/types/domains/GetAccountDomain';

export async function getAccount(
  requestQuery: GetAccountRequestQuery,
): Promise<GetAccountResponse> {
  const response = await axiosInstance.get(ACCOUNT_ENDPOINTS.GET_ACCOUNT(requestQuery));

  return response.data;
}
