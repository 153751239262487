export enum ActionButtons {
  cancel = 'Cancel',
  apply = 'Apply',
  clear = 'Clear',
  refresh = 'Refresh',
}

export enum ComponentName {
  filterVisits = 'filter_visits',
}
