import { SearchField } from '@hexa-ui/components';

import { SEARCH_PARAMETERS } from '@/enums/searchEnum';

import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { CARD_COMPONENT_TEST_IDS as TEST_IDS } from '../../TableVisitConsts';
import { SearchWithSelectProps } from '../../types/TableVisitCard';
import * as Styled from './SearchWithSelect.styles';

export const SearchWithSelect = ({
  filterValue,
  searchValue,
  onChangeFilterValue,
  onChangeSearch,
  onClear,
}: SearchWithSelectProps) => {
  const { accountId, bdrId, searchBy } = useTranslation();
  const { Filter, FilterOption } = SearchField;
  const filterPlaceHolder = filterValue === SEARCH_PARAMETERS.ACCOUNT_ID ? accountId : bdrId;

  return (
    <Styled.SearchFieldContainer>
      <Styled.CustoSearchField
        placeholder={`${searchBy} ${filterPlaceHolder}`}
        size="large"
        onChange={onChangeSearch}
        onClear={onClear}
        value={searchValue}
        data-testid={TEST_IDS.SEARCH_INPUT}
        style={{ minWidth: 200, fontWeight: '500' }}>
        <Filter
          value={filterValue}
          onChange={onChangeFilterValue}
          size="large"
          placeholder={bdrId}
          defaultValue={SEARCH_PARAMETERS.BDR_ID}>
          <FilterOption
            value={SEARCH_PARAMETERS.BDR_ID}
            data-testid={TEST_IDS.BRD_ID_OPTION}
            key={'filter-option-bdr-id'}>
            {bdrId}
          </FilterOption>
          <FilterOption
            value={SEARCH_PARAMETERS.ACCOUNT_ID}
            data-testid={TEST_IDS.ACCOUNT_ID_OPTION}
            key={'filter-option-account-id'}>
            {accountId}
          </FilterOption>
        </Filter>
      </Styled.CustoSearchField>
    </Styled.SearchFieldContainer>
  );
};
