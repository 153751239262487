import { Alert } from '@hexa-ui/components';
import styled, { css } from 'styled-components';

export const VisitDetailsContent = styled.div`
  padding-bottom: ${({ theme }) => theme.measure.space['space-5']};
`;

export const WrapperVisitDetailsCard = styled.div`
  display: grid;
  grid-template-columns: 57.5% 40.8%;
  grid-template-rows: repeat(2, 1fr);
  gap: ${({ theme }) => theme.measure.space['space-4']};
  height: 100%;
`;

export const VisitDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.measure.space['space-4']};
  margin-bottom: ${({ theme }) => theme.measure.space['space-9']};
  justify-content: space-between;
  width: 99.2%;
`;

export const WrapperVisitActionCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.measure.space['space-4']};
`;

export const VisitDetailsCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.measure.space['space-4']};
`;

type AlertErrorProps = {
  canceledByUser?: boolean;
};

export const AlertError = styled(Alert)<AlertErrorProps>`
  ${({ canceledByUser }) => css`
    margin: ${({ theme }) => theme.measure.space['space-4']} 0;
    width: 100%;

    ${canceledByUser &&
    css`
      background-color: ${({ theme }) => theme.color.primitive.transparent.black['black-4']};
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: ${({ theme }) => theme.measure.space['space-1']};
        background-color: #e82727;

        height: 100%;
      }
    `}
  `}
`;

export const WrapperLoading = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
