import { useIntl } from 'react-intl';

interface UseTranslationProps {
  bdrId: string;
  accountId: string;
  searchBy: string;
  searchButton: string;
  bdrName: string;
  accountIdError: string;
  bdrIdError: string;
  bdrNameError: string;
  titleCardVisits: string;
  showingResults: string;
  bdrIdColumn: string;
  bdrNameColumn: string;
  pocIdColumn: string;
  visitDateColumn: string;
  minTimeColumn: string;
  maxTimeColumn: string;
  minutes: string;
  status: string;
  scheduled: string;
  inProgress: string;
  canceled: string;
  justified: string;
  completed: string;
  filtersButton: string;
  applyButton: string;
  cancelButton: string;
  clearAllButton: string;
  filteredBy: string;
  loadingContent: string;
  errorContent: string;
  reloadButton: string;
  noContent: string;
  searchNotFound: string;
  clearSearch: string;
}

enum TranslationKeys {
  BUTTONS = 'Buttons',
  SEARCH_BAR = 'SearchBar',
  TITLE = 'Title',
  ERROR = 'Error',
  TABLE_VISITS = 'TableVisits',
  STATUS_VISITS = 'StatusVisits',
  FILTER = 'Filter',
}

export const useTranslation = (): UseTranslationProps => {
  const { formatMessage } = useIntl();

  const getTranslationById = (key: string, value: string): string => {
    return formatMessage({ id: `Components.VisitTableCard.${key}.${value}` });
  };

  return {
    bdrId: getTranslationById(TranslationKeys.SEARCH_BAR, 'BDR_ID'),
    accountId: getTranslationById(TranslationKeys.SEARCH_BAR, 'ACCOUNT_ID'),
    searchBy: getTranslationById(TranslationKeys.SEARCH_BAR, 'SEARCH_BY'),
    searchButton: getTranslationById(TranslationKeys.BUTTONS, 'SEARCH_BUTTON'),
    bdrName: getTranslationById(TranslationKeys.SEARCH_BAR, 'BDR_NAME'),
    titleCardVisits: getTranslationById(TranslationKeys.TITLE, 'CARD_VISITS'),
    accountIdError: getTranslationById(TranslationKeys.ERROR, 'ACCOUNT_ID_ERROR'),
    bdrIdError: getTranslationById(TranslationKeys.ERROR, 'BDR_ID_ERROR'),
    bdrNameError: getTranslationById(TranslationKeys.ERROR, 'BDR_NAME_ERROR'),

    showingResults: getTranslationById(TranslationKeys.TABLE_VISITS, 'SHOWING_RESULTS'),
    bdrIdColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'BDR_ID'),
    bdrNameColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'BDR_NAME'),
    pocIdColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'POC_ID'),
    visitDateColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'VISIT_DATE'),
    minTimeColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'MIN_TIME'),
    maxTimeColumn: getTranslationById(TranslationKeys.TABLE_VISITS, 'MAX_TIME'),
    minutes: getTranslationById(TranslationKeys.TABLE_VISITS, 'MINUTES'),
    loadingContent: getTranslationById(TranslationKeys.TABLE_VISITS, 'LOADING_CONTENT'),
    errorContent: getTranslationById(TranslationKeys.TABLE_VISITS, 'ERROR_CONTENT'),
    noContent: getTranslationById(TranslationKeys.TABLE_VISITS, 'NO_CONTENT'),
    searchNotFound: getTranslationById(TranslationKeys.TABLE_VISITS, 'SEARCH_NOT_FOUND'),
    clearSearch: getTranslationById(TranslationKeys.TABLE_VISITS, 'CLEAR_SEARCH'),

    status: getTranslationById(TranslationKeys.STATUS_VISITS, 'STATUS'),
    scheduled: getTranslationById(TranslationKeys.STATUS_VISITS, 'SCHEDULED'),
    inProgress: getTranslationById(TranslationKeys.STATUS_VISITS, 'IN_PROGRESS'),
    canceled: getTranslationById(TranslationKeys.STATUS_VISITS, 'CANCELED'),
    justified: getTranslationById(TranslationKeys.STATUS_VISITS, 'JUSTIFIED'),
    completed: getTranslationById(TranslationKeys.STATUS_VISITS, 'COMPLETED'),

    filtersButton: getTranslationById(TranslationKeys.BUTTONS, 'FILTERS_BUTTON'),
    applyButton: getTranslationById(TranslationKeys.BUTTONS, 'APPLY_BUTTON'),
    cancelButton: getTranslationById(TranslationKeys.BUTTONS, 'CANCEL_BUTTON'),
    clearAllButton: getTranslationById(TranslationKeys.BUTTONS, 'CLEAR_ALL_BUTTON'),
    reloadButton: getTranslationById(TranslationKeys.BUTTONS, 'RELOAD_BUTTON'),

    filteredBy: getTranslationById(TranslationKeys.FILTER, 'FILTERED_BY'),
  };
};
