import { AxiosResponse } from 'axios';

import { getDistributionCenters } from '@/api/services/DdcService/DdcService';
import { GetDistributionCentersRequest } from '@/types/domains/GetDistributionCentersDomain';

export const getDistributionCentersUseCase = async (
  requestParams: GetDistributionCentersRequest,
): Promise<AxiosResponse> => {
  const { vendorId, page, signal, ddcName } = requestParams;
  const response = await getDistributionCenters({ vendorId, page, signal, name: ddcName });
  return response;
};
