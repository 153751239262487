import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { VisitStatus } from '@/api/services/visits/types';
import { useVisitsParamStore } from '@/stores/visitsParams/VisitsParamsStore';

import { FILTERS_VISITS_IDS } from '../../consts/FiltersVisits';
import { UseTableVisitCardProps } from '../../types/TableVisitCard';
import { updateStatusStore } from '../../utils/statusFilters';

const apiStatusToFilterId = {
  [VisitStatus.SCHEDULE]: FILTERS_VISITS_IDS.scheduled,
  [VisitStatus.COMPLETED]: FILTERS_VISITS_IDS.completed,
  [VisitStatus.CANCELED]: FILTERS_VISITS_IDS.canceled,
  [VisitStatus.JUSTIFIED]: FILTERS_VISITS_IDS.justified,
  [VisitStatus.IN_PROGRESS]: FILTERS_VISITS_IDS.inProgress,
};

export const useTableVisitCard = (): UseTableVisitCardProps => {
  const { status: currentStatus } = useVisitsParamStore();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const getInitialActiveFilters = (): string[] => {
    if (!currentStatus) return [];

    if (typeof currentStatus === 'string' && currentStatus.includes(',')) {
      const statusValues = currentStatus.split(',');
      return statusValues
        .map((status) => apiStatusToFilterId[status as VisitStatus])
        .filter(Boolean);
    }

    return [apiStatusToFilterId[currentStatus]].filter(Boolean);
  };

  const [activeFilters, setActiveFilters] = useState<string[]>(getInitialActiveFilters());

  useEffect(() => {
    setActiveFilters(getInitialActiveFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus]);

  const onRemoveFilter = useCallback(
    (filterId: string) => {
      const updatedFilters = activeFilters.filter((id) => id !== filterId);
      setActiveFilters(updatedFilters);

      updateStatusStore(updatedFilters);
    },
    [activeFilters],
  );

  const onApplyFilter = useCallback(
    (filter: string[]) => {
      setActiveFilters(filter);

      const params = new URLSearchParams(searchParams);
      params.set('page', '1');
      navigate(`?${params.toString()}`);
    },
    [navigate, searchParams],
  );

  return {
    activeFilters,
    onApplyFilter,
    onRemoveFilter,
  };
};
