import styled from 'styled-components';

export const WrapperInformationContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: ${({ theme }) => theme.measure.space['space-8']};
`;

export const WrapperLoadingContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
