import { useIntl } from 'react-intl';

interface UseTranslationProps {
  visitDetailsTitle: string;
  pocInformationTitle: string;
  nextVisitTitle: string;
  bdrInformationTitle: string;
  visitInformationTitle: string;

  visitDateLabel: string;
  visitStatusLabel: string;
  suggestedTimeLabel: string;
  minimumTimeLabel: string;
  maximumTimeLabel: string;
  noNextVisitLabel: string;

  pocNameLabel: string;
  pocIdLabel: string;
  pocLatitudeLabel: string;
  pocLongitudeLabel: string;
  noPocInformationLabel: string;

  bdrNameLabel: string;
  bdrIdLabel: string;
  noBdrInformationLabel: string;

  showVisitDetailsLabel: string;
  minutesLabel: string;
  noDataAvailableLabel: string;

  goBackButton: string;
  cancelVisitButton: string;
  cancelButtonModal: string;
  cancelHeaderModal: string;
  cancelParagraphModal: string;
  cancelVisitToastSuccess: string;

  loadingContent: string;
  errorContent: string;
  errorRefreshContent: string;

  canceledByUserAdmin: string;
  canceledByUserSync: string;
  canceledBySystem: string;
  canceledBySystemReason: {
    invalid_lat_long: string;
    not_in_customer: string;
    not_in_accounts_bff: string;
    invalid_name: string;
    not_in_ucc: string;
    invalid_delivery_address: string;
    bees_account_id_null: string;
  };
}

enum TranslationKeys {
  TITLE = 'Title',
  LABEL = 'Label',
  BUTTON = 'Button',
  TOAST = 'Toast',
}

export const useTranslation = (): UseTranslationProps => {
  const { formatMessage } = useIntl();

  const getTranslationById = (key: string, value: string): string => {
    return formatMessage({ id: `Components.VisitDetails.${key}.${value}` });
  };

  return {
    visitDetailsTitle: getTranslationById(TranslationKeys.TITLE, 'VISIT_DETAILS'),
    pocInformationTitle: getTranslationById(TranslationKeys.TITLE, 'POC_INFORMATION'),
    nextVisitTitle: getTranslationById(TranslationKeys.TITLE, 'NEXT_VISIT'),
    bdrInformationTitle: getTranslationById(TranslationKeys.TITLE, 'BDR_INFORMATION'),
    visitInformationTitle: getTranslationById(TranslationKeys.TITLE, 'VISIT_INFORMATION'),

    visitDateLabel: getTranslationById(TranslationKeys.LABEL, 'VISIT_DATE'),
    visitStatusLabel: getTranslationById(TranslationKeys.LABEL, 'VISIT_STATUS'),
    suggestedTimeLabel: getTranslationById(TranslationKeys.LABEL, 'SUGGESTED_TIME'),
    minimumTimeLabel: getTranslationById(TranslationKeys.LABEL, 'MINIMUM_TIME'),
    maximumTimeLabel: getTranslationById(TranslationKeys.LABEL, 'MAXIMUM_TIME'),
    noNextVisitLabel: getTranslationById(TranslationKeys.LABEL, 'NO_NEXT_VISIT'),

    pocNameLabel: getTranslationById(TranslationKeys.LABEL, 'POC_NAME'),
    pocIdLabel: getTranslationById(TranslationKeys.LABEL, 'POC_ID'),
    pocLatitudeLabel: getTranslationById(TranslationKeys.LABEL, 'POC_LATITUDE'),
    pocLongitudeLabel: getTranslationById(TranslationKeys.LABEL, 'POC_LONGITUDE'),
    noPocInformationLabel: getTranslationById(TranslationKeys.LABEL, 'NO_POC_INFORMATION'),

    bdrNameLabel: getTranslationById(TranslationKeys.LABEL, 'BDR_NAME'),
    bdrIdLabel: getTranslationById(TranslationKeys.LABEL, 'BDR_ID'),
    noBdrInformationLabel: getTranslationById(TranslationKeys.LABEL, 'NO_BDR_INFORMATION'),

    showVisitDetailsLabel: getTranslationById(TranslationKeys.LABEL, 'SHOW_VISIT_DETAILS'),
    minutesLabel: getTranslationById(TranslationKeys.LABEL, 'MINUTES'),
    noDataAvailableLabel: getTranslationById(TranslationKeys.LABEL, 'NO_DATA_AVAILABLE'),

    cancelButtonModal: getTranslationById(TranslationKeys.BUTTON, 'CANCEL_MODAL'),
    cancelHeaderModal: getTranslationById(TranslationKeys.TITLE, 'CANCEL_VISIT'),
    cancelParagraphModal: getTranslationById(TranslationKeys.LABEL, 'CANCEL_VISIT_DESCRIPTION'),
    cancelVisitButton: getTranslationById(TranslationKeys.BUTTON, 'CANCEL_VISIT'),
    goBackButton: getTranslationById(TranslationKeys.BUTTON, 'GO_BACK'),
    cancelVisitToastSuccess: getTranslationById(TranslationKeys.TOAST, 'SUCCESS'),

    loadingContent: getTranslationById(TranslationKeys.LABEL, 'LOADING_CONTENT'),
    errorContent: getTranslationById(TranslationKeys.LABEL, 'ERROR_CONTENT'),
    errorRefreshContent: getTranslationById(TranslationKeys.BUTTON, 'REFRESH'),

    canceledByUserAdmin: getTranslationById(TranslationKeys.LABEL, 'CANCELED_BY_USER_ADMIN'),
    canceledByUserSync: getTranslationById(TranslationKeys.LABEL, 'CANCELED_BY_USER_SYNC'),
    canceledBySystem: getTranslationById(TranslationKeys.LABEL, 'CANCELED_BY_SYSTEM'),
    canceledBySystemReason: {
      // eslint-disable-next-line camelcase
      invalid_lat_long: getTranslationById(
        TranslationKeys.LABEL,
        'CanceledBySystem.INVALID_LAT_LONG',
      ),
      // eslint-disable-next-line camelcase
      not_in_customer: getTranslationById(
        TranslationKeys.LABEL,
        'CanceledBySystem.NOT_IN_CUSTOMER',
      ),
      // eslint-disable-next-line camelcase
      not_in_accounts_bff: getTranslationById(
        TranslationKeys.LABEL,
        'CanceledBySystem.NOT_IN_ACCOUNTS_BFF',
      ),
      // eslint-disable-next-line camelcase
      invalid_name: getTranslationById(TranslationKeys.LABEL, 'CanceledBySystem.INVALID_NAME'),
      // eslint-disable-next-line camelcase
      not_in_ucc: getTranslationById(TranslationKeys.LABEL, 'CanceledBySystem.NOT_IN_UCC'),
      // eslint-disable-next-line camelcase
      invalid_delivery_address: getTranslationById(
        TranslationKeys.LABEL,
        'CanceledBySystem.INVALID_DELIVERY_ADDRESS',
      ),
      // eslint-disable-next-line camelcase
      bees_account_id_null: getTranslationById(
        TranslationKeys.LABEL,
        'CanceledBySystem.BEES_ACCOUNT_ID_NULL',
      ),
    },
  };
};
