enum environment {
  DEV = 'development',
  UAT = 'uat',
  SIT = 'qa',
  PROD = 'prod',
}

const portalConfig = localStorage.getItem('portal-config');
const env = portalConfig ? JSON.parse(portalConfig).ENV : null;

const SERVICES_DOMAIN = {
  [environment.PROD]: 'https://services.bees-platform.com',
  [environment.UAT]: 'https://services-uat.bees-platform.dev',
  [environment.SIT]: 'https://services-sit.bees-platform.dev',
  [environment.DEV]: 'https://services-sit.bees-platform.dev',
};

export function getServiceDomain(): string {
  if (process.env.NODE_ENV === 'test') return '';
  return SERVICES_DOMAIN[env];
}

export const SERVICE_VISITS_ADMIN_BFF = '/bees-visits-admin-bff/';
export const ENDPOINT_GET_VISITS = `${getServiceDomain()}${SERVICE_VISITS_ADMIN_BFF}visits`;
export const ENDPOINT_GET_DDC = `${getServiceDomain()}${SERVICE_VISITS_ADMIN_BFF}distribution-centers`;
