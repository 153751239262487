import { Button, Heading, Modal } from '@hexa-ui/components';

import { CANCEL_VISIT_MODAL_TEST_IDS } from '../../consts/VisitDetailsCardConsts';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { useVisitDetails } from '../../hooks/useVisitDetails';
import { CancelVisitModalProps } from '../../types/VisitDetails.types';
import * as Styled from './CancelVisitModal.styles';

export const CancelVisitModal = ({ visitId }: CancelVisitModalProps) => {
  const { handleCancelVisit, handleClickBackButton } = useVisitDetails();
  const {
    cancelButtonModal,
    cancelHeaderModal,
    cancelParagraphModal,
    cancelVisitButton,
    goBackButton,
  } = useTranslation();

  return (
    <Modal.Root
      actions={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Modal.Cancel>
            <Styled.CancelButton
              data-testid={CANCEL_VISIT_MODAL_TEST_IDS.GO_BACK_BUTTON}
              size="medium"
              variant="secondary"
              onClick={() => handleClickBackButton(visitId)}>
              {goBackButton}
            </Styled.CancelButton>
          </Modal.Cancel>
          <Modal.Action>
            <Button
              data-testid={CANCEL_VISIT_MODAL_TEST_IDS.CANCEL_VISIT_BUTTON}
              size="medium"
              variant="primary"
              onClick={() => handleCancelVisit(visitId)}>
              {cancelVisitButton}
            </Button>
          </Modal.Action>
        </div>
      }
      destructive="critical"
      title={
        <Heading size="H3" data-testid={CANCEL_VISIT_MODAL_TEST_IDS.HEADER}>
          {cancelHeaderModal}
        </Heading>
      }
      trigger={
        <Styled.CancelButtonModal
          data-testid={CANCEL_VISIT_MODAL_TEST_IDS.CANCEL_BUTTON_MODAL}
          size="medium">
          {cancelButtonModal}
        </Styled.CancelButtonModal>
      }>
      <Styled.paragraph>{cancelParagraphModal}</Styled.paragraph>
    </Modal.Root>
  );
};
