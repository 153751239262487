import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 16px;

  margin-top: 8px;

  span {
    font-family: Work Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

    color: ${({ theme }) => theme.color.component.txt.avatar.default};
  }
`;
