import { tokens } from '@bees-force-shared/theme';
import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SelectContainer = styled.div`
  margin-top: ${tokens.measure.space['space-6']};
`;

export const Label = styled.span`
  font-size: ${tokens.measure['font-size']['fontsize-2']};
  line-height: ${tokens.measure['line-height']['lineheight-5']};
  font-weight: ${tokens.fonts['font-weight'].semibold};
  font-family: ${tokens.fonts['font-family']['Work Sans']};
`;

export const FooterModalContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.measure.space['space-4']};
  gap: ${({ theme }) => theme.measure.space['space-3']};
  width: 100%;
  justify-content: flex-end;
`;
