import { useCallback, useState } from 'react';

import { SEARCH_PARAMETERS } from '@/enums/searchEnum';

import { UseTableVisitCardProps } from '../../types/TableVisitCard';
import { useSegmentSearchVisits } from '../useTableVisits/hooks/useSegmentSearchVisits/useSegmentSearchVisits';
import { useTranslation } from '../useTranslation/useTranslation';

export const useTableVisitCard = (): UseTableVisitCardProps => {
  const { accountId } = useTranslation();
  const [filterValue, setFilterValue] = useState<SEARCH_PARAMETERS>(SEARCH_PARAMETERS.BDR_ID);
  const [searchValue, setSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [ids, setIds] = useState<string[]>([]);
  const { dispatchSearchEvent } = useSegmentSearchVisits();

  const onClickSearchEvent = useCallback(() => {
    if (filterValue === accountId) {
      const filteredIds = searchValue.includes(';') ? searchValue.split(';') : [searchValue];
      setIds([...filteredIds]);
    }

    dispatchSearchEvent(searchValue, filterValue);
  }, [filterValue, accountId, dispatchSearchEvent, searchValue]);

  const onRemoveFilter = useCallback((filterId: string) => {
    setActiveFilters((prev) => prev.filter((id) => id !== filterId));
  }, []);

  const onApplyFilter = useCallback((filter: string[]) => {
    setActiveFilters(filter);
  }, []);

  return {
    searchValue,
    filterValue,
    activeFilters,
    ids,
    setFilterValue,
    setSearchValue,
    onClickSearchEvent,
    onApplyFilter,
    onRemoveFilter,
  };
};
