import { Paragraph } from '@hexa-ui/components';

import { getFormattedMessage } from '@/utils/getFormattedMessage/getFormattedMessage';

import { useTranslation } from '../../hooks/useTranslation/useTranslation';

export const VisitInformationMinMaxTime = ({ sugTime, minTime, maxTime }) => {
  const { suggestedTimeLabel, minimumTimeLabel, maximumTimeLabel, minutesLabel } = useTranslation();

  const renderTimeInfo = (label: string, time: string) => (
    <div>
      <Paragraph weight="semibold">{label}</Paragraph>
      <Paragraph>{getFormattedMessage(minutesLabel, { min: time })}</Paragraph>
    </div>
  );

  return (
    <>
      {renderTimeInfo(suggestedTimeLabel, sugTime)}
      {renderTimeInfo(minimumTimeLabel, minTime)}
      {renderTimeInfo(maximumTimeLabel, maxTime)}
    </>
  );
};
