import { format } from 'date-fns';
import { enUS, es, id, ptBR } from 'date-fns/locale';

import { DateFormats } from '@/consts/GlobalConsts';
import { Locales } from '@/enums/locales';

export function formatDate(date: Date, locale: Locales, formatType: DateFormats): string {
  const localeMap: { [key in Locales]: Locale } = {
    [Locales.enUS]: enUS,
    [Locales.ptBR]: ptBR,
    [Locales.es419]: es,
    [Locales.idID]: id,
  };

  const selectedLocale = localeMap[locale as Locales];

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error('Invalid date');
  }

  const dateFormatByLocale = {
    [Locales.enUS]: {
      [DateFormats.SHORT]: 'MM-dd-yyyy',
      [DateFormats.SHORT_REVERSE]: 'yyyy-MM-dd',
      [DateFormats.MEDIUM]: 'MMM d, yyyy',
      [DateFormats.LONG]: 'MMMM d, yyyy',
      [DateFormats.LONG_ORDINAL]: 'MMMM do, yyyy',
    },
    [Locales.ptBR]: {
      [DateFormats.SHORT]: 'dd-MM-yyyy',
      [DateFormats.SHORT_REVERSE]: 'yyyy-MM-dd',
      [DateFormats.MEDIUM]: 'd MMM, yyyy',
      [DateFormats.LONG]: "d 'de' MMMM, yyyy",
    },
    [Locales.es419]: {
      [DateFormats.SHORT]: 'dd-MM-yyyy',
      [DateFormats.SHORT_REVERSE]: 'yyyy-MM-dd',
      [DateFormats.MEDIUM]: 'd MMM, yyyy',
      [DateFormats.LONG]: "d 'de' MMMM, yyyy",
    },
    [Locales.idID]: {
      [DateFormats.SHORT]: 'dd-MM-yyyy',
      [DateFormats.SHORT_REVERSE]: 'yyyy-MM-dd',
      [DateFormats.MEDIUM]: 'd MMM, yyyy',
      [DateFormats.LONG]: 'MMMM d, yyyy',
    },
  };

  return (
    selectedLocale &&
    format(date, dateFormatByLocale[locale][formatType], { locale: selectedLocale })
  );
}
