import analytics from '@/config/typewriter';
import { useAnalytics } from '@/hooks/useAnalytics/useAnalytics';
import { VisitCancelSegmentProps, VisitCancelState } from '@/types/AnalyticsTypes';

export const useSegmentVisitCancel = () => {
  const { defaultProps } = useAnalytics();

  const dispatchVisitCancelEvent = ({ buttonName, componentName, visitID }: VisitCancelState) => {
    const visitCancelProps = {
      ...defaultProps,
      [VisitCancelSegmentProps.ButtonName]: buttonName,
      [VisitCancelSegmentProps.ComponentName]: componentName,
      [VisitCancelSegmentProps.VisitID]: visitID,
      [VisitCancelSegmentProps.FailureReason]: '',
    };

    try {
      analytics.visitCancel(visitCancelProps);
    } catch (error) {
      analytics.visitCancel({
        ...visitCancelProps,
        [VisitCancelSegmentProps.FailureReason]:
          error instanceof Error ? error.message : VisitCancelSegmentProps.unknownError,
      });
    }
  };

  return {
    dispatchVisitCancelEvent,
  };
};
