import analytics from '@/config/typewriter';
import { useAnalytics } from '@/hooks/useAnalytics/useAnalytics';
import { ComponentsName, VisitEditSegmentProps, VisitEditState } from '@/types/AnalyticsTypes';

export const useSegmentVisitEdit = () => {
  const { defaultProps } = useAnalytics();

  const mapVisitEditProps = (visitData: VisitEditState, failureReason = '') => ({
    ...defaultProps,
    [VisitEditSegmentProps.ButtonName]: VisitEditSegmentProps.Edit as VisitEditSegmentProps.Edit,
    [VisitEditSegmentProps.ComponentName]: ComponentsName.editVisit,
    [VisitEditSegmentProps.VisitID]: visitData.visit_id,
    [VisitEditSegmentProps.VisitStatus]: visitData.visit_status,
    [VisitEditSegmentProps.OldDate]: JSON.stringify(visitData.old_date),
    [VisitEditSegmentProps.NewDate]: JSON.stringify(visitData.new_date),
    [VisitEditSegmentProps.FailureReason]: failureReason,
  });

  const dispatchVisitEditEvent = (visitData: VisitEditState) => {
    try {
      analytics.editVisit(mapVisitEditProps(visitData));
    } catch (error) {
      const failureReason =
        error instanceof Error ? error.message : VisitEditSegmentProps.unknownError;
      analytics.editVisit(mapVisitEditProps(visitData, failureReason));
    }
  };

  return { dispatchVisitEditEvent };
};
