import { createStore, createEvent } from 'effector';
import { useUnit } from 'effector-react';

import { VisitsModel } from '@/api/services/visits/types';

export const setVisits = createEvent<VisitsModel[]>();
export const clearVisits = createEvent();

export const $visitsStore = createStore<VisitsModel[]>([])
  .on(setVisits, (_, visits) => visits)
  .reset(clearVisits);

export function useVisitsStore() {
  return useUnit($visitsStore);
}
