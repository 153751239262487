import { LoadingDots } from '@hexa-ui/components';

import { TABLE_VISITS_TEST_IDS } from '../../consts/TableVisits';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { LoadingContentContainer, LoadingContentLabel } from './LoadingContent.styles';

export const LoadingContent = (): JSX.Element => {
  const { loadingContent } = useTranslation();

  return (
    <LoadingContentContainer data-testid={TABLE_VISITS_TEST_IDS.LOADING_CONTENT}>
      <LoadingContentLabel>{loadingContent}</LoadingContentLabel>
      <LoadingDots size="large" />
    </LoadingContentContainer>
  );
};
