export enum DateFormats {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
  SHORT_REVERSE = 'shortReverse',
  LONG_ORDINAL = 'longOrdinal',
}

export const STATUS = {
  JUSTIFIED: 'JUSTIFIED',
  SCHEDULE: 'SCHEDULE',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};
