/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface AccountsAssignedFileDownloaded {
	/**
	 * The submission ID generated by "TaskService". Sample: fe573f8c-645c-4920-bdd7-612819866838
	 */
	submission_id: string | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The total amount of POCs that the BDR already completed the task. It's an integer. Samples: 1, 3, 5
	 */
	total_completed: number | null
	/**
	 * The total amount of POCs in the given context. It's an integer. Samples: 1, 3, 5
	 */
	total_pocs: number | null
}
export interface ButtonClicked {
	/**
	 * The exact label of the button in which the user clicked. Samples: "Update", "Cancelar"
	 */
	button_label: string | null
	/**
	 * What is the name of the button. Samples: Place Order
	 */
	button_name: string | null
	/**
	 * Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Homepage', 'Task Edition'
	 */
	referrer: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
	 */
	screen_name: ScreenName
	/**
	 * The url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html
	 */
	url: string | null
}
export interface CsvAccountListValidated {
	/**
	 * The file the user dropped or select is valid or not. It's a boolean. If Yes, set TRUE. If not, set FALSE.
	 */
	is_valid_file: boolean | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_conflict_pocs: number | null
	/**
	 * The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
	 */
	total_invalid_pocs: number | null
	/**
	 * The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_valid_pocs: number | null
}
export interface DdcSelected {
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * The updated DDC. Example - 743824729 / DDC Campinas
	 */
	ddc_update?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface DateFilterVisits {
	/**
	 * What is the name of the button. Samples: Place Order
	 */
	button_name: string | null
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The filter date of visits. Example 2025-01-15
	 */
	date?: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface EditVisit {
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * Current date of the visit
	 */
	new_date: string | null
	/**
	 * Previous date of the visit recorded in the system
	 */
	old_date: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
	/**
	 * Visit ID
	 */
	visit_id: string | null
	/**
	 * Status of the visit
	 */
	visit_status: string | null
}
export interface ErrorFileDownloaded {
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_conflict_pocs: number | null
	/**
	 * The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
	 */
	total_invalid_pocs: number | null
	/**
	 * The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_valid_pocs: number | null
}
export interface FilterVisits {
	/**
	 * What is the name of the button. Samples: Place Order
	 */
	button_name: string | null
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * The status applied for filtering visits
	 */
	status?: any[] | null
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface MetadataIdsItem {
	/**
	 * The selected metadata ID's
	 */
	metadata_id: string
}
export interface SkusItem {
	/**
	 * The selected sku
	 */
	sku: string
}
export interface AudienceDataFeatureSelectionItem {
	/**
	 * This field contains the name of the selected audience feature.
	 */
	feature: string | null
	/**
	 * The array of audience metadata ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	metadata_ids?: MetadataIdsItem[] | null
	/**
	 * selected operator
	 */
	operator: string | null
	/**
	 * selected period
	 */
	period: string | null
	/**
	 * The array of skus. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	skus?: SkusItem[] | null
	/**
	 * selected value
	 */
	value: string | null
}
export interface AudienceStaticItem {
	/**
	 * Audience Id
	 */
	audience_id: string
	/**
	 * Vendor Id
	 */
	vendor_id: string
}
export interface MetadataIdsItem1 {
	/**
	 * The selected metadata ID's
	 */
	metadata_id: string
}
export interface SkusItem1 {
	/**
	 * The selected sku
	 */
	sku: string
}
export interface EffectivenessDataFeatureSelectionItem {
	/**
	 * This field contains the name of the selected effectiveness feature.
	 */
	feature: string | null
	/**
	 * The array of audience metadata ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	metadata_ids?: MetadataIdsItem1[] | null
	/**
	 * selected operator
	 */
	operator: string | null
	/**
	 * selected period
	 */
	period: string | null
	/**
	 * The array of skus. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	skus?: SkusItem1[] | null
	/**
	 * selected value
	 */
	value: string | null
}
export interface ConditionalJumpItem {
	/**
	 * selected jump position value
	 */
	jump_position_value?: number | null
}
export interface ConditionalOptionItem {
	/**
	 * The array of conditional jump. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	conditional_jump?: ConditionalJumpItem[]
	/**
	 * selected conditional value
	 */
	conditional_value?: string | null
}
export interface MultipleSelectionOptionItem {
	/**
	 * selected multiple selection option value
	 */
	multiple_selection_value?: string | null
}
export interface SingleSelectionOptionItem {
	/**
	 * selected single selection option value
	 */
	single_selection_value?: string | null
}
export interface OptionsItem {
	/**
	 * The array of conditional option. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	conditional_option?: ConditionalOptionItem[] | null
	/**
	 * selected free text option
	 */
	free_text_option?: string | null
	/**
	 * The array of multiple selection option. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	multiple_selection_option?: MultipleSelectionOptionItem[] | null
	/**
	 * selected numeric option
	 */
	numeric_option?: string | null
	/**
	 * The array of single selection option. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	single_selection_option?: SingleSelectionOptionItem[] | null
}
export interface SurveyQuestionIncludedItem {
	/**
	 * The array of options. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	options?: OptionsItem[] | null
	/**
	 * This field contains the type of the survey question. (Ex:Multi-select, free text)
	 */
	response_type: string | null
	/**
	 * This field represents the question displayed on the form.
	 */
	title: string | null
}
export interface FrontlineTaskCreation {
	/**
	 * Differ if the information is coming from V2 or V1
	 */
	app_version?: string | null
	/**
	 * The array of audience data feature selection. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	audience_data_feature_selection?: AudienceDataFeatureSelectionItem[] | null
	/**
	 * The array of audiences static. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	audience_static?: AudienceStaticItem[] | null
	/**
	 * DYNAMIC (audiences created as data features) ; STATIC (created as CSV file)
	 */
	audience_type?: AudienceType
	/**
	 * True = There is at least one effe.chec ; false= no effe.check
	 */
	effectiveness_check: boolean | null
	/**
	 * The array of effectiveness data feature selection. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	effectiveness_data_feature_selection?:
		| EffectivenessDataFeatureSelectionItem[]
		| null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * The file the user dropped or select is valid or not. If Yes, set TRUE. If not, set FALSE.
	 */
	is_sponsored: boolean | null
	/**
	 * If Task Successful = True; If not = False
	 */
	is_task_successful: boolean | null
	/**
	 * If the task group has local model = true is hasn't = false
	 */
	local_model_id_enabled: boolean | null
	/**
	 * Link or HomePage
	 */
	origin_screen_name: string | null
	/**
	 * a unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The identification of the sponsor - Partner
	 */
	sponsored_name: string | null
	/**
	 * The array of survey question included. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
	 */
	survey_question_included?: SurveyQuestionIncludedItem[] | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The duration of the task.
	 */
	task_duration: number | null
	/**
	 * The frequency of the task
	 */
	task_frequency: string | null
	/**
	 * The task group selected in the task template creation
	 */
	task_group_selection?: string | null
	/**
	 * The profile of the task.
	 */
	task_profile: string | null
	/**
	 * Time between "create task template" and "save task template" - MiliSecond
	 */
	task_time_ms: number | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The type of method.
	 */
	type_method: TypeMethod
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * Identify the vision job id added by the user
	 */
	vision_job_id?: string | null
}
export interface NewMerchandiserTaskTemplateStepCompleted {
	/**
	 * The entry method in the given context. It contains Permitted Values: 'New Template', 'Duplicate Template'
	 */
	entry_method: EntryMethod
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
	 */
	screen_name: ScreenName
	/**
	 * The step name the user completed. It contains permitted values. Samples: Type Selected, Template Submitted, Template Created
	 */
	step_name: StepName
	/**
	 * The number of the step the user completed. It's an integer. Samples: 1, 3, 5
	 */
	step_number: number | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
}
export interface NewMerchandiserTaskTemplateSubmitted {
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
	 */
	screen_name: ScreenName
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_dependent: number | null
	/**
	 * The number of questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_questions: number | null
}
export interface NewTaskTemplateCanceled {
	/**
	 * The entry method in the given context. It contains Permitted Values: 'New Template', 'Duplicate Template'
	 */
	entry_method: EntryMethod
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
	 */
	screen_name: ScreenName
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
}
export interface NewTaskTemplateStarted {
	/**
	 * The entry method in the given context. It contains Permitted Values: 'New Template', 'Duplicate Template'
	 */
	entry_method: EntryMethod
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
	 */
	screen_name: ScreenName
}
export interface NewTaskTemplateStepCompleted {
	/**
	 * The entry method in the given context. It contains Permitted Values: 'New Template', 'Duplicate Template'
	 */
	entry_method: EntryMethod
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
	 */
	screen_name: ScreenName
	/**
	 * The step name the user completed. It contains permitted values. Samples: Type Selected, Template Submitted, Template Created
	 */
	step_name: StepName
	/**
	 * The number of the step the user completed. It's an integer. Samples: 1, 3, 5
	 */
	step_number: number | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
}
export interface NewTaskTemplateSubmissionFailed {
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
	 */
	screen_name: ScreenName
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_dependent: number | null
	/**
	 * The number of questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_questions: number | null
}
export interface NewTaskTemplateSubmissionSuccessful {
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
	 */
	screen_name: ScreenName
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_dependent: number | null
	/**
	 * The number of questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_questions: number | null
}
export interface NewTaskTemplateSubmitted {
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
	 */
	screen_name: ScreenName
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_dependent: number | null
	/**
	 * The number of questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_questions: number | null
}
export interface OperationSelected {
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * Country updated of the POC. Example - BR
	 */
	country_name_update: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * The updated operation ID of the visit
	 */
	operation_id_update: string | null
	/**
	 * The updated name of the organization. Example - Loreal
	 */
	organization_name_update: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * The updated name of the seller. Example - DPC
	 */
	seller_name_update?: string | null
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
	/**
	 * The user vendor identity value updated
	 */
	vendor_id_name_update: string | null
}
export interface UserGroupsItem {
	/**
	 * User groups (Sample. GVBR, TaskManager, etc)
	 */
	groups: string | null
}
export interface OperationsSearchStarted {
	/**
	 * Catch user´s email
	 */
	created_by: string | null
	/**
	 * Country select in the request
	 */
	search_country: string | null
	/**
	 * Search id/name used in the request
	 */
	search_id: string | null
	/**
	 * Search type chosen by the user: BDR, Account, Visits, Tasks
	 */
	search_type: string | null
	/**
	 * Vendor select in the request
	 */
	search_vendor: string | null
	/**
	 * a unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	user_groups: UserGroupsItem[] | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface UserGroupsItem1 {
	/**
	 * User groups (Sample. GVBR, TaskManager, etc)
	 */
	groups: string | null
}
export interface PhotoTaskTemplate {
	/**
	 * The bucket chosen by the user
	 */
	bucket: Bucket
	/**
	 * Catch user´s email
	 */
	created_by: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * The file the user dropped or select is valid or not. If Yes, set TRUE. If not, set FALSE.
	 */
	is_sponsored: boolean | null
	/**
	 * If Task Successful = True; If not = False
	 */
	is_task_successful: boolean | null
	/**
	 * Link or HomePage
	 */
	origin_screen_name: string | null
	/**
	 * a unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The identification of the sponsor - Partner
	 */
	sponsored_name: string | null
	/**
	 * The profile of the task.
	 */
	task_profile: string | null
	/**
	 * Time between "create task template" and "save task template" - MiliSecond
	 */
	task_time_ms: number | null
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The type of method.
	 */
	type_method: TypeMethod
	/**
	 * The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	user_groups: UserGroupsItem1[] | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
	/**
	 * Identify the vision job id added by the user
	 */
	vision_job_id?: string | null
}
export interface UserGroupsItem2 {
	/**
	 * User groups (Sample. GVBR, TaskManager, etc)
	 */
	groups: string | null
}
export interface Prioritization {
	/**
	 * The number of the first priorization number
	 */
	active_prioritization_number: number | null
	/**
	 * Differ if the information is coming from V2 or V1
	 */
	app_version?: string | null
	/**
	 * The there is an active submission at the moment. If Yes, set TRUE. If not, set FALSE.
	 */
	is_active: boolean | null
	/**
	 * a unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The number of the final priorization number
	 */
	target_prioritization_number: number | null
	/**
	 * The task group selected in the task template creation
	 */
	task_group_selection?: string | null
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * Type of trigger select.
	 */
	trigger_type: TriggerType
	/**
	 * The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	user_groups: UserGroupsItem2[] | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface RefreshDataVisits {
	/**
	 * What is the name of the button. Samples: Place Order
	 */
	button_name: string | null
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface SearchVisits {
	/**
	 * What is the name of the button. Samples: Place Order
	 */
	button_name: string | null
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * The content typed by the user in the search field. Samples: 'Tarefa', 'Refrigerator'
	 */
	search_content: string | null
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface SideBarMenuInteraction {
	/**
	 * What is the name of the button. Samples: Place Order
	 */
	button_name: string | null
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * Link or HomePage
	 */
	origin_screen_name: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface UserGroupsItem3 {
	/**
	 * User groups (Sample. GVBR, TaskManager, etc)
	 */
	groups: string | null
}
export interface SimpleTaskTemplate {
	/**
	 * True = There is at least one effe.chec ; false= no effe.check
	 */
	effectiveness_check: boolean | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * The file the user dropped or select is valid or not. If Yes, set TRUE. If not, set FALSE.
	 */
	is_sponsored: boolean | null
	/**
	 * If Task Successful = True; If not = False
	 */
	is_task_successful: boolean | null
	/**
	 * Link or HomePage
	 */
	origin_screen_name: string | null
	/**
	 * a unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * We want to know the number of skus uploaded in the csv file or in the window
	 */
	sku_tracking: number | null
	/**
	 * The identification of the sponsor - Partner
	 */
	sponsored_name: string | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The duration of the task.
	 */
	task_duration: number | null
	/**
	 * The frequency of the task
	 */
	task_frequency: string | null
	/**
	 * The profile of the task.
	 */
	task_profile: string | null
	/**
	 * The sub category task.
	 */
	task_sub_category: TaskSubCategory
	/**
	 * Time between "create task template" and "save task template" - MiliSecond
	 */
	task_time_ms: number | null
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The type of method.
	 */
	type_method: TypeMethod
	/**
	 * The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	user_groups: UserGroupsItem3[] | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface SortingVisits {
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * Order priority. Example: ascending
	 */
	order_priority: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * Sorting info. Example: visit_date
	 */
	sorting_info: string | null
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface UserGroupsItem4 {
	/**
	 * User groups (Sample. GVBR, TaskManager, etc)
	 */
	groups: string | null
}
export interface SurveyTaskTemplate {
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * The file the user dropped or select is valid or not. If Yes, set TRUE. If not, set FALSE.
	 */
	is_sponsored: boolean | null
	/**
	 * If Task Successful = True; If not = False
	 */
	is_task_successful: boolean | null
	/**
	 * Link or HomePage
	 */
	origin_screen_name: string | null
	/**
	 * a unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The identification of the sponsor - Partner
	 */
	sponsored_name: string | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The duration of the task.
	 */
	task_duration: number | null
	/**
	 * The frequency of the task
	 */
	task_frequency: string | null
	/**
	 * The profile of the task.
	 */
	task_profile: string | null
	/**
	 * Time between "create task template" and "save task template" - MiliSecond
	 */
	task_time_ms: number | null
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The number of each question Type (SINGLESELECTION; FREETEXT; CONDITIONAL; NUMERIC; MULTIPLESELECTION)
	 */
	total_question_type: Record<string, any> | null
	/**
	 * The number of questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_questions: number | null
	/**
	 * The type of method.
	 */
	type_method: TypeMethod
	/**
	 * The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	user_groups: UserGroupsItem4[] | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface TaskAssignmentCanceled {
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_conflict_pocs: number | null
	/**
	 * The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
	 */
	total_invalid_pocs: number | null
	/**
	 * The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_valid_pocs: number | null
}
export interface TaskAssignmentDeleted {
	/**
	 * The submission ID generated by "TaskService". Sample: fe573f8c-645c-4920-bdd7-612819866838
	 */
	submission_id: string | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The total amount of POCs that the BDR already completed the task. It's an integer. Samples: 1, 3, 5
	 */
	total_completed: number | null
	/**
	 * The total amount of POCs in the given context. It's an integer. Samples: 1, 3, 5
	 */
	total_pocs: number | null
}
export interface TaskAssignmentStarted {
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
}
export interface TaskAssignmentSubmissionFailed {
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_conflict_pocs: number | null
	/**
	 * The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
	 */
	total_invalid_pocs: number | null
	/**
	 * The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_valid_pocs: number | null
}
export interface TaskAssignmentSubmissionSuccessful {
	/**
	 * The submission ID generated by "TaskService". Sample: fe573f8c-645c-4920-bdd7-612819866838
	 */
	submission_id: string | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_conflict_pocs: number | null
	/**
	 * The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
	 */
	total_invalid_pocs: number | null
	/**
	 * The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_valid_pocs: number | null
}
export interface TaskAssignmentSubmitted {
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_conflict_pocs: number | null
	/**
	 * The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
	 */
	total_invalid_pocs: number | null
	/**
	 * The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
	 */
	total_valid_pocs: number | null
}
export interface UserGroupsItem5 {
	/**
	 * User groups (Sample. GVBR, TaskManager, etc)
	 */
	groups: string | null
}
export interface TaskSearched {
	/**
	 * Differ if the information is coming from V2 or V1
	 */
	app_version?: string | null
	/**
	 * Link or HomePage
	 */
	origin_screen_name: string | null
	/**
	 * What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
	 */
	screen_name: ScreenName
	/**
	 * The content typed by the user in the search field. Samples: 'Tarefa', 'Refrigerator'
	 */
	search_content: string | null
	/**
	 * Applied filters (Sample: {isSponsor: false} )
	 */
	search_filter: Record<string, any> | null
	/**
	 * The amount of results returned considering the search content. It's an integer. Samples: 3, 5, 8
	 */
	search_results: number | null
	/**
	 * a unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The task group selected in the task template creation
	 */
	task_group_selection?: string | null
	/**
	 * The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	user_groups: UserGroupsItem5[] | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface UserGroupsItem6 {
	/**
	 * User groups (Sample. GVBR, TaskManager, etc)
	 */
	groups: string | null
}
export interface TaskSubmission {
	/**
	 * Differ if the information is coming from V2 or V1
	 */
	app_version?: string | null
	/**
	 * We want to know the number of POCs uploaded in the csv file
	 */
	audience_tracking: number | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Imagem recognition for the task sub category
	 */
	image_recognition_task_sub_category: Bucket
	/**
	 * If Task Successful = True; If not = False
	 */
	is_submission_successful: boolean | null
	/**
	 * a unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * Simple task for sub category
	 */
	simple_task_sub_category: SimpleTaskSubCategory
	/**
	 * Time between "airplane icon" and "submit task" - milisecond
	 */
	submission_time_ms: number | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The task group selected in the task template creation
	 */
	task_group_selection?: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * Number of days count from the field 'start end date'
	 */
	task_validity: number | null
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
	 */
	user_groups: UserGroupsItem6[] | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface TaskTemplateDeleted {
	/**
	 * The there is an active submission at the moment. If Yes, set TRUE. If not, set FALSE.
	 */
	is_active: boolean | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The total amount of submissions for the Task Template. it's an integer. Samples: 1, 3, 5
	 */
	total_submissions: number | null
}
export interface TaskTemplateUpdateCanceled {
	/**
	 * The flag that indicates if the user made any change on the form before performing the action. It's a boolean. If Yes, set TRUE. If not, set False.
	 */
	has_changes: boolean | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
}
export interface TaskTemplateUpdateStarted {
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_dependent: number | null
	/**
	 * The number of questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_questions: number | null
}
export interface TaskTemplateUpdateSubmissionFailed {
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * The flag that indicates if the user made any change on the form before performing the action. It's a boolean. If Yes, set TRUE. If not, set False.
	 */
	has_changes: boolean | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_dependent: number | null
	/**
	 * The number of questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_questions: number | null
}
export interface TaskTemplateUpdateSubmissionSuccessful {
	/**
	 * The flag that indicates if the user made any change on the form before performing the action. It's a boolean. If Yes, set TRUE. If not, set False.
	 */
	has_changes: boolean | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_dependent: number | null
	/**
	 * The number of questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_questions: number | null
}
export interface TaskTemplateUpdateSubmitted {
	/**
	 * The flag that indicates if the user made any change on the form before performing the action. It's a boolean. If Yes, set TRUE. If not, set False.
	 */
	has_changes: boolean | null
	/**
	 * The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
	 */
	task_category: string | null
	/**
	 * The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
	 */
	task_type: TaskType
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_dependent: number | null
	/**
	 * The number of questions in the template. It's an integer. Samples: 1, 3, 5
	 */
	total_questions: number | null
}
export interface VisionJobId {
	/**
	 * The bucket chosen by the user
	 */
	bucket: Bucket
	/**
	 * Catch user´s email
	 */
	created_by: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * If Task Successful = True; If not = False
	 */
	is_successful: boolean | null
	/**
	 * a unique identifier for the user's session (expires when exiting the page, exits the mfe)
	 */
	session_id: string | null
	/**
	 * The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
	 */
	template_id?: string | null
	/**
	 * The type of method.
	 */
	type_method: TypeMethod
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
	/**
	 * Identify the vision job id added by the user
	 */
	vision_job_id?: string | null
}
export interface VisitCancel {
	/**
	 * What is the name of the button. Samples: Place Order
	 */
	button_name: string | null
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
	/**
	 * Visit ID
	 */
	visit_id: string | null
}
export interface VisitDetails {
	/**
	 * What is the name of the button. Samples: Place Order
	 */
	button_name: string | null
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
}
export interface VisitList {
	/**
	 * What is the name of the button. Samples: Place Order
	 */
	button_name: string | null
	/**
	 * Component Name. Example: menu_sidebar_collapsed
	 */
	component_name: string | null
	/**
	 * Country of the POC. Example: BR
	 */
	country: string | null
	/**
	 * The DDC. Example - 743824729 / DDC Campinas
	 */
	ddc?: string | null
	/**
	 * Device Language. Example: pt-BR
	 */
	device_language: string | null
	/**
	 * The user email. lastName.firstName@ab-inbev.com
	 */
	email: string | null
	/**
	 * The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
	 */
	failure_reason?: string | null
	/**
	 * Feature Name. Example: Visits
	 */
	feature_name: string | null
	/**
	 * The App Name in a given context. Permitted values. Example - Tasks
	 */
	screen_name_app: ScreenNameApp
	/**
	 * User Selected Language. Example: pt-BR
	 */
	user_selected_language: string | null
	/**
	 * User's vendorGroupID, Example: Pepsico
	 */
	vendor_group_id: string | null
	/**
	 * The user vendor identity value
	 */
	vendor_id?: string | null
	/**
	 * Visit ID
	 */
	visit_id: string | null
}

export enum TaskType {
	ImageRecognition = 'IMAGE_RECOGNITION',
	Null = 'NULL',
	Simple = 'SIMPLE',
	Survey = 'SURVEY',
	Null1 = 'null',
}
export enum ScreenName {
	Null = 'null',
	Priorization = 'priorization',
	Submission = 'submission',
}
export enum ScreenNameApp {
	Null = 'null',
	Tasks = 'Tasks',
	Visit = 'Visit',
	OperationPanel = 'Operation panel',
	TaskGroups = 'task groups',
	AudienceBuilder = 'audience builder',
}
export enum AudienceType {
	Dynamic = 'DYNAMIC',
	Null = 'NULL',
	Static = 'STATIC',
	Null1 = 'null',
}
export enum TypeMethod {
	Create = 'CREATE',
	Duplicate = 'DUPLICATE',
	Update = 'UPDATE',
	Delete = 'DELETE',
	Null = 'null',
}
export enum EntryMethod {
	DuplicateTemplate = 'Duplicate Template',
	NewTemplate = 'New Template',
	Null = 'null',
}
export enum StepName {
	TemplateCreated = 'Template Created',
	TemplateSubmitted = 'Template Submitted',
	TypeSelected = 'Type Selected',
	Null = 'null',
}
export enum Bucket {
	Cooler = 'COOLER',
	Poster = 'POSTER',
	Shelf = 'SHELF',
	Null = 'null',
}
export enum TriggerType {
	DragDrop = 'DRAG_DROP',
	Typing = 'TYPING',
	Null = 'null',
}
export enum TaskSubCategory {
	Challenge = 'CHALLENGE',
	Combo = 'COMBO',
	MonthlyProductCoverage = 'MONTHLY_PRODUCT_COVERAGE',
	Volume = 'VOLUME',
	Null = 'null',
}
export enum SimpleTaskSubCategory {
	Challange = 'CHALLANGE',
	Combo = 'COMBO',
	MonthlyProductCoverage = 'MONTHLY_PRODUCT_COVERAGE',
	Volume = 'VOLUME',
	Null = 'null',
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * @typedef AccountsAssignedFileDownloaded
 * @property {string | null} submission_id - The submission ID generated by "TaskService". Sample: fe573f8c-645c-4920-bdd7-612819866838
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_completed - The total amount of POCs that the BDR already completed the task. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_pocs - The total amount of POCs in the given context. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef ButtonClicked
 * @property {string | null} button_label - The exact label of the button in which the user clicked. Samples: "Update", "Cancelar"
 * @property {string | null} button_name - What is the name of the button. Samples: Place Order
 * @property {string | null} referrer - Where did the user come from to get to this page/screen. The PREVIOUS screen. Samples: 'Homepage', 'Task Edition'
 * @property {ScreenName} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
 * @property {string | null} url - The url in the given context (WEB ONLY, if mobile, leave null). If a link or a button, the url the user will be redirected to. Samples: https://tiendaenlinea.bavaria.co/aguila-rn-330cc-x-30-3128.html
 */
/**
 * @typedef CsvAccountListValidated
 * @property {boolean | null} is_valid_file - The file the user dropped or select is valid or not. It's a boolean. If Yes, set TRUE. If not, set FALSE.
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_conflict_pocs - The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_invalid_pocs - The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_valid_pocs - The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef DdcSelected
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} [ddc_update] - The updated DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef DateFilterVisits
 * @property {string | null} button_name - What is the name of the button. Samples: Place Order
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [date] - The filter date of visits. Example 2025-01-15
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef EditVisit
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {string | null} new_date - Current date of the visit
 * @property {string | null} old_date - Previous date of the visit recorded in the system
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 * @property {string | null} visit_id - Visit ID
 * @property {string | null} visit_status - Status of the visit
 */
/**
 * @typedef ErrorFileDownloaded
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_conflict_pocs - The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_invalid_pocs - The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_valid_pocs - The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef FilterVisits
 * @property {string | null} button_name - What is the name of the button. Samples: Place Order
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {any[] | null} [status] - The status applied for filtering visits
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef MetadataIdsItem
 * @property {string} metadata_id - The selected metadata ID's
 */
/**
 * @typedef SkusItem
 * @property {string} sku - The selected sku
 */
/**
 * @typedef AudienceDataFeatureSelectionItem
 * @property {string | null} feature - This field contains the name of the selected audience feature.
 * @property {MetadataIdsItem[] | null} [metadata_ids] - The array of audience metadata ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} operator - selected operator
 * @property {string | null} period - selected period
 * @property {SkusItem[] | null} [skus] - The array of skus. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} value - selected value
 */
/**
 * @typedef AudienceStaticItem
 * @property {string} audience_id - Audience Id
 * @property {string} vendor_id - Vendor Id
 */
/**
 * @typedef MetadataIdsItem1
 * @property {string} metadata_id - The selected metadata ID's
 */
/**
 * @typedef SkusItem1
 * @property {string} sku - The selected sku
 */
/**
 * @typedef EffectivenessDataFeatureSelectionItem
 * @property {string | null} feature - This field contains the name of the selected effectiveness feature.
 * @property {MetadataIdsItem1[] | null} [metadata_ids] - The array of audience metadata ids. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} operator - selected operator
 * @property {string | null} period - selected period
 * @property {SkusItem1[] | null} [skus] - The array of skus. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} value - selected value
 */
/**
 * @typedef ConditionalJumpItem
 * @property {number | null} [jump_position_value] - selected jump position value
 */
/**
 * @typedef ConditionalOptionItem
 * @property {ConditionalJumpItem[]} [conditional_jump] - The array of conditional jump. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} [conditional_value] - selected conditional value
 */
/**
 * @typedef MultipleSelectionOptionItem
 * @property {string | null} [multiple_selection_value] - selected multiple selection option value
 */
/**
 * @typedef SingleSelectionOptionItem
 * @property {string | null} [single_selection_value] - selected single selection option value
 */
/**
 * @typedef OptionsItem
 * @property {ConditionalOptionItem[] | null} [conditional_option] - The array of conditional option. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} [free_text_option] - selected free text option
 * @property {MultipleSelectionOptionItem[] | null} [multiple_selection_option] - The array of multiple selection option. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} [numeric_option] - selected numeric option
 * @property {SingleSelectionOptionItem[] | null} [single_selection_option] - The array of single selection option. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 */
/**
 * @typedef SurveyQuestionIncludedItem
 * @property {OptionsItem[] | null} [options] - The array of options. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} response_type - This field contains the type of the survey question. (Ex:Multi-select, free text)
 * @property {string | null} title - This field represents the question displayed on the form.
 */
/**
 * @typedef FrontlineTaskCreation
 * @property {string | null} [app_version] - Differ if the information is coming from V2 or V1
 * @property {AudienceDataFeatureSelectionItem[] | null} [audience_data_feature_selection] - The array of audience data feature selection. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {AudienceStaticItem[] | null} [audience_static] - The array of audiences static. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {AudienceType} [audience_type] - DYNAMIC (audiences created as data features) ; STATIC (created as CSV file)
 * @property {boolean | null} effectiveness_check - True = There is at least one effe.chec ; false= no effe.check
 * @property {EffectivenessDataFeatureSelectionItem[] | null} [effectiveness_data_feature_selection] - The array of effectiveness data feature selection. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {boolean | null} is_sponsored - The file the user dropped or select is valid or not. If Yes, set TRUE. If not, set FALSE.
 * @property {boolean | null} is_task_successful - If Task Successful = True; If not = False
 * @property {boolean | null} local_model_id_enabled - If the task group has local model = true is hasn't = false
 * @property {string | null} origin_screen_name - Link or HomePage
 * @property {string | null} session_id - a unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} sponsored_name - The identification of the sponsor - Partner
 * @property {SurveyQuestionIncludedItem[] | null} [survey_question_included] - The array of survey question included. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {number | null} task_duration - The duration of the task.
 * @property {string | null} task_frequency - The frequency of the task
 * @property {string | null} [task_group_selection] - The task group selected in the task template creation
 * @property {string | null} task_profile - The profile of the task.
 * @property {number | null} task_time_ms - Time between "create task template" and "save task template" - MiliSecond
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {TypeMethod} type_method - The type of method.
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vision_job_id] - Identify the vision job id added by the user
 */
/**
 * @typedef NewMerchandiserTaskTemplateStepCompleted
 * @property {EntryMethod} entry_method - The entry method in the given context. It contains Permitted Values: 'New Template', 'Duplicate Template'
 * @property {ScreenName} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
 * @property {StepName} step_name - The step name the user completed. It contains permitted values. Samples: Type Selected, Template Submitted, Template Created
 * @property {number | null} step_number - The number of the step the user completed. It's an integer. Samples: 1, 3, 5
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 */
/**
 * @typedef NewMerchandiserTaskTemplateSubmitted
 * @property {ScreenName} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {number | null} total_dependent - The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_questions - The number of questions in the template. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef NewTaskTemplateCanceled
 * @property {EntryMethod} entry_method - The entry method in the given context. It contains Permitted Values: 'New Template', 'Duplicate Template'
 * @property {ScreenName} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 */
/**
 * @typedef NewTaskTemplateStarted
 * @property {EntryMethod} entry_method - The entry method in the given context. It contains Permitted Values: 'New Template', 'Duplicate Template'
 * @property {ScreenName} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
 */
/**
 * @typedef NewTaskTemplateStepCompleted
 * @property {EntryMethod} entry_method - The entry method in the given context. It contains Permitted Values: 'New Template', 'Duplicate Template'
 * @property {ScreenName} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
 * @property {StepName} step_name - The step name the user completed. It contains permitted values. Samples: Type Selected, Template Submitted, Template Created
 * @property {number | null} step_number - The number of the step the user completed. It's an integer. Samples: 1, 3, 5
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 */
/**
 * @typedef NewTaskTemplateSubmissionFailed
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {ScreenName} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {number | null} total_dependent - The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_questions - The number of questions in the template. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef NewTaskTemplateSubmissionSuccessful
 * @property {ScreenName} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_dependent - The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_questions - The number of questions in the template. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef NewTaskTemplateSubmitted
 * @property {ScreenName} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {number | null} total_dependent - The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_questions - The number of questions in the template. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef OperationSelected
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} country_name_update - Country updated of the POC. Example - BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {string | null} operation_id_update - The updated operation ID of the visit
 * @property {string | null} organization_name_update - The updated name of the organization. Example - Loreal
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} [seller_name_update] - The updated name of the seller. Example - DPC
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 * @property {string | null} vendor_id_name_update - The user vendor identity value updated
 */
/**
 * @typedef UserGroupsItem
 * @property {string | null} groups - User groups (Sample. GVBR, TaskManager, etc)
 */
/**
 * @typedef OperationsSearchStarted
 * @property {string | null} created_by - Catch user´s email
 * @property {string | null} search_country - Country select in the request
 * @property {string | null} search_id - Search id/name used in the request
 * @property {string | null} search_type - Search type chosen by the user: BDR, Account, Visits, Tasks
 * @property {string | null} search_vendor - Vendor select in the request
 * @property {string | null} session_id - a unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {UserGroupsItem[] | null} user_groups - The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef UserGroupsItem1
 * @property {string | null} groups - User groups (Sample. GVBR, TaskManager, etc)
 */
/**
 * @typedef PhotoTaskTemplate
 * @property {Bucket} bucket - The bucket chosen by the user
 * @property {string | null} created_by - Catch user´s email
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {boolean | null} is_sponsored - The file the user dropped or select is valid or not. If Yes, set TRUE. If not, set FALSE.
 * @property {boolean | null} is_task_successful - If Task Successful = True; If not = False
 * @property {string | null} origin_screen_name - Link or HomePage
 * @property {string | null} session_id - a unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} sponsored_name - The identification of the sponsor - Partner
 * @property {string | null} task_profile - The profile of the task.
 * @property {number | null} task_time_ms - Time between "create task template" and "save task template" - MiliSecond
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {TypeMethod} type_method - The type of method.
 * @property {UserGroupsItem1[] | null} user_groups - The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {string | null} [vendor_id] - The user vendor identity value
 * @property {string | null} [vision_job_id] - Identify the vision job id added by the user
 */
/**
 * @typedef UserGroupsItem2
 * @property {string | null} groups - User groups (Sample. GVBR, TaskManager, etc)
 */
/**
 * @typedef Prioritization
 * @property {number | null} active_prioritization_number - The number of the first priorization number
 * @property {string | null} [app_version] - Differ if the information is coming from V2 or V1
 * @property {boolean | null} is_active - The there is an active submission at the moment. If Yes, set TRUE. If not, set FALSE.
 * @property {string | null} session_id - a unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {number | null} target_prioritization_number - The number of the final priorization number
 * @property {string | null} [task_group_selection] - The task group selected in the task template creation
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {TriggerType} trigger_type - Type of trigger select.
 * @property {UserGroupsItem2[] | null} user_groups - The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef RefreshDataVisits
 * @property {string | null} button_name - What is the name of the button. Samples: Place Order
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef SearchVisits
 * @property {string | null} button_name - What is the name of the button. Samples: Place Order
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} search_content - The content typed by the user in the search field. Samples: 'Tarefa', 'Refrigerator'
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef SideBarMenuInteraction
 * @property {string | null} button_name - What is the name of the button. Samples: Place Order
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {string | null} origin_screen_name - Link or HomePage
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef UserGroupsItem3
 * @property {string | null} groups - User groups (Sample. GVBR, TaskManager, etc)
 */
/**
 * @typedef SimpleTaskTemplate
 * @property {boolean | null} effectiveness_check - True = There is at least one effe.chec ; false= no effe.check
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {boolean | null} is_sponsored - The file the user dropped or select is valid or not. If Yes, set TRUE. If not, set FALSE.
 * @property {boolean | null} is_task_successful - If Task Successful = True; If not = False
 * @property {string | null} origin_screen_name - Link or HomePage
 * @property {string | null} session_id - a unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {number | null} sku_tracking - We want to know the number of skus uploaded in the csv file or in the window
 * @property {string | null} sponsored_name - The identification of the sponsor - Partner
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {number | null} task_duration - The duration of the task.
 * @property {string | null} task_frequency - The frequency of the task
 * @property {string | null} task_profile - The profile of the task.
 * @property {TaskSubCategory} task_sub_category - The sub category task.
 * @property {number | null} task_time_ms - Time between "create task template" and "save task template" - MiliSecond
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {TypeMethod} type_method - The type of method.
 * @property {UserGroupsItem3[] | null} user_groups - The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef SortingVisits
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {string | null} order_priority - Order priority. Example: ascending
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} sorting_info - Sorting info. Example: visit_date
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef UserGroupsItem4
 * @property {string | null} groups - User groups (Sample. GVBR, TaskManager, etc)
 */
/**
 * @typedef SurveyTaskTemplate
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {boolean | null} is_sponsored - The file the user dropped or select is valid or not. If Yes, set TRUE. If not, set FALSE.
 * @property {boolean | null} is_task_successful - If Task Successful = True; If not = False
 * @property {string | null} origin_screen_name - Link or HomePage
 * @property {string | null} session_id - a unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} sponsored_name - The identification of the sponsor - Partner
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {number | null} task_duration - The duration of the task.
 * @property {string | null} task_frequency - The frequency of the task
 * @property {string | null} task_profile - The profile of the task.
 * @property {number | null} task_time_ms - Time between "create task template" and "save task template" - MiliSecond
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {Record<string, any> | null} total_question_type - The number of each question Type (SINGLESELECTION; FREETEXT; CONDITIONAL; NUMERIC; MULTIPLESELECTION)
 * @property {number | null} total_questions - The number of questions in the template. It's an integer. Samples: 1, 3, 5
 * @property {TypeMethod} type_method - The type of method.
 * @property {UserGroupsItem4[] | null} user_groups - The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef TaskAssignmentCanceled
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_conflict_pocs - The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_invalid_pocs - The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_valid_pocs - The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef TaskAssignmentDeleted
 * @property {string | null} submission_id - The submission ID generated by "TaskService". Sample: fe573f8c-645c-4920-bdd7-612819866838
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_completed - The total amount of POCs that the BDR already completed the task. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_pocs - The total amount of POCs in the given context. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef TaskAssignmentStarted
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 */
/**
 * @typedef TaskAssignmentSubmissionFailed
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_conflict_pocs - The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_invalid_pocs - The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_valid_pocs - The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef TaskAssignmentSubmissionSuccessful
 * @property {string | null} submission_id - The submission ID generated by "TaskService". Sample: fe573f8c-645c-4920-bdd7-612819866838
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_conflict_pocs - The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_invalid_pocs - The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_valid_pocs - The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef TaskAssignmentSubmitted
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_conflict_pocs - The total amount of POCs that had conflicted with other previous submissions. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_invalid_pocs - The total amount of POCs that were not found in BEES Accounts. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_valid_pocs - The total amount of POCs identified successfully and not conflicting with previous submissions. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef UserGroupsItem5
 * @property {string | null} groups - User groups (Sample. GVBR, TaskManager, etc)
 */
/**
 * @typedef TaskSearched
 * @property {string | null} [app_version] - Differ if the information is coming from V2 or V1
 * @property {string | null} origin_screen_name - Link or HomePage
 * @property {ScreenName} screen_name - What is the name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Homepage', 'Task Edition'
 * @property {string | null} search_content - The content typed by the user in the search field. Samples: 'Tarefa', 'Refrigerator'
 * @property {Record<string, any> | null} search_filter - Applied filters (Sample: {isSponsor: false} )
 * @property {number | null} search_results - The amount of results returned considering the search content. It's an integer. Samples: 3, 5, 8
 * @property {string | null} session_id - a unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} [task_group_selection] - The task group selected in the task template creation
 * @property {UserGroupsItem5[] | null} user_groups - The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef UserGroupsItem6
 * @property {string | null} groups - User groups (Sample. GVBR, TaskManager, etc)
 */
/**
 * @typedef TaskSubmission
 * @property {string | null} [app_version] - Differ if the information is coming from V2 or V1
 * @property {number | null} audience_tracking - We want to know the number of POCs uploaded in the csv file
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {Bucket} image_recognition_task_sub_category - Imagem recognition for the task sub category
 * @property {boolean | null} is_submission_successful - If Task Successful = True; If not = False
 * @property {string | null} session_id - a unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {SimpleTaskSubCategory} simple_task_sub_category - Simple task for sub category
 * @property {number | null} submission_time_ms - Time between "airplane icon" and "submit task" - milisecond
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {string | null} [task_group_selection] - The task group selected in the task template creation
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {number | null} task_validity - Number of days count from the field 'start end date'
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {UserGroupsItem6[] | null} user_groups - The array of groups. Attention. Array props should directly contain the sub objects, not the 'items' as an object like in the protocols UI.
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef TaskTemplateDeleted
 * @property {boolean | null} is_active - The there is an active submission at the moment. If Yes, set TRUE. If not, set FALSE.
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_submissions - The total amount of submissions for the Task Template. it's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef TaskTemplateUpdateCanceled
 * @property {boolean | null} has_changes - The flag that indicates if the user made any change on the form before performing the action. It's a boolean. If Yes, set TRUE. If not, set False.
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 */
/**
 * @typedef TaskTemplateUpdateStarted
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_dependent - The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_questions - The number of questions in the template. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef TaskTemplateUpdateSubmissionFailed
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {boolean | null} has_changes - The flag that indicates if the user made any change on the form before performing the action. It's a boolean. If Yes, set TRUE. If not, set False.
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_dependent - The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_questions - The number of questions in the template. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef TaskTemplateUpdateSubmissionSuccessful
 * @property {boolean | null} has_changes - The flag that indicates if the user made any change on the form before performing the action. It's a boolean. If Yes, set TRUE. If not, set False.
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_dependent - The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_questions - The number of questions in the template. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef TaskTemplateUpdateSubmitted
 * @property {boolean | null} has_changes - The flag that indicates if the user made any change on the form before performing the action. It's a boolean. If Yes, set TRUE. If not, set False.
 * @property {string | null} task_category - The category of the task. It contains permitted values. Customer Satisfaction, Digital Education, Value Creation, Execution)
 * @property {TaskType} task_type - The type of the task that will guide the experience of the BDR. It contains permitted values. Samples: Simple, Survey, Photo)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {number | null} total_dependent - The number of dependent questions in the template. It's an integer. Samples: 1, 3, 5
 * @property {number | null} total_questions - The number of questions in the template. It's an integer. Samples: 1, 3, 5
 */
/**
 * @typedef VisionJobId
 * @property {Bucket} bucket - The bucket chosen by the user
 * @property {string | null} created_by - Catch user´s email
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {boolean | null} is_successful - If Task Successful = True; If not = False
 * @property {string | null} session_id - a unique identifier for the user's session (expires when exiting the page, exits the mfe)
 * @property {string | null} [template_id] - The template ID generated by "TaskService". Sample: f24e2877-ab9d-463d-a249-927eab749276
 * @property {TypeMethod} type_method - The type of method.
 * @property {string | null} [vendor_id] - The user vendor identity value
 * @property {string | null} [vision_job_id] - Identify the vision job id added by the user
 */
/**
 * @typedef VisitCancel
 * @property {string | null} button_name - What is the name of the button. Samples: Place Order
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 * @property {string | null} visit_id - Visit ID
 */
/**
 * @typedef VisitDetails
 * @property {string | null} button_name - What is the name of the button. Samples: Place Order
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 */
/**
 * @typedef VisitList
 * @property {string | null} button_name - What is the name of the button. Samples: Place Order
 * @property {string | null} component_name - Component Name. Example: menu_sidebar_collapsed
 * @property {string | null} country - Country of the POC. Example: BR
 * @property {string | null} [ddc] - The DDC. Example - 743824729 / DDC Campinas
 * @property {string | null} device_language - Device Language. Example: pt-BR
 * @property {string | null} email - The user email. lastName.firstName@ab-inbev.com
 * @property {string | null} [failure_reason] - The reason of failure in the given context. Samples: 'Required field not filled', 'Generic Bad Request'
 * @property {string | null} feature_name - Feature Name. Example: Visits
 * @property {ScreenNameApp} screen_name_app - The App Name in a given context. Permitted values. Example - Tasks
 * @property {string | null} user_selected_language - User Selected Language. Example: pt-BR
 * @property {string | null} vendor_group_id - User's vendorGroupID, Example: Pepsico
 * @property {string | null} [vendor_id] - The user vendor identity value
 * @property {string | null} visit_id - Visit ID
 */

/**
 * When the user clicks to download the POCs of a submission (task assignment)
 *
 * @param {AccountsAssignedFileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountsAssignedFileDownloaded(
	props: AccountsAssignedFileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Accounts Assigned File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks in a button
 *
 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function buttonClicked(
	props: ButtonClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user select or drop a CSV  in the drop zone. This action triggers the validation
 *
 * @param {CsvAccountListValidated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function csvAccountListValidated(
	props: CsvAccountListValidated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'CSV Account List Validated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user click on a button to select a DDC
 *
 * @param {DdcSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ddcSelected(
	props: DdcSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'DDC Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user filter visits by date
 *
 * @param {DateFilterVisits} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dateFilterVisits(
	props: DateFilterVisits,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Date Filter Visits',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user click on a button to edit a visit
 *
 * @param {EditVisit} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editVisit(
	props: EditVisit,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Edit Visit', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * when the user clicks to download the error file generated by the validation
 *
 * @param {ErrorFileDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorFileDownloaded(
	props: ErrorFileDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Error File Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user filters the visits by a specific criteria
 *
 * @param {FilterVisits} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function filterVisits(
	props: FilterVisits,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Filter Visits',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on the save task button
 *
 * @param {FrontlineTaskCreation} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function frontlineTaskCreation(
	props: FrontlineTaskCreation,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Frontline Task Creation',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user completes a new MERCHANDISER task template and clicks on save task template
 *
 * @param {NewMerchandiserTaskTemplateStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newMerchandiserTaskTemplateStepCompleted(
	props: NewMerchandiserTaskTemplateStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Merchandiser Task Template Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Exactly when the user clicks to submit the New Merchandiser Task Template. Should fire before Submission Completed or Failed
 *
 * @param {NewMerchandiserTaskTemplateSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newMerchandiserTaskTemplateSubmitted(
	props: NewMerchandiserTaskTemplateSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Merchandiser Task Template Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * when the user cancels the task template creation flow and go back to the task template list
 *
 * @param {NewTaskTemplateCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newTaskTemplateCanceled(
	props: NewTaskTemplateCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Task Template Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user starts the task template creation flow

 *
 * @param {NewTaskTemplateStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newTaskTemplateStarted(
	props: NewTaskTemplateStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Task Template Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user completes a step in the New Task Template Journey
 *
 * @param {NewTaskTemplateStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newTaskTemplateStepCompleted(
	props: NewTaskTemplateStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Task Template Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the service responds that the New Task Template submission was Failed
 *
 * @param {NewTaskTemplateSubmissionFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newTaskTemplateSubmissionFailed(
	props: NewTaskTemplateSubmissionFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Task Template Submission Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the service responds that the New Task Template submission was successful
 *
 * @param {NewTaskTemplateSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newTaskTemplateSubmissionSuccessful(
	props: NewTaskTemplateSubmissionSuccessful,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Task Template Submission Successful',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Exactly when the user clicks to submit the New Task Template. Should fire before Submission Successful or Failed
 *
 * @param {NewTaskTemplateSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newTaskTemplateSubmitted(
	props: NewTaskTemplateSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Task Template Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user click on a button to edit a operation
 *
 * @param {OperationSelected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function operationSelected(
	props: OperationSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Operation Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on the "Search" button in the Operations Panel MFE
 *
 * @param {OperationsSearchStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function operationsSearchStarted(
	props: OperationsSearchStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Operations Search Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user completes a the New Photo Task Template Journey - button: Save Photo Template
 *
 * @param {PhotoTaskTemplate} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function photoTaskTemplate(
	props: PhotoTaskTemplate,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Photo Task Template',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks on the "drag & drop" or "priority typing"
 *
 * @param {Prioritization} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function prioritization(
	props: Prioritization,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Prioritization',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user refreshes the data in the visits overview
 *
 * @param {RefreshDataVisits} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function refreshDataVisits(
	props: RefreshDataVisits,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Refresh Data Visits',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user searches for a visit by a specific criteria
 *
 * @param {SearchVisits} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function searchVisits(
	props: SearchVisits,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Search Visits',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user click on side bar menu
 *
 * @param {SideBarMenuInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sideBarMenuInteraction(
	props: SideBarMenuInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Side Bar Menu Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user completes a step in the New Task Template Journey - button: Save task template
 *
 * @param {SimpleTaskTemplate} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function simpleTaskTemplate(
	props: SimpleTaskTemplate,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Simple Task Template',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user sorts the visits by a specific criteria
 *
 * @param {SortingVisits} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sortingVisits(
	props: SortingVisits,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Sorting Visits',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user completes a the New Survey Task Template Journey - button: Save Survey Template
 *
 * @param {SurveyTaskTemplate} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function surveyTaskTemplate(
	props: SurveyTaskTemplate,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Survey Task Template',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user drops the Task Assignment flow
 *
 * @param {TaskAssignmentCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskAssignmentCanceled(
	props: TaskAssignmentCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Assignment Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user performs a deletion of a Task Template assigned
 *
 * @param {TaskAssignmentDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskAssignmentDeleted(
	props: TaskAssignmentDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Assignment Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user start the task assignment flow, by clicking in the task 'plane' icon
 *
 * @param {TaskAssignmentStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskAssignmentStarted(
	props: TaskAssignmentStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Assignment Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the service responses with an error in the Task Assignment submission process
 *
 * @param {TaskAssignmentSubmissionFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskAssignmentSubmissionFailed(
	props: TaskAssignmentSubmissionFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Assignment Submission Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user receives a success message telling the task was successfully assigned
 *
 * @param {TaskAssignmentSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskAssignmentSubmissionSuccessful(
	props: TaskAssignmentSubmissionSuccessful,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Assignment Submission Successful',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Exactly when the user clicks to submit the task template assigned. Should fire before Submission Successful or Failed
 *
 * @param {TaskAssignmentSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskAssignmentSubmitted(
	props: TaskAssignmentSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Assignment Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user finishes typing in the Task search bar to analyse the search results
 *
 * @param {TaskSearched} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskSearched(
	props: TaskSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user clicks "Submit Task"
 *
 * @param {TaskSubmission} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskSubmission(
	props: TaskSubmission,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Submission',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user performs a deletion of a Task Template
 *
 * @param {TaskTemplateDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskTemplateDeleted(
	props: TaskTemplateDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Template Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user cancels the task update flow
 *
 * @param {TaskTemplateUpdateCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskTemplateUpdateCanceled(
	props: TaskTemplateUpdateCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Template Update Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user selects a template to update, starting the Task Template Update Journey
 *
 * @param {TaskTemplateUpdateStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskTemplateUpdateStarted(
	props: TaskTemplateUpdateStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Template Update Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the service responds that the Task Template Update submission was Failed
 *
 * @param {TaskTemplateUpdateSubmissionFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskTemplateUpdateSubmissionFailed(
	props: TaskTemplateUpdateSubmissionFailed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Template Update Submission Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user receives a success message telling the record was updated
 *
 * @param {TaskTemplateUpdateSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskTemplateUpdateSubmissionSuccessful(
	props: TaskTemplateUpdateSubmissionSuccessful,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Template Update Submission Successful',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Exactly when the user clicks to submit the Task Template Update. Should fire before Submission Successful or Failed
 *
 * @param {TaskTemplateUpdateSubmitted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function taskTemplateUpdateSubmitted(
	props: TaskTemplateUpdateSubmitted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Task Template Update Submitted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user click on "ADD" after pasting "Vision Job ID"
 *
 * @param {VisionJobId} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function visionJobId(
	props: VisionJobId,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Vision Job ID',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user click on a button to cancel a visit
 *
 * @param {VisitCancel} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function visitCancel(
	props: VisitCancel,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Visit Cancel',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user click on visit details button
 *
 * @param {VisitDetails} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function visitDetails(
	props: VisitDetails,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Visit Details',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When a user click on a visit to see the details
 *
 * @param {VisitList} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function visitList(
	props: VisitList,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Visit List', props || {}, withTypewriterContext(options), callback)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * When the user clicks to download the POCs of a submission (task assignment)
	 *
	 * @param {AccountsAssignedFileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountsAssignedFileDownloaded,
	/**
	 * When the user clicks in a button
	 *
	 * @param {ButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	buttonClicked,
	/**
	 * When the user select or drop a CSV  in the drop zone. This action triggers the validation
	 *
	 * @param {CsvAccountListValidated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	csvAccountListValidated,
	/**
	 * When a user click on a button to select a DDC
	 *
	 * @param {DdcSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ddcSelected,
	/**
	 * When a user filter visits by date
	 *
	 * @param {DateFilterVisits} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	dateFilterVisits,
	/**
	 * When a user click on a button to edit a visit
	 *
	 * @param {EditVisit} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	editVisit,
	/**
	 * when the user clicks to download the error file generated by the validation
	 *
	 * @param {ErrorFileDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	errorFileDownloaded,
	/**
	 * When the user filters the visits by a specific criteria
	 *
	 * @param {FilterVisits} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	filterVisits,
	/**
	 * When the user clicks on the save task button
	 *
	 * @param {FrontlineTaskCreation} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	frontlineTaskCreation,
	/**
	 * When the user completes a new MERCHANDISER task template and clicks on save task template
	 *
	 * @param {NewMerchandiserTaskTemplateStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newMerchandiserTaskTemplateStepCompleted,
	/**
	 * Exactly when the user clicks to submit the New Merchandiser Task Template. Should fire before Submission Completed or Failed
	 *
	 * @param {NewMerchandiserTaskTemplateSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newMerchandiserTaskTemplateSubmitted,
	/**
	 * when the user cancels the task template creation flow and go back to the task template list
	 *
	 * @param {NewTaskTemplateCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newTaskTemplateCanceled,
	/**
	 * When the user starts the task template creation flow
	
	 *
	 * @param {NewTaskTemplateStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newTaskTemplateStarted,
	/**
	 * When the user completes a step in the New Task Template Journey
	 *
	 * @param {NewTaskTemplateStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newTaskTemplateStepCompleted,
	/**
	 * When the service responds that the New Task Template submission was Failed
	 *
	 * @param {NewTaskTemplateSubmissionFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newTaskTemplateSubmissionFailed,
	/**
	 * When the service responds that the New Task Template submission was successful
	 *
	 * @param {NewTaskTemplateSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newTaskTemplateSubmissionSuccessful,
	/**
	 * Exactly when the user clicks to submit the New Task Template. Should fire before Submission Successful or Failed
	 *
	 * @param {NewTaskTemplateSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newTaskTemplateSubmitted,
	/**
	 * When a user click on a button to edit a operation
	 *
	 * @param {OperationSelected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	operationSelected,
	/**
	 * When the user clicks on the "Search" button in the Operations Panel MFE
	 *
	 * @param {OperationsSearchStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	operationsSearchStarted,
	/**
	 * When the user completes a the New Photo Task Template Journey - button: Save Photo Template
	 *
	 * @param {PhotoTaskTemplate} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	photoTaskTemplate,
	/**
	 * When the user clicks on the "drag & drop" or "priority typing"
	 *
	 * @param {Prioritization} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	prioritization,
	/**
	 * When a user refreshes the data in the visits overview
	 *
	 * @param {RefreshDataVisits} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	refreshDataVisits,
	/**
	 * When the user searches for a visit by a specific criteria
	 *
	 * @param {SearchVisits} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	searchVisits,
	/**
	 * When a user click on side bar menu
	 *
	 * @param {SideBarMenuInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sideBarMenuInteraction,
	/**
	 * When the user completes a step in the New Task Template Journey - button: Save task template
	 *
	 * @param {SimpleTaskTemplate} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	simpleTaskTemplate,
	/**
	 * When the user sorts the visits by a specific criteria
	 *
	 * @param {SortingVisits} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sortingVisits,
	/**
	 * When the user completes a the New Survey Task Template Journey - button: Save Survey Template
	 *
	 * @param {SurveyTaskTemplate} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	surveyTaskTemplate,
	/**
	 * When the user drops the Task Assignment flow
	 *
	 * @param {TaskAssignmentCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskAssignmentCanceled,
	/**
	 * When the user performs a deletion of a Task Template assigned
	 *
	 * @param {TaskAssignmentDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskAssignmentDeleted,
	/**
	 * When the user start the task assignment flow, by clicking in the task 'plane' icon
	 *
	 * @param {TaskAssignmentStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskAssignmentStarted,
	/**
	 * When the service responses with an error in the Task Assignment submission process
	 *
	 * @param {TaskAssignmentSubmissionFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskAssignmentSubmissionFailed,
	/**
	 * When the user receives a success message telling the task was successfully assigned
	 *
	 * @param {TaskAssignmentSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskAssignmentSubmissionSuccessful,
	/**
	 * Exactly when the user clicks to submit the task template assigned. Should fire before Submission Successful or Failed
	 *
	 * @param {TaskAssignmentSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskAssignmentSubmitted,
	/**
	 * When the user finishes typing in the Task search bar to analyse the search results
	 *
	 * @param {TaskSearched} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskSearched,
	/**
	 * When the user clicks "Submit Task"
	 *
	 * @param {TaskSubmission} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskSubmission,
	/**
	 * When the user performs a deletion of a Task Template
	 *
	 * @param {TaskTemplateDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskTemplateDeleted,
	/**
	 * When the user cancels the task update flow
	 *
	 * @param {TaskTemplateUpdateCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskTemplateUpdateCanceled,
	/**
	 * When the user selects a template to update, starting the Task Template Update Journey
	 *
	 * @param {TaskTemplateUpdateStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskTemplateUpdateStarted,
	/**
	 * When the service responds that the Task Template Update submission was Failed
	 *
	 * @param {TaskTemplateUpdateSubmissionFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskTemplateUpdateSubmissionFailed,
	/**
	 * When the user receives a success message telling the record was updated
	 *
	 * @param {TaskTemplateUpdateSubmissionSuccessful} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskTemplateUpdateSubmissionSuccessful,
	/**
	 * Exactly when the user clicks to submit the Task Template Update. Should fire before Submission Successful or Failed
	 *
	 * @param {TaskTemplateUpdateSubmitted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	taskTemplateUpdateSubmitted,
	/**
	 * When the user click on "ADD" after pasting "Vision Job ID"
	 *
	 * @param {VisionJobId} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	visionJobId,
	/**
	 * When a user click on a button to cancel a visit
	 *
	 * @param {VisitCancel} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	visitCancel,
	/**
	 * When a user click on visit details button
	 *
	 * @param {VisitDetails} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	visitDetails,
	/**
	 * When a user click on a visit to see the details
	 *
	 * @param {VisitList} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	visitList,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_1qAQlNoLyYbpho85MtffF3D1IBX`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
