import { useState, useRef } from 'react';

import { usePreferredLanguageV2, useToast, TypeToast } from 'admin-portal-shared-services';

import { CustomLanguageType } from '@/components/Overview/types/FilterModalCalendar';
import { DateFormats } from '@/consts/GlobalConsts';
import { Locales } from '@/enums/locales';
import { formatDate } from '@/utils/dateFormatter/dateFormatter';

import { useTranslation } from '../useTranslation/useTranslation';

export const useEditVisitModal = () => {
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const language = usePreferredLanguageV2().preferredLanguage ?? 'en-US';
  const toastService = useToast();
  const { noDataAvailable, savedChanges } = useTranslation();

  const parseDateToString = () => {
    const currentDate = selectedDate || new Date();

    const formattedDate = formatDate(currentDate, Locales.enUS, DateFormats.SHORT_REVERSE);
    return formattedDate;
  };

  const parseNextVisitLabelDate = (date?: Date) => {
    if (!date) return noDataAvailable;
    const parsedDate = formatDate(date, language as Locales, DateFormats.MEDIUM);
    return parsedDate;
  };

  const handleOnSaveDate = (date: Date) => {
    setSelectedDate(date);
    setOpenCalendar(false);
  };

  const handleOnSaveModal = () => {
    setOpenCalendar(false);
    toastService.notify({
      message: savedChanges,
      type: TypeToast.SUCCESS,
    });
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  const calendarLanguage = (): CustomLanguageType | undefined => {
    const lang = language && language.split('-')[0];
    return lang as unknown as CustomLanguageType;
  };

  return {
    isOpenCalendar,
    setOpenCalendar,
    selectedDate,
    setSelectedDate,
    parseDateToString,
    parseVisitLabelDate: parseNextVisitLabelDate,
    handleOnSaveDate,
    handleOnSaveModal,
    containerRef,
    selectedStatus,
    setSelectedStatus,
    calendarLanguage,
  };
};
