import { Badge } from '@hexa-ui/components';
import styled from 'styled-components';

import { BadgeStatusProps } from './types/BadgeStatusTypes';

export const BadgeStatus = styled(Badge.Status)<BadgeStatusProps>`
  background-color: ${(props) => props.$bgcolor};

  > p {
    color: ${(props) => props.$labelcolor};
    display: flex;
  }
  > p::before {
    content: '•';
    color: ${(props) => props.$labelcolor};
    font-size: 1.2em;
    margin-right: 4px;
  }
`;
