import { useCallback, useEffect, useRef, useState } from 'react';

import { SEARCH_PARAMETERS } from '@/enums/searchEnum';
import { setAccountId, setBdrId } from '@/stores/visitsParams/VisitsParamsEvents';
import { useVisitsParamStore } from '@/stores/visitsParams/VisitsParamsStore';

import { useSegmentSearchVisits } from '../useTableVisits/hooks/useSegmentSearchVisits/useSegmentSearchVisits';

export const useSearchVisits = () => {
  const { bdrId, accountId } = useVisitsParamStore();
  const { dispatchSearchEvent } = useSegmentSearchVisits();

  const [filterValue, setFilterValue] = useState(SEARCH_PARAMETERS.BDR_ID);

  const activeFilter = filterValue === SEARCH_PARAMETERS.ACCOUNT_ID ? accountId : bdrId;
  const [searchValue, setSearchValue] = useState(activeFilter);

  const prevSearchValueRef = useRef(searchValue);
  const prevFilterValueRef = useRef(filterValue);

  const onClickSearch = useCallback(() => {
    if (filterValue === SEARCH_PARAMETERS.ACCOUNT_ID) {
      setBdrId('');
      setAccountId(searchValue);
    } else {
      setAccountId('');
      setBdrId(searchValue);
    }

    dispatchSearchEvent(searchValue, filterValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, dispatchSearchEvent, searchValue]);

  const onClearSearch = () => {
    setAccountId('');
    setBdrId('');
    setSearchValue('');
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (
        prevSearchValueRef.current !== searchValue ||
        prevFilterValueRef.current !== filterValue
      ) {
        onClickSearch();
        prevSearchValueRef.current = searchValue;
        prevFilterValueRef.current = filterValue;
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue, filterValue, onClickSearch]);

  useEffect(() => {
    setSearchValue(activeFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, accountId, bdrId]);

  return {
    filterValue,
    searchValue,
    setFilterValue,
    setSearchValue,
    onClickSearch,
    onClearSearch,
  };
};
