import styled from 'styled-components';

export const WrapperInformationContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const WrapperInformation = styled.div`
  padding-bottom: 2rem;
`;

export const WrapperLoadingContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
