import styled from 'styled-components';

export const LoadingContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: 100%;
  gap: 16px;
`;

export const LoadingContentLabel = styled.label`
  font-family: Work Sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #141414;
`;
