import { createStore } from 'effector';
import { useUnit } from 'effector-react';

import { resetVisitsState, updateVisitsState } from './VisitsParamsEvents';
import { initialState, VisitsState } from './VisitsParamsState';

const $visitsState = createStore<VisitsState>(initialState)
  .on(updateVisitsState, (state, updates) => ({ ...state, ...updates }))
  .reset(resetVisitsState);

export function useVisitsParamStore() {
  return useUnit($visitsState);
}
