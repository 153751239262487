import analytics from '@/config/typewriter';
import { useAnalytics } from '@/hooks/useAnalytics/useAnalytics';
import { VisitDetailsSegmentProps } from '@/types/AnalyticsTypes';

export const useSegmentVisitDetails = () => {
  const { defaultProps } = useAnalytics();

  const dispatchVisitDetailsEvent = (buttonName: string, componentName: string) => {
    const visitDetailProps = {
      ...defaultProps,
      [VisitDetailsSegmentProps.ButtonName]: buttonName,
      [VisitDetailsSegmentProps.ComponentName]: componentName,
      [VisitDetailsSegmentProps.FailureReason]: '',
    };

    try {
      analytics.visitDetails(visitDetailProps);
    } catch (error) {
      analytics.visitDetails({
        ...visitDetailProps,
        [VisitDetailsSegmentProps.FailureReason]:
          error instanceof Error ? error.message : VisitDetailsSegmentProps.unknownError,
      });
    }
  };

  return {
    dispatchVisitDetailsEvent,
  };
};
