import { tokens } from '@bees-force-shared/theme';
import { Alert, Button, IconButton } from '@hexa-ui/components';
import styled from 'styled-components';

export const WrapperModalContentFields = styled.div`
  width: 47.25rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.measure.space['space-4']};
  font-size: ${({ theme }) => theme.measure['font-size']['fontsize-2']};
  font-weight: ${({ theme }) => theme.fonts['font-weight']['semibold']};
  font-family: ${({ theme }) => theme.fonts['font-family']['Work Sans']};
  & .visit-date {
    font-weight: ${({ theme }) => theme.fonts['font-weight']['normal']};
  }
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.measure.space['space-4']};
  flex-direction: row;
`;

export const OpenCalendarButton = styled(Button)`
  width: 100%;
  border-radius: ${({ theme }) => theme.measure['border-radius']['small']};
  margin-top: ${({ theme }) => theme.measure.space['space-1']};
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--colors-interfaceSurfaceSecondary);
  font-size: ${({ theme }) => theme.measure['font-size']['fontsize-3']};
  padding: ${({ theme }) => theme.measure.space['space-3']};
  &:hover {
    background: none;
    border: 1px solid var(--colors-interfaceLabelPrimary);
    box-shadow: none;
  }
`;

export const CustomIconButton = styled(IconButton)`
  &:hover {
    background-color: transparent;
  }
`;

export const VisitLabel = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.measure['font-size']['fontsize-3']};
  font-weight: ${({ theme }) => theme.fonts['font-weight']['normal']};
  font-family: ${({ theme }) => theme.fonts['font-family']['Work Sans']};
  & span {
    font-size: ${({ theme }) => theme.measure['font-size']['fontsize-2']};
    color: var(--colors-interfaceLabelPrimary);
    font-weight: ${({ theme }) => theme.fonts['font-weight']['semibold']};
    line-height: ${({ theme }) => theme.measure.space['space-5']};
  }
  grid-column: span 3;
`;

export const WrapperDateAndCalendar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const WrapperLabelWithCalendar = styled.div`
  width: 100%;
`;

export const WrapperSelectStatus = styled.div`
  width: 100%;
`;

export const WrapperDateAndStatus = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.measure.space['space-8']};
  grid-column: span 3;
`;

export const WrapperInputTime = styled.div`
  display: flex;
  justify-content: space-between;
  grid-column: span 3;
  width: 100%;
`;

export const AlertWrapper = styled(Alert)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${tokens.measure.space['space-4']};
  grid-column: span 3;
`;
