import { AxiosRequestHeaders } from 'axios';

import { PaginationModel } from '@/types/pagination';

export interface GetVisits {
  signal?: AbortSignal;
  headers?: AxiosRequestHeaders;
  vendorId: string;
  deliveryCenterId?: string;
  bdrId?: string;
  accountId?: string;
  status?: VisitStatus;
  page?: number;
  pageSize?: number;
  visitDate?: string;
  startDate?: string;
  endDate?: string;
  sort?: Order;
}

export interface VisitsModel {
  id: string;
  visitId: string;
  vendorId: string;
  deliveryCenterId: string;
  bdrId: string;
  accountId: string;
  status: VisitStatus;
  page: number;
  pageSize: number;
  startDate: string;
  visitDate: string;
  endDate: string;
  sort: Order;
  poc?: {
    id: string;
    name: string;
    email: string;
    latitude: number;
    longitude: number;
  };
  bdr?: {
    id: string;
    name: string;
  };
  nextVisit?: {
    date: string;
    status: string;
  };
  canceledDueTo?: string;
  estimatedTimeMin?: number;
  estimatedTimeMax?: number;
  suggestedTime?: string;
}

export interface VisitsListModel {
  content: VisitsModel[];
  pagination: PaginationModel;
}

export enum VisitStatus {
  JUSTIFIED = 'JUSTIFIED',
  SCHEDULE = 'SCHEDULE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum Order {
  VISIT_DATE_ASC = 'visitDate,asc',
  VISIT_DATE_DESC = 'visitDate,desc',
}
