import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSegmentFilterVisits } from '@/components/TableVisitCard/hooks/useFilterVisits/useSegmentFilterVisits';
import { ActionButtons } from '@/enums/filterVisits';

import { FILTERS_VISITS_IDS } from '../../consts/FiltersVisits';
import { UseFilterVisits, useFilterVisitsProps } from '../../types/FilterVisits';
import { updateStatusStore } from '../../utils/statusFilters';
import { useTranslation } from '../useTranslation/useTranslation';

export const useFilterVisits = ({
  appliedFilters,
  onApplyFilter,
  filterRef,
}: useFilterVisitsProps): UseFilterVisits => {
  const { scheduled, inProgress, canceled, justified, completed } = useTranslation();
  const { callFilterVisitsEvent } = useSegmentFilterVisits();

  const initialFilters = useMemo(
    () => [
      { id: FILTERS_VISITS_IDS.scheduled, label: scheduled, checked: false },
      { id: FILTERS_VISITS_IDS.completed, label: completed, checked: false },
      { id: FILTERS_VISITS_IDS.canceled, label: canceled, checked: false },
      { id: FILTERS_VISITS_IDS.justified, label: justified, checked: false },
      { id: FILTERS_VISITS_IDS.inProgress, label: inProgress, checked: false },
    ],
    [scheduled, completed, canceled, justified, inProgress],
  );

  const getFilteredByAppliedFilters = useCallback(() => {
    return initialFilters.map((filter) => ({
      ...filter,
      checked: appliedFilters.includes(filter.id),
    }));
  }, [appliedFilters, initialFilters]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [tempFilters, setTempFilters] = useState(getFilteredByAppliedFilters());
  const [countAppliedFilters, setCountAppliedFilters] = useState(appliedFilters.length);

  const isApplySomeFilters = useMemo(
    () => tempFilters.some((filter) => filter.checked),
    [tempFilters],
  );

  const callSegmentEvent = useCallback(
    (buttonName: string) => {
      let activeFilters: string[] = [];
      if (buttonName !== ActionButtons.clear) {
        activeFilters = tempFilters.filter((filter) => filter.checked).map((filter) => filter.id);
      }
      callFilterVisitsEvent(buttonName, activeFilters);
    },
    [callFilterVisitsEvent, tempFilters],
  );

  useEffect(() => {
    const count = tempFilters.filter((filter) => filter.checked).length;
    setCountAppliedFilters(count);
  }, [tempFilters]);

  const handleClickOutside = useCallback(
    (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target) && isExpanded) {
        setIsExpanded(false);
        callSegmentEvent(ActionButtons.cancel);
      }
    },
    [filterRef, isExpanded, callSegmentEvent],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleClearFilters = () => {
    setTempFilters(initialFilters);
    callSegmentEvent(ActionButtons.clear);
  };

  const handleOpenCloseFilter = (buttonName?: string) => {
    setIsExpanded((prev) => !prev);
    if (!!buttonName && typeof buttonName === 'string') callSegmentEvent(buttonName);
    setTempFilters(getFilteredByAppliedFilters());
  };

  const handleClickCancel = () => {
    handleOpenCloseFilter(ActionButtons.cancel);
  };

  const handleClickFilter = (id) => {
    setTempFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.id === id ? { ...filter, checked: !filter.checked } : filter,
      ),
    );
  };

  const handleClickApply = () => {
    const activeFilters = tempFilters.filter((filter) => filter.checked).map((filter) => filter.id);

    updateStatusStore(activeFilters);

    onApplyFilter(activeFilters);
    handleOpenCloseFilter(ActionButtons.apply);
  };

  return {
    isExpanded,
    isApplySomeFilters,
    tempFilters,
    countAppliedFilters,
    handleOpenCloseFilter,
    handleClickFilter,
    handleClearFilters,
    handleClickApply,
    handleClickCancel,
  };
};
