import { useAuthenticationService } from 'admin-portal-shared-services';
import { v4 as uuid4 } from 'uuid';

import { ScreenNameApp } from '@/config/typewriter';
import { FEATURE_NAME } from '@/consts/AnalyticsConsts';

import { CommonSegmentProp } from '../../types/AnalyticsTypes';

export const useAnalytics = () => {
  const mockUser = {
    // TODO: Change the mock to real data when implementing the option selector
    ddc: 'ddc',
    vendorGroupId: 'vendor_group_id',
    userGroups: [],
  };

  const authentication = useAuthenticationService();
  const { user_country: userCountry, language } = authentication.getUserCountryAndLanguage();
  const vendorId = authentication.getVendorId();
  const email = authentication.getUserEmailB2C();

  const defaultProps = {
    [CommonSegmentProp.country]: userCountry,
    [CommonSegmentProp.ddc]: mockUser.ddc,
    [CommonSegmentProp.deviceLanguage]: language,
    [CommonSegmentProp.email]: email,
    [CommonSegmentProp.userSelectedLanguage]: language,
    [CommonSegmentProp.vendorGroupId]: mockUser.vendorGroupId,
    [CommonSegmentProp.vendorId]: vendorId,
    [CommonSegmentProp.sessionId]: uuid4(),
    [CommonSegmentProp.userGroups]: mockUser.userGroups,
    [CommonSegmentProp.featureName]: FEATURE_NAME,
    [CommonSegmentProp.screenNameApp]: ScreenNameApp.Visit,
  };

  return {
    defaultProps,
  };
};
