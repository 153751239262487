import { Card } from '@hexa-ui/components';
import styled from 'styled-components';

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperEmpty = styled(Card)`
  padding-top: ${({ theme }) => theme.measure.space['space-6']};
  padding-right: ${({ theme }) => theme.measure.space['space-6']};
  padding-bottom: ${({ theme }) => theme.measure.space['space-8']};
  padding-left: ${({ theme }) => theme.measure.space['space-6']};
  gap: ${({ theme }) => theme.measure.space['space-8']};
`;

export const WrapperEmptyContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
