import { LoadingContent, ErrorContent } from '@bees-force-shared/components';
import { Paragraph } from '@hexa-ui/components';

import { BadgeStatus } from '@/components/Atoms/BadgeStatus/BadgeStatus';
import { DateFormats } from '@/consts/GlobalConsts';
import {
  formatDateToLocaleDate,
  formatStringDateToNewDate,
} from '@/utils/dateFormatter/dateFormatter';

import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { useVisitDetails } from '../../hooks/useVisitDetails';
import { VisitInformationProps } from '../../types/VisitDetails.types';
import { VisitInformationMinMaxTime } from '../VisitInformationMinMaxTime/VisitInformationMinMaxTime';
import * as Styled from './VisitInformationContent.styles';

export const VisitInformationContent = ({
  date,
  status,
  suggestedTime,
  minimumTime,
  maximumTime,
  isLoading,
  isError,
  onRefresh,
}: VisitInformationProps) => {
  const { isFeatureVisitEffectiveRouteEnabled, locale } = useVisitDetails({});
  const { visitDateLabel, visitStatusLabel, loadingContent, errorContent, errorRefreshContent } =
    useTranslation();

  if (isLoading) {
    return (
      <Styled.WrapperLoadingContent>
        <LoadingContent text={loadingContent} />
      </Styled.WrapperLoadingContent>
    );
  }

  if (isError) {
    return (
      <ErrorContent text={errorContent} refreshText={errorRefreshContent} onRefresh={onRefresh} />
    );
  }

  return (
    <Styled.WrapperInformationContent>
      <div>
        <Paragraph weight="semibold">{visitDateLabel}</Paragraph>
        <Paragraph>
          {formatDateToLocaleDate(formatStringDateToNewDate(date), locale, DateFormats.MEDIUM)}
        </Paragraph>
      </div>
      <div>
        <Paragraph weight="semibold">{visitStatusLabel}</Paragraph>
        <BadgeStatus status={status} />
      </div>
      <div />
      {isFeatureVisitEffectiveRouteEnabled && (
        <VisitInformationMinMaxTime
          sugTime={suggestedTime}
          minTime={minimumTime}
          maxTime={maximumTime}
        />
      )}
    </Styled.WrapperInformationContent>
  );
};
