import { useDecision } from '@optimizely/react-sdk';
import { useHasPermission } from 'admin-portal-shared-services';

import { FEATURE_TOGGLE_FORCE_ADMIN_VISITS } from '@/consts/FeatureToggles';

export enum Scopes {
  BeesForceVisitsManagementWrite = 'BeesForceAdmin.VisitManager.Write',
}

export const useNavigationControl = () => {
  const hasBeesForceVisitsManagementWritePermission = useHasPermission([
    Scopes.BeesForceVisitsManagementWrite,
  ]);

  const [forceAdminVisits, forceAdminVisitsReady] = useDecision(FEATURE_TOGGLE_FORCE_ADMIN_VISITS);

  const isForceAdminVisitsEnabled =
    forceAdminVisits.enabled &&
    forceAdminVisitsReady &&
    hasBeesForceVisitsManagementWritePermission;

  return {
    isForceAdminVisitsEnabled,
  };
};
