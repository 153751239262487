import { useIntl } from 'react-intl';

import { OVERVIEW_REFRESH_DATA_TEST_IDS } from '../../consts/OverviewConsts';
import { OverviewRefreshDataInterface } from '../../types/OverviewTypes';
import * as Styled from './OverviewRefreshData.styles';

export const OverviewRefreshData = ({
  lastTimeUpdate,
  hasError,
  errorMessage,
  handleClickRefresh,
  isRefetching,
}: OverviewRefreshDataInterface) => {
  const { formatMessage } = useIntl();

  return (
    <Styled.WrapperRefreshButton>
      <Styled.RefreshButton
        data-testid={OVERVIEW_REFRESH_DATA_TEST_IDS.REFRESH_BUTTON}
        hasUnderline={false}
        onClick={handleClickRefresh}
        disabled={isRefetching}>
        {hasError
          ? formatMessage({ id: 'Components.Overview.Button.TRY_AGAIN' })
          : formatMessage({ id: 'Components.Overview.Button.REFRESH' })}
      </Styled.RefreshButton>

      {hasError && (
        <Styled.RefreshErrorLabel
          data-testid={OVERVIEW_REFRESH_DATA_TEST_IDS.REFRESH_ERROR_LABEL}
          colortype="error"
          disabled={true}>
          {errorMessage}
        </Styled.RefreshErrorLabel>
      )}

      <Styled.RefreshLabel
        data-testid={OVERVIEW_REFRESH_DATA_TEST_IDS.REFRESH_LABEL}
        colortype="disabled">
        {lastTimeUpdate}
      </Styled.RefreshLabel>
    </Styled.WrapperRefreshButton>
  );
};
