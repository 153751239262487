import { Heading, Paragraph } from '@hexa-ui/components';

import { BDR_INFORMATION_TEST_IDS } from '../../consts/VisitDetailsCardConsts';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import { BdrInformationProps } from '../../types/VisitDetails.types';
import { EmptyCard } from '../EmptyCard/EmptyCard';
import * as Styled from './BdrInformation.styles';

export const BdrInformation = ({ bdrId }: BdrInformationProps) => {
  const { bdrInformationTitle, bdrIdLabel, noBdrInformationLabel } = useTranslation();

  if (!bdrId) {
    return <EmptyCard title={bdrInformationTitle} emptyMessage={noBdrInformationLabel} />;
  }

  return (
    <Styled.WrapperBdrInformationCard border="medium" elevated="minimal">
      <Heading data-testid={BDR_INFORMATION_TEST_IDS.HEADER} size="H3">
        {bdrInformationTitle}
      </Heading>
      <Styled.WrapperInformationContent>
        <Styled.WrapperInformation>
          <Paragraph weight="semibold">{bdrIdLabel}</Paragraph>
          <Paragraph>{bdrId}</Paragraph>
        </Styled.WrapperInformation>
      </Styled.WrapperInformationContent>
    </Styled.WrapperBdrInformationCard>
  );
};
