import { Table } from '@hexa-ui/components';

import { bdrsMock } from '../../consts/BDRs.mock';
import { useTableVisits } from '../../hooks/useTableVisits/useTableVisits';
import * as Styled from './TableVisits.styles';

export const TableVisits = () => {
  const { columns, handleClickNavigate, setSortDetails } = useTableVisits();

  return (
    <Styled.TableWrapper>
      <Table
        columns={columns}
        data={bdrsMock}
        pagination={{
          pageSize: 25,
        }}
        onRow={(rowData) => ({
          'data-testid': `visit-row-${rowData.id}`,
          onClick: () => handleClickNavigate(rowData),
        })}
        onSort={(column, direction) => {
          setSortDetails({ column, direction });
        }}
      />
    </Styled.TableWrapper>
  );
};
