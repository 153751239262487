export const FILTERS_VISITS_IDS = {
  scheduled: 'status-scheduled',
  completed: 'status-completed',
  canceled: 'status-canceled',
  justified: 'status-justified',
  inProgress: 'status-in-progress',
};

export const FILTERS_TEST_IDS = {
  FILTERS_BUTTON: 'filters-button',
  CLEAR_ALL_BUTTON: 'clear-all-button',
  APPLY_BUTTON: 'apply-button',
  CANCEL_BUTTON: 'cancel-button',
};

export const FILTERED_BY_TEST_IDS = {
  FILTERED_BY_CONTAINER: 'filtered-by-container',
  FILTERED_BY: 'filtered-by-',
};
