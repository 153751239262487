import { Heading, Paragraph } from '@hexa-ui/components';

import { EmptyCardProps } from '../../types/VisitDetails.types';
import * as Styled from './EmptyCard.styles';

export const EmptyCard: React.FC<EmptyCardProps> = ({ title, emptyMessage }) => {
  return (
    <Styled.WrapperEmpty border="medium" elevated="minimal">
      <Styled.WrapperHeader>
        <Heading size="H3">{title}</Heading>
      </Styled.WrapperHeader>
      <Styled.WrapperEmptyContent>
        <Paragraph colortype="secondary">{emptyMessage}</Paragraph>
      </Styled.WrapperEmptyContent>
    </Styled.WrapperEmpty>
  );
};
