import { useState, useRef, useCallback } from 'react';
import { Matcher } from 'react-day-picker';

import { CalendarRef } from '@hexa-ui/components';

import { UseSingleDatePickerProps } from '@/components/VisitDetailsCard/types/VisitDetails.types';

export const useSingleDatePicker = (
  onReset: (() => void) | undefined,
): UseSingleDatePickerProps => {
  const [disableActions, setDisableActions] = useState(true);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const calendarRef = useRef<CalendarRef>(null);

  const handleOnDateChange = useCallback((selection: Matcher | Matcher[] | undefined) => {
    if (selection) {
      setDisableActions(false);
    } else {
      setDisableActions(true);
    }
    setSelectedDate(selection as unknown as Date);
  }, []);

  const handleOnReset = useCallback(() => {
    calendarRef.current?.clearSelection();
    setDisableActions(true);
    onReset && onReset();
  }, [onReset]);

  return {
    disableActions,
    selectedDate,
    calendarRef,
    handleOnDateChange,
    handleOnReset,
  };
};
