import { Navigate } from 'react-router-dom';

import { useNavigationControl } from './hooks/useNavigationControl/useNavigationControl';
import { VisitDetails } from './pages/VisitDetails/VisitDetails';
import { Visits } from './pages/Visits/Visits';

const ProtectedRoute = ({ element }) => {
  const { isForceAdminVisitsEnabled } = useNavigationControl();

  if (!isForceAdminVisitsEnabled && process.env.NODE_ENV === 'production') {
    return <Navigate to="/force" replace />;
  }

  return element;
};

export const routes = [
  {
    element: <ProtectedRoute element={<Visits />} />,
    path: '/force/visits',
  },
  {
    element: <ProtectedRoute element={<VisitDetails />} />,
    path: '/force/visits/:id',
  },
];
