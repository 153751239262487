import analytics from '@/config/typewriter';
import { useAnalytics } from '@/hooks/useAnalytics/useAnalytics';
import { CommonSegmentProp, ComponentsName, DdcSelected } from '@/types/AnalyticsTypes';

export const useSegmentDdcSelect = () => {
  const { defaultProps } = useAnalytics();

  const dispatchSelectedDdcEvent = (ddc: string, ddcUpdate: string) => {
    try {
      analytics.ddcSelected({
        ...defaultProps,
        [CommonSegmentProp.ddc]: ddc,
        [DdcSelected.DdcUpdate]: ddcUpdate,
        [DdcSelected.ComponentName]: ComponentsName.modalChangeDDC,
      });
    } catch (error) {
      const failureReason = error instanceof Error ? error.message : 'Unknown error';
      analytics.ddcSelected({
        ...defaultProps,
        [CommonSegmentProp.ddc]: ddc,
        [DdcSelected.DdcUpdate]: ddcUpdate,
        [DdcSelected.ComponentName]: ComponentsName.modalChangeDDC,
        [DdcSelected.FailureReason]: failureReason,
      });
    }
  };

  return { dispatchSelectedDdcEvent };
};
