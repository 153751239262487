import { Button, Paragraph, TextButton } from '@hexa-ui/components';
import styled from 'styled-components';

export const paragraph = styled(Paragraph)``;

export const CancelButton = styled(Button)`
  margin-right: ${({ theme }) => theme.measure.space['space-2']};
`;

export const CancelButtonModal = styled(TextButton)`
  background-color: ${({ theme }) =>
    theme.color.flavor.rule.bg.button['bg-button-tertiary-default']};
  border-radius: 20px;
  padding: 0 16px;

  &:hover {
    background-color: ${({ theme }) =>
      theme.color.flavor.rule.bg.button['bg-button-tertiary-hover']};
  }
`;
