import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { KpiStatus } from '@/components/Overview/enums/visits';
import { HorizontalStackedBarStatus } from '@/components/Overview/types/OverviewTypes';

export const useHorizontalStackedBar = () => {
  const { formatMessage } = useIntl();
  const [bars, setBars] = useState<HorizontalStackedBarStatus[]>([]);
  const [first, setFirst] = useState<number>();
  const [last, setLast] = useState<number>();

  const [status, setStatus] = useState<HorizontalStackedBarStatus[]>([]);
  const statusTotal = status.reduce((acc, item) => acc + item.count, 0);

  useEffect(() => {
    if (status.length === 0) return;

    const updatedStatus = status.map((item) => ({
      ...item,
      size: (item.count / statusTotal) * 100 >= 1 ? (item.count / statusTotal) * 100 : 0,
    }));

    setFirst(updatedStatus.findIndex((item) => item.size > 0));
    setLast(
      updatedStatus.length - 1 - [...updatedStatus].reverse().findIndex((item) => item.size > 0),
    );
    setBars(updatedStatus);
  }, [status, statusTotal]);

  const handleClick = () => {
    setStatus([
      {
        name: KpiStatus.SCHEDULED,
        color: '#009900',
        count: Math.floor(Math.random() * 100),
      },
      {
        name: KpiStatus.IN_PROGRESS,
        color: '#006EDB',
        count: Math.floor(Math.random() * 100),
      },
      {
        name: KpiStatus.COMPLETED,
        color: '#585858',
        count: Math.floor(Math.random() * 100),
      },
      {
        name: KpiStatus.JUSTIFIED,
        color: '#D47300',
        count: Math.floor(Math.random() * 100),
      },
      {
        name: KpiStatus.CANCELED,
        color: '#E82727',
        count: 0,
      },
    ]);
  };
  // fill mock data
  useEffect(() => {
    handleClick();
  }, []);

  return {
    bars,
    status,
    handleClick,
    last,
    first,
    formatMessage,
  };
};
