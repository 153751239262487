import { updateVisit } from '@/services/visits/UpdateVisit';
import {
  UpdateVisitRequestBody,
  UpdateVisitRequestQuery,
  UpdateVisitResponse,
} from '@/types/domains/UpdateVisitDomain';

export const updateVisitUseCase = async (
  params: UpdateVisitRequestQuery,
  body: UpdateVisitRequestBody,
): Promise<UpdateVisitResponse> => {
  const response = await updateVisit(params, body);

  return response;
};
