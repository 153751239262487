export const VISIT_DETAILS_CARD_TEST_IDS = {
  GO_BACK_ICON_BUTTON: 'go-back-icon-button',
  HEADER: 'visit-details-header',
  ALERT_ERROR: 'alert-error',
};

export const POC_INFORMATION_TEST_IDS = {
  HEADER: 'poc-information-header',
};

export const VISIT_INFORMATION_TEST_IDS = {
  HEADER: 'visit-information-header',
};

export const NEXT_VISIT_TEST_IDS = {
  HEADER: 'next-visit-header',
};

export const BDR_INFORMATION_TEST_IDS = {
  HEADER: 'bdr-information-header',
};

export const CANCEL_VISIT_MODAL_TEST_IDS = {
  CANCEL_BUTTON_MODAL: 'cancel-button-modal',
  HEADER: 'cancel-visit-modal-header',
  GO_BACK_BUTTON: 'go-back-button',
  CANCEL_VISIT_BUTTON: 'cancel-visit-button',
};
