import { Button } from '@hexa-ui/components';
import { RotateCw } from '@hexa-ui/icons';
import styled from 'styled-components';

export const ErrorContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: 100%;
  gap: ${({ theme }) => theme.measure.space['space-4']};
  flex-direction: column;
`;
export const ErrorContentLabel = styled.label`
  font-family: Work Sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
`;
export const ErrorContentButton = styled(Button)`
  display: flex;
  gap: 8px;
  padding: 1rem 1rem 1rem 0.5rem;

  > div {
    background: transparent;
    margin-right: 0;
  }
`;

export const ErrorButtonIcon = styled(RotateCw)`
  background-color: var(--colors-brandPrimaryBasis);
  border-radius: ${({ theme }) => theme.measure.space['space-4']};
`;
