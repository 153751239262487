import { useIntl } from 'react-intl';

import { EditLabelButton } from '@bees-force-shared/business-components';

import { IVisitOperationDDCFilterProps } from '@/types/components/VisitOperationDDCFilters/VisitOperationDDCFilters';

import { useModalChangeDDC } from '../ModalChangeDDC/hooks/useModalChangeDDC';
import { ModalChangeDDC } from '../ModalChangeDDC/ModalChangeDDC';
import { SELECT_CHANGE_DDC_IDS } from './consts/VisitOperationDDCFilter';
import { useDdc } from './hooks/useDdc';
import * as Styled from './VisitOperationDDCFilters.styles';

export const VisitOperationDDCFilter = ({ disabled }: IVisitOperationDDCFilterProps) => {
  const { isOpen, openModal, closeModal } = useModalChangeDDC();
  const { formatMessage } = useIntl();
  const { ddcNameFormatted } = useDdc();

  return (
    <div>
      <ModalChangeDDC isOpen={isOpen} onClose={closeModal} />
      <Styled.Container data-testid={SELECT_CHANGE_DDC_IDS.SELECT_CHANGE_DDC}>
        <EditLabelButton
          label={formatMessage({ id: 'Components.ModalChangeDDC.Button.Label.DDC' })}
          title={ddcNameFormatted}
          widthProp="12.75rem"
          onClick={openModal}
          disabled={disabled}
        />
      </Styled.Container>
    </div>
  );
};
