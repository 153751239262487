import { Button, Calendar } from '@hexa-ui/components';

import { FILTERS_TEST_IDS } from '@/components/Overview/consts/OverviewConsts';
import { SingleDatePickerProps } from '@/components/VisitDetailsCard/types/VisitDetails.types';

import { useSingleDatePicker } from '../../hooks/useSingleDatePicker/useSingleDatePicker';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import * as Styled from './SingleDatePicker.styles';

export const SingleDatePicker = ({
  language,
  onSave,
  datePickerRef,
  onReset,
}: SingleDatePickerProps): JSX.Element => {
  const { selectedDate, calendarRef, handleOnDateChange, handleOnReset, disableActions } =
    useSingleDatePicker(onReset);
  const { reset, save } = useTranslation();
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  return (
    <Styled.CalendarModal ref={datePickerRef} data-testid={FILTERS_TEST_IDS.FILTER_MODAL}>
      <Calendar
        legend={{
          orientation: 'vertical',
        }}
        mode="single"
        customLocale={language}
        onChange={handleOnDateChange}
        hightlightedDates={[]}
        data-testid={FILTERS_TEST_IDS.FILTER_MODAL_CALENDAR}
        ref={calendarRef}
        fromDate={tomorrow}
      />
      <Styled.WrapperButtonActions>
        {disableActions ? (
          <Button
            data-testid={FILTERS_TEST_IDS.FILTER_BUTTON_RESET_CALENDAR}
            disabled={disableActions}>
            {reset}
          </Button>
        ) : (
          <Styled.ResetButton
            hasUnderline={false}
            onClick={handleOnReset}
            data-testid={FILTERS_TEST_IDS.FILTER_BUTTON_RESET_CALENDAR}>
            {reset}
          </Styled.ResetButton>
        )}
        <Button
          onClick={() => onSave && onSave(selectedDate as Date)}
          data-testid={FILTERS_TEST_IDS.FILTER_BUTTON_SAVE_CALENDAR}
          disabled={disableActions}>
          {save}
        </Button>
      </Styled.WrapperButtonActions>
    </Styled.CalendarModal>
  );
};
