import { Paragraph } from '@hexa-ui/components';
import styled from 'styled-components';

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const FilterDescription = styled(Paragraph)``;
export const FilterWrapper = styled.div`
  display: flex;
  gap: 0;
  align-items: center;
`;
export const DateLabel = styled(Paragraph)`
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #006edb;
`;
