import MessageMap from '../i18n.d';

export const ptBR: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Apenas opção em inglês disponível',
  },
  Pages: {
    Visits: {
      Title: {
        PAGE_VISITS: 'Visitas',
      },
      Status: {
        Error: {
          ERROR_MESSAGE: 'Não foi possível carregar os dados',
          SUBTITLE: 'Tente recarregar a página.',
          ON_REFRESH: 'Atualizar',
        },
        LOADING: 'Carregando dados',
      },
    },
  },
  Components: {
    Overview: {
      Title: {
        OVERVIEW: 'Visão Geral',
        CANCELLED_VISITS: '% de visitas canceladas',
        BDRS_WITHOUT_VISITS: '% de RNs sem visitas',
        TOTAL_OF_VISITS: 'Total de visitas',
      },
      Label: {
        CANCELLED_VISITS_TOTAL: ' de [total] Visitas',
        BDRS_WITHOUT_VISITS_TOTAL: 'de [total] RNs',
        TOTAL_OF_VISITS_COUNT: 'Total',
        LAST_UPDATE: 'Última atualização [lastUpdate] atrás.',
        FILTER_DESCRIPTION: 'Selecione um intervalo de datas para exibir os dados:',
      },
      Button: {
        REFRESH: 'Atualizar',
        TRY_AGAIN: 'Tentar novamente',
      },
      Error: {
        REFRESH_ERROR: 'Não foi possível recarregar os dados',
        DEFAULT_ERROR: 'Algo deu errado.',
        RELOAD_BUTTON: 'Recarregar',
      },
      Filter: {
        Periods: {
          TODAY: 'Hoje',
          YESTERDAY: 'Ontem',
          TOMORROW: 'Amanhã',
          OTHER: 'Outro',
        },
      },
      Graph: {
        TITLE: 'Por status',
        SCHEDULED: 'Planejadas',
        IN_PROGRESS: 'Em progresso',
        COMPLETED: 'Concluídas',
        JUSTIFIED: 'Justificadas',
        CANCELED: 'Canceladas',
      },
      SearchField: {
        PLACEHOLDER: 'Pesquisar',
      },
      LOADING: 'Carregando conteúdo',
    },
    VisitTableCard: {
      SearchBar: {
        ACCOUNT_ID: 'Código do PDV',
        BDR_ID: 'ID do RN',
        BDR_NAME: 'Nome do RN',
        SEARCH_BY: 'Pesquisar por',
      },
      Buttons: {
        SEARCH_BUTTON: 'Buscar',
        FILTERS_BUTTON: 'Filtrar',
        APPLY_BUTTON: 'Aplicar',
        CANCEL_BUTTON: 'Cancelar',
        CLEAR_ALL_BUTTON: 'Limpar tudo',
        RELOAD_BUTTON: 'Recarregar',
      },
      Title: {
        CARD_VISITS: 'Visitas',
      },
      Error: {
        ACCOUNT_ID_ERROR: 'Código do PDV [id] não existe.',
        BDR_ID_ERROR: 'RN [id] não existe.',
        BDR_NAME_ERROR: 'RN [name] não existe.',
      },
      StatusVisits: {
        STATUS: 'Status',
        SCHEDULED: 'Planejada',
        IN_PROGRESS: 'Em progresso',
        COMPLETED: 'Concluída',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
      },
      TableVisits: {
        SHOWING_RESULTS: 'Mostrando [results] visitas',
        BDR_ID: 'ID do RN',
        BDR_NAME: 'Nome do RN',
        POC_ID: 'Código do PDV',
        VISIT_DATE: 'Data da visita',
        MIN_TIME: 'Tempo Min.',
        MAX_TIME: 'Tempo Máx.',
        MINUTES: 'Minutos',
        QUANTITY_INDICATION: 'de',
        LOADING_CONTENT: 'Carregando dados',
        ERROR_CONTENT: 'Algo deu errado.',
        NO_CONTENT: 'Não há conteúdo para ser exibido.',
        SEARCH_NOT_FOUND: 'Nenhum resultado. Revise sua busca e tente novamente.',
        CLEAR_SEARCH: 'Limpar busca',
      },
      Filter: {
        FILTERED_BY: 'Filtrado por',
      },
    },
    ModalBDRsWithoutVisits: {
      Title: {
        BDRS_WITHOUT_VISITS: 'RNs sem visitas',
      },
      Label: {
        SHOWING_RESULTS: 'Mostrando [results] resultados',
        BDR_ID: 'ID do RN',
        BDR_NAME: 'Nome do RN',
        DATE_WITHOUT_VISITS: 'Data sem visita',
        SEARCH_BY: 'Pesquisar por ID do RN',
        PAGINATION_INDICATION: 'de',
        RANGE_INDICATION: 'a',
      },
    },
    ModalChangeDDC: {
      Title: {
        CHANGE_DDC: 'Alterar CDD',
      },
      Paragraph: {
        FIND_ANOTHER_DDC: 'Encontre outro CDD para gerenciar por CDD ID ou CDD Name.',
      },
      Button: {
        CANCEL: 'Cancelar',
        CHANGE: 'Alterar',
        Label: {
          DDC: 'CDD',
        },
      },
      Select: {
        Search: {
          PLACEHOLDER: 'Pesquisar',
        },
        LABEL: 'CDD',
        NOT_FOUND: 'Não encontramos o que você está procurando.',
        SHOW_ALL: 'Mostrar tudo',
      },
    },
    ModalChangeOperation: {
      Button: {
        Label: {
          OPERATION: 'Operação',
          TOOLTIP_INFORMATION: 'Operação inclui organização e o vendor.',
        },
      },
      TITLE: 'Operação de edição',
      SUB_TITLE: 'A operação será aplicada à página de visitas.',
      Actions: {
        CANCEL_BUTTON: 'Cancelar',
        SAVE_BUTTON: 'Salvar',
      },
      Fields: {
        Country: {
          LABEL: 'País',
          PLACEHOLDER: 'Selecione um País',
        },
        Organization: {
          LABEL: 'Organização',
          HINT: 'Pode incluir empresas.',
          PLACEHOLDER: 'Selecione uma organização',
          ALERT: 'Este país não tem organizações',
        },
        Seller: {
          LABEL: 'Vendedor',
          HINT: 'Quem receberá a visita.',
          PLACEHOLDER: 'Selecione um vendedor',
        },
        VendorId: {
          LABEL: 'Vendor ID',
          PLACEHOLDER: 'Selecione um vendor ID',
        },
      },
    },
    VisitDetails: {
      Title: {
        VISIT_DETAILS: 'Detalhes da visita',
        POC_INFORMATION: 'Informações do PDV',
        NEXT_VISIT: 'Próxima visita',
        BDR_INFORMATION: 'Informações do RN',
        VISIT_INFORMATION: 'Informações da visita',
        CANCEL_VISIT: 'Cancelar visita?',
      },
      Label: {
        VISIT_DATE: 'Data da visita',
        VISIT_STATUS: 'Status da visita',
        SUGGESTED_TIME: 'Tempo sugerido',
        MINIMUM_TIME: 'Tempo mínimo',
        MAXIMUM_TIME: 'Tempo máximo',
        NO_NEXT_VISIT: 'Não há visita agendada para este PDV.',

        POC_NAME: 'Nome do PDV',
        POC_ID: 'Código do PDV',
        POC_LATITUDE: 'Latitude',
        POC_LONGITUDE: 'Longitude',
        NO_POC_INFORMATION: 'Não há informações de POC para esta visita.',

        BDR_NAME: 'Nome do RN',
        BDR_ID: 'Código do RN',
        NO_BDR_INFORMATION: 'Não há informações do RN para este POC.',

        SHOW_VISIT_DETAILS: 'Mostrar detalhes da visita',
        MINUTES: '[min] minutos',
        NO_DATA_AVAILABLE: 'Nenhum dado disponível',
        CANCELED_DUE_TO: 'Esta visita foi cancelada pelo sistema devido a [error].',

        CANCEL_VISIT_DESCRIPTION:
          'Esta ação pode ser desfeita enquanto a data permanecer no futuro.',

        LOADING_CONTENT: 'Carregando dados',
        ERROR_CONTENT: 'Algo deu errado.',

        CANCELED_BY_USER_ADMIN: 'Esta visita foi cancelada por um usuário do BEES One.',
        CANCELED_BY_USER_SYNC: 'Esta visita foi cancelada por um usuário em Sync.',
        CANCELED_BY_SYSTEM: 'Esta visita foi cancelada pelo sistema devido a [error].',
        CanceledBySystem: {
          INVALID_LAT_LONG: 'latitude ou longitude inválida',
          NOT_IN_CUSTOMER: 'conta indisponível para o Fornecedor',
          NOT_IN_ACCOUNTS_BFF: 'conta indisponível para o Force',
          INVALID_NAME: 'nome inválido',
          NOT_IN_UCC: 'dados inválidos no UCC',
          INVALID_DELIVERY_ADDRESS: 'endereço de entrega inválido',
          BEES_ACCOUNT_ID_NULL: 'ID da conta nulo',
        },
      },
      Button: {
        CANCEL_MODAL: 'Cancelar',
        GO_BACK: 'Não, voltar',
        CANCEL_VISIT: 'Sim, cancelar',
        REFRESH: 'Recarregar',
      },
      Toast: {
        SUCCESS: 'Visita cancelada com sucesso.',
      },
      PageStatus: {
        Error: {
          ERROR_MESSAGE: 'Não foi possível carregar os dados',
          SUBTITLE: 'Tente recarregar a página.',
          ON_REFRESH: 'Recarregar',
          ON_GO_TO: 'Voltar para Visitas',
        },
        LOADING: 'Carregando dados',
      },
    },
    FilterModalCalendar: {
      Button: {
        SAVE: 'Salvar',
        RESET: 'Reiniciar',
      },
    },
    BadgeStatus: {
      Label: {
        STATUS: 'Status',
        SCHEDULED: 'Planejada',
        IN_PROGRESS: 'Em progresso',
        COMPLETED: 'Concluída',
        JUSTIFIED: 'Justificada',
        CANCELED: 'Cancelada',
      },
    },
    EditModal: {
      Label: {
        EDIT_VISIT: 'Editar visita',
        VISIT_DATE: 'Data da visita',
        VISIT_STATUS: 'Status da visita',
        NEXT_VISIT: 'Próxima visita',
        SCHEDULE: 'Planejadas',
        CANCELED: 'Cancelado',
        NO_DATA_AVAILABLE: 'Nenhum dado disponível',
        SAVED_CHANGE: 'Alterações salvas com sucesso.',
      },
      Button: {
        CANCEL: 'Cancelar',
        SAVE_CHANGES: 'Salvar alterações',
        EDIT: 'Editar',
        RESET: 'Reiniciar',
        SAVE: 'Salvar',
      },
      Input: {
        Label: {
          SUGGESTED_TIME: 'Hora sugerida',
          MINIMUM_TIME: 'Tempo mínimo',
          MAXIMUM_TIME: 'Tempo máximo',
          PLACEHOLDER: 'Insira o horário',
        },
      },
      Errors: {
        MAX_TIME_LESS_THAN_MIN_TIME:
          'O valor do tempo máximo não pode ser menor ou igual do que o tempo mínimo.',
        MIN_TIME_LESS_THAN_2: 'O valor do tempo mínimo não pode ser menor que 2 minutos.',
        SAME_DATE:
          'Esta data não está disponível, já existe uma visita na mesma data. Escolha outro para salvar ou cancelar',
      },
      Alert: {
        WARNING_MESSAGE:
          'Se você salvar essas alterações, esta visita pode mudar para o status Planejado se todos os parâmetros forem válidos.',
      },
    },
  },
};
