import { AxiosResponse } from 'axios';

import { ENDPOINT_GET_VISITS } from '../../../consts/Endpoints';
import { api } from '../../api';
import { GetVisits } from './types';

export const getVisits = async ({
  headers,
  signal,
  vendorId,
  deliveryCenterId,
  bdrId,
  accountId,
  status,
  page,
  startDate,
  endDate,
  sort,
  pageSize,
}: GetVisits): Promise<AxiosResponse> => {
  return await api.get({
    url: ENDPOINT_GET_VISITS,
    config: {
      headers,
      signal,
      params: {
        vendorId,
        deliveryCenterId,
        bdrId,
        accountId,
        status,
        page,
        pageSize,
        startDate,
        endDate,
        sort,
      },
    },
  });
};
