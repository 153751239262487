import { TextLink } from '@hexa-ui/components';
import styled from 'styled-components';

export const FilterModal = styled.div`
  gap: 6px;
  padding: ${({ theme }) => theme.measure.space['space-6']};
  flex-basis: 100%;
  position: absolute;
  background-color: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 0rem 0.125rem 0.5rem 0rem rgba(0, 0, 0, 0.08);
  z-index: 9;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    left: 15vw !important;
  }

  @media (max-width: 768px) {
    left: 5vw !important;
  }
`;

export const WrapperButtonFilter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.measure.space['space-3']};
`;

export const ResetButton = styled(TextLink)`
  align-content: center;
  padding: 0 ${({ theme }) => theme.measure.space['space-3']};
`;
