import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Heading, IconButton } from '@hexa-ui/components';
import { ArrowLeft } from '@hexa-ui/icons';

import { BdrInformation } from './components/BdrInformation/BdrInformation';
import { CancelVisitModal } from './components/CancelVisitModal/CancelVisitModal';
import { EditVisitModal } from './components/EditVisitModal/EditVisitModal';
import { NextVisit } from './components/NextVisit/NextVisit';
import { PocInformation } from './components/PocInformation/PocInformation';
import { VisitInformation } from './components/VisitInformation/VisitInformation';
import { VISIT_DETAILS_CARD_TEST_IDS } from './consts/VisitDetailsCardConsts';
import { useTranslation } from './hooks/useTranslation/useTranslation';
import { useVisitDetails } from './hooks/useVisitDetails';
import { StatusVisit } from './types/VisitDetails.types';
import * as Styled from './VisitDetailsCard.styles';

export const VisitDetailsCard = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { visitInfo, handleClickGoBackToVisitListButton } = useVisitDetails();
  const { canceledDueToLabel } = useTranslation();

  const {
    date,
    status,
    suggestedTime,
    minimumTime,
    maximumTime,
    poc = {},
    nextVisit = {},
    bdr = {},
    canceledDueTo,
  } = visitInfo;
  const { name: pocName, id: pocId, latitude: pocLatitude, longitude: pocLongitude } = poc;
  const { id: bdrId } = bdr;
  const { date: nextVisitDate, status: nextVisitStatus } = nextVisit;
  const isScheduled = status === 'scheduled';
  const isVisitDatePassed = new Date(date) <= new Date();
  const isCanceledActionAvailable = isScheduled && !isVisitDatePassed;

  const lowerCaseStatus = status.toLowerCase();
  return (
    <Styled.VisitDetailsContent>
      <Styled.VisitDetailsHeader>
        <Styled.VisitDetailsCardHeader>
          <IconButton
            icon={ArrowLeft}
            variant="tertiary"
            data-testid={VISIT_DETAILS_CARD_TEST_IDS.GO_BACK_ICON_BUTTON}
            size="medium"
            onClick={handleClickGoBackToVisitListButton}
          />
          <Heading data-testid={VISIT_DETAILS_CARD_TEST_IDS.HEADER} size="H2">
            {formatMessage({ id: 'Components.VisitDetails.Title.VISIT_DETAILS' })}
          </Heading>
        </Styled.VisitDetailsCardHeader>
        <Styled.WrapperVisitActionCard>
          {isCanceledActionAvailable && <CancelVisitModal visitId={id ?? null} key={id} />}
          {(lowerCaseStatus === StatusVisit.SCHEDULED ||
            lowerCaseStatus === StatusVisit.CANCELED) && (
            <EditVisitModal
              date={new Date(date)}
              status={status}
              nextVisitDate={nextVisitDate ? new Date(nextVisitDate) : undefined}
            />
          )}
        </Styled.WrapperVisitActionCard>
      </Styled.VisitDetailsHeader>

      {canceledDueTo && (
        <Styled.AlertError
          type="error"
          message={canceledDueToLabel}
          data-testid={VISIT_DETAILS_CARD_TEST_IDS.ALERT_ERROR}
        />
      )}

      <Styled.WrapperVisitDetailsCard>
        <VisitInformation
          date={date}
          status={status}
          suggestedTime={suggestedTime}
          minimumTime={minimumTime}
          maximumTime={maximumTime}
        />
        <PocInformation
          pocName={pocName}
          pocId={pocId}
          pocLatitude={pocLatitude}
          pocLongitude={pocLongitude}
        />
        <NextVisit nextVisitDate={nextVisitDate} nextVisitStatus={nextVisitStatus} />
        <BdrInformation bdrId={bdrId} />
      </Styled.WrapperVisitDetailsCard>
    </Styled.VisitDetailsContent>
  );
};
