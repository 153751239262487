import { createStore } from 'effector';

import { setSelectedDdc } from './UserInfoEvents';
import { LOCAL_STORAGE_KEY, userInfoInitialState, UserInfoState } from './UserInfoState';

const getInitialState = (): UserInfoState => {
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (savedState) {
    return JSON.parse(savedState);
  }
  return userInfoInitialState;
};

export const UserInfoStore = createStore(getInitialState()).on(
  setSelectedDdc,
  (state: UserInfoState, updatedState: Partial<UserInfoState>) => {
    const newState = {
      ...state,
      ...updatedState,
    };

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState));

    return newState;
  },
);

const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
if (savedState) {
  const parsedState = JSON.parse(savedState);
  setSelectedDdc(parsedState);
}
