import React from 'react';
import { useIntl } from 'react-intl';

import { Calendar, Check, ChevronDown, ChevronUp } from '@hexa-ui/icons';

import { FILTERS_TEST_IDS } from '@/components/Overview/consts/OverviewConsts';
import { Periods } from '@/components/Overview/enums/visits';
import { FilterButtonProps } from '@/components/Overview/types/OverviewTypes';

import * as Styled from './FilterButton.styles';

export const FilterButton = ({
  period,
  name,
  checked,
  onChange,
  onClick,
  openModal,
  inputRef,
}: FilterButtonProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const periodLabel = formatMessage({
    id: `Components.Overview.Filter.Periods.${period.toUpperCase()}`,
  });
  const isOtherPeriod = period === Periods.OTHER;

  return (
    <Styled.FilterByPeriodContainer data-testid={FILTERS_TEST_IDS.FILTERS_BY_PERIOD_CONTAINER}>
      <Styled.Period>
        <Styled.Input
          ref={inputRef}
          data-testid={FILTERS_TEST_IDS.FILTER_INPUT}
          type="radio"
          id={period}
          name={name}
          value={period}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
        />
        <Styled.Label
          htmlFor={period}
          data-testid={FILTERS_TEST_IDS.FILTER_BUTTON_LABEL}
          id={`${period}-label-id`}>
          {checked && !isOtherPeriod && (
            <Styled.Icon data-testid={FILTERS_TEST_IDS.FILTER_BUTTON_ICON_CHECK} checked={checked}>
              <Check />
            </Styled.Icon>
          )}
          {isOtherPeriod && (
            <Styled.Icon
              data-testid={FILTERS_TEST_IDS.FILTER_BUTTON_ICON_CALENDAR}
              checked={checked}>
              <Calendar />
            </Styled.Icon>
          )}
          {periodLabel}
          {isOtherPeriod && (
            <Styled.IconRight data-testid={FILTERS_TEST_IDS.FILTER_BUTTON_ICON_CHEVRON_DOWN}>
              {openModal ? <ChevronUp /> : <ChevronDown />}
            </Styled.IconRight>
          )}
        </Styled.Label>
      </Styled.Period>
    </Styled.FilterByPeriodContainer>
  );
};
