import { useCallback, useRef, useState } from 'react';

import { Periods } from '@/components/Overview/enums/visits';
import { CustomLanguageType } from '@/components/Overview/types/FilterModalCalendar';
import { DateFormats } from '@/consts/GlobalConsts';
import { Locales } from '@/enums/locales';
import { DateFilterPropsState } from '@/types/AnalyticsTypes';
import { formatDateToLocaleDate } from '@/utils/dateFormatter/dateFormatter';

import { useSegmentDateFilter } from './useSegmentDateFilter/useSegmentDateFilter';

export const useFilters = (locale: Locales) => {
  const [selectedPeriod, setSelectedPeriod] = useState<string>(Periods.TODAY);
  const [localeSelectedDate, setLocaleSelectedDate] = useState<Date>(new Date());
  const [complementDate, setComplementDate] = useState<Date>(new Date());
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [showClearData, setShowClearData] = useState<boolean>(false);
  const [dateFilter, setDateFilter] = useState<DateFilterPropsState | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useSegmentDateFilter(dateFilter, () => setDateFilter(null));
  const handleOnClick = useCallback(
    (period: Periods) => {
      if (period === Periods.OTHER) {
        setOpenCalendar(!openCalendar);
      }
    },
    [openCalendar],
  );

  const handleOnSaveDate = useCallback((event) => {
    const formattedLocaleSelectedDate = formatDateToLocaleDate(
      event.from,
      Locales.enUS,
      DateFormats.SHORT_REVERSE,
    );
    const formattedComplementDate = formatDateToLocaleDate(
      event.to,
      Locales.enUS,
      DateFormats.SHORT_REVERSE,
    );
    const dateRange = `${formattedLocaleSelectedDate} - ${formattedComplementDate}`;

    setLocaleSelectedDate(event.from);
    setComplementDate(event.to);
    setOpenCalendar(false);
    setShowClearData(true);

    setDateFilter({
      date: dateRange,
      selectedDateFilter: Periods.OTHER,
    });
  }, []);

  const resetAllStatus = () => {
    setDateFilter(null);
    setShowClearData(false);
    setComplementDate(new Date());
    setLocaleSelectedDate(new Date());
    setSelectedPeriod(Periods.TODAY);
    setOpenCalendar(false);
  };

  const handleOnClickOutside = useCallback(() => {
    handleOnClick(Periods.OTHER);
    if (localeSelectedDate.getTime() === complementDate.getTime()) {
      setSelectedPeriod(Periods.TODAY);
    }
  }, [complementDate, handleOnClick, localeSelectedDate]);

  const handleRadioChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDateFilter = e.target.value as Periods;
    const newDate = new Date();
    switch (selectedDateFilter) {
      case Periods.YESTERDAY:
        newDate.setDate(newDate.getDate() - 1);
        break;
      case Periods.TOMORROW:
        newDate.setDate(newDate.getDate() + 1);
        break;
      case Periods.OTHER:
        setOpenCalendar(true);
    }

    if (selectedDateFilter !== Periods.OTHER) {
      setShowClearData(false);
      setDateFilter({
        date: formatDateToLocaleDate(newDate, Locales.enUS, DateFormats.SHORT_REVERSE),
        selectedDateFilter: selectedDateFilter,
      });
    }
    setShowClearData(false);
    setSelectedPeriod(selectedDateFilter);
    setLocaleSelectedDate(newDate);
    setComplementDate(newDate);
  }, []);

  const calendarLanguage = (): CustomLanguageType | undefined => {
    const lang = locale && locale.split('-')[0];
    return lang as unknown as CustomLanguageType;
  };

  const formatDateLabel = (): string => {
    const formattedDateFrom = formatDateToLocaleDate(
      localeSelectedDate,
      locale,
      DateFormats.MEDIUM,
    );
    const formattedDateTo = formatDateToLocaleDate(complementDate, locale, DateFormats.MEDIUM);

    if (formattedDateFrom === formattedDateTo) {
      return `${formattedDateFrom}`;
    }
    return `${formattedDateFrom} - ${formattedDateTo}`;
  };

  const startDate = formatDateToLocaleDate(
    localeSelectedDate,
    'en-US' as Locales,
    DateFormats.SHORT_REVERSE,
  );
  const endDate = formatDateToLocaleDate(
    complementDate,
    'en-US' as Locales,
    DateFormats.SHORT_REVERSE,
  );

  return {
    handleOnClick,
    handleOnSaveDate,
    handleRadioChange,
    resetAllStatus,
    showClearData,
    openCalendar,
    selectedPeriod,
    inputRef,
    calendarLanguage,
    formatDateLabel,
    handleOnClickOutside,
    startDate,
    endDate,
  };
};
