import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { KpiStatus } from '@/components/Overview/enums/visits';
import { HorizontalStackedBarStatus } from '@/components/Overview/types/OverviewTypes';

interface props {
  visitsByStatus?: {
    status: string;
    total: number;
  }[];
}

export const useHorizontalStackedBar = ({ visitsByStatus }: props) => {
  const { formatMessage } = useIntl();
  const [bars, setBars] = useState<HorizontalStackedBarStatus[]>([]);

  const [status, setStatus] = useState<HorizontalStackedBarStatus[]>([]);
  const statusTotal = status.reduce((acc, item) => acc + item.count, 0);

  useEffect(() => {
    if (status.length === 0) return;

    const updatedStatus = status.map((item) => {
      let proportionalSize = 0;

      if (item.count && item.count > 0) {
        proportionalSize = (item.count / statusTotal) * 100;
        proportionalSize = Math.max(proportionalSize, 0.5);
      }

      return {
        ...item,
        size: proportionalSize,
      };
    });

    setBars(updatedStatus);
  }, [status, statusTotal]);

  const handleDataVisitsByStatus = useCallback(() => {
    const initialCounts = {
      SCHEDULE: 0,
      COMPLETED: 0,
      JUSTIFIED: 0,
      IN_PROGRESS: 0,
      CANCELED: 0,
    };

    const counts =
      visitsByStatus?.reduce((acc, visit) => {
        if (acc[visit.status] !== undefined) {
          acc[visit.status] += visit.total;
        }
        return acc;
      }, initialCounts) || initialCounts;

    setStatus([
      {
        name: KpiStatus.SCHEDULED,
        color: '#009900',
        count: counts.SCHEDULE,
      },
      {
        name: KpiStatus.IN_PROGRESS,
        color: '#006EDB',
        count: counts.IN_PROGRESS,
      },
      {
        name: KpiStatus.COMPLETED,
        color: '#585858',
        count: counts.COMPLETED,
      },
      {
        name: KpiStatus.JUSTIFIED,
        color: '#D47300',
        count: counts.JUSTIFIED,
      },
      {
        name: KpiStatus.CANCELED,
        color: '#E82727',
        count: counts.CANCELED,
      },
    ]);
  }, [visitsByStatus]);

  useEffect(() => {
    handleDataVisitsByStatus();
  }, [handleDataVisitsByStatus]);

  return {
    bars,
    status,
    handleDataVisitsByStatus,

    formatMessage,
  };
};
