import { VISIT_EDIT_IDS } from '@/components/VisitDetailsCard/consts/EditVisitModalConsts';

export const timeInputFields = [
  {
    id: 'SUGGESTED_TIME',
    stateKey: 'suggestedTime',
    testId: VISIT_EDIT_IDS.INPUT_TIME_MODAL_EDIT_SUGGESTED_TIME,
  },
  {
    id: 'MINIMUM_TIME',
    stateKey: 'estimatedTimeMin',
    testId: VISIT_EDIT_IDS.INPUT_TIME_MODAL_EDIT_MINIMUM_TIME,
  },
  {
    id: 'MAXIMUM_TIME',
    stateKey: 'estimatedTimeMax',
    testId: VISIT_EDIT_IDS.INPUT_TIME_MODAL_EDIT_MAXIMUM_TIME,
  },
];
