export const bdrsMock = [
  {
    id: '001',
    accountId: '001',
    status: 'scheduled',
    visitDate: '2021-01-01 00:00:00',
    minTime: 1,
    maxTime: 1,
  },
  {
    id: '002',
    accountId: '002',
    status: 'in_progress',
    visitDate: '2021-08-02 00:00:00',
    minTime: 3,
    maxTime: 4,
  },
  {
    id: '003',
    accountId: '003',
    status: 'completed',
    visitDate: '2021-08-03 00:00:00',
    minTime: 5,
    maxTime: 6,
  },
  {
    id: '004',
    accountId: '004',
    status: 'justified',
    visitDate: '2021-09-04 00:00:00',
    minTime: 7,
    maxTime: 8,
  },
  {
    id: '005',
    accountId: '005',
    status: 'canceled',
    visitDate: '2021-08-05 00:00:00',
    minTime: 20,
    maxTime: 60,
  },
  {
    id: '006',
    accountId: '006',
    status: 'in_progress',
    visitDate: '2021-08-05 00:00:00',
    minTime: 20,
    maxTime: 60,
  },
  {
    id: '007',
    accountId: '007',
    status: 'scheduled',
    visitDate: '2021-08-05 00:00:00',
    minTime: 20,
    maxTime: 60,
  },
];
