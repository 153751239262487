import { Alert } from '@hexa-ui/components';
import styled from 'styled-components';

export const VisitDetailsContent = styled.div`
  padding-bottom: ${({ theme }) => theme.measure.space['space-5']};
`;

export const WrapperVisitDetailsCard = styled.div`
  display: grid;
  grid-template-columns: 57.5% 40.8%;
  grid-template-rows: repeat(2, 1fr);
  gap: ${({ theme }) => theme.measure.space['space-4']};
`;

export const VisitDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.measure.space['space-4']};
  margin-bottom: ${({ theme }) => theme.measure.space['space-9']};
  justify-content: space-between;
  width: 99.2%;
`;

export const WrapperVisitActionCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.measure.space['space-4']};
`;

export const VisitDetailsCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.measure.space['space-4']};
`;

export const AlertError = styled(Alert)`
  margin: ${({ theme }) => theme.measure.space['space-4']} 0;
  width: 100%;
`;
