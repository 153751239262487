import { axiosInstance } from '@bees-force-shared/services';

import { KPI_ENDPOINTS } from '@/constructors/Visits/VisitsConstructor';
import { GetKpiRequestQuery, GetKpiResponse } from '@/types/domains/GetKpisDomain';

export async function getKpis(requestQuery: GetKpiRequestQuery): Promise<GetKpiResponse> {
  const refreshData = requestQuery?.refreshData;

  const response = await axiosInstance.get(KPI_ENDPOINTS.GET_KPI(requestQuery), {
    headers: { vendorId: requestQuery.vendorId, refreshData },
  });

  return response.data;
}
