import { useIntl } from 'react-intl';

import { ComboboxItem } from '@ariakit/react/combobox';
import { LoadingDots } from '@hexa-ui/components';
import { Check, ChevronDown, ChevronUp, Search } from '@hexa-ui/icons';
import * as Select from '@radix-ui/react-select';

import { ComboboxProvider } from '@/providers/ComboboxProvider/ComboboxProvider';
import { ddcNameFormatter } from '@/utils/ddcNameFormatter/ddcNameFormatter';

import { Ddc } from '../../types/ModalChangeDDCTypes';
import { TEST_IDS } from './consts/SelectWithSearch';
import { useDistributionCenters } from './hooks/useDistributionCenters/useDistributionCenters';
import { useSelectWithSearch } from './hooks/useSelectWithSearch';
import * as Styled from './SelectWithSearch.styles';

interface ISelectWithSearch {
  name: string;
  searchPlaceholder: string;
  showAllMessage: string;
  ddc: Ddc;
  setDdc: React.Dispatch<React.SetStateAction<Ddc>>;
}

export const SelectWithSearch = ({
  name,
  searchPlaceholder,
  showAllMessage,
  ddc,
  setDdc,
}: ISelectWithSearch) => {
  const { isOpen, setIsOpen, containerRef } = useSelectWithSearch();
  const { formatMessage } = useIntl();
  const {
    ddcList,
    value: ddcValue,
    isLoadingDdc,
    hasError,
    setDdcName,
    infiniteScrollRootRef,
    infiniteScrollTriggerRef,
    setIsSelectOpen,
  } = useDistributionCenters();

  const handleSelectDdc = (id: string) => {
    if (id === 'default') {
      setDdc({ id: '', name: '' });
      return;
    }

    const foundedDdc = ddcList.find((ddc) => ddc.id === id);

    // The DDC Here is not null, so we can use the assertion
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setDdc(foundedDdc!);
  };

  return (
    <Select.Root
      value={ddcValue.id}
      onValueChange={handleSelectDdc}
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        setIsSelectOpen(open);
      }}>
      <ComboboxProvider
        open={isOpen}
        setOpen={setIsOpen}
        resetValueOnHide
        includesBaseElement={false}
        setValue={(value) => {
          setDdcName(value);
        }}>
        <Styled.SelectTrigger aria-label={name} data-testid={TEST_IDS.SELECT_TRIGGER}>
          <Styled.ValueContainer>
            <Select.Value placeholder={ddcNameFormatter({ ddc, showAllMessage })}>
              {ddcNameFormatter({ ddc, showAllMessage })}
            </Select.Value>
          </Styled.ValueContainer>
          <Styled.SelectIcon>
            {isOpen ? <ChevronUp size="medium" /> : <ChevronDown size="medium" />}
          </Styled.SelectIcon>
        </Styled.SelectTrigger>
        <Styled.SelectContent
          ref={(ref: HTMLDivElement) => {
            containerRef.current = ref;
            if (ref) {
              ref.addEventListener('touchend', (e) => e.preventDefault());
            }
          }}
          role="dialog"
          aria-label={name}
          position="popper"
          sideOffset={1}
          alignOffset={0}>
          <Styled.ComboboxWrapper>
            <Styled.ComboboxIcon>
              <Search />
            </Styled.ComboboxIcon>
            <Styled.ComboboxComponent
              autoSelect
              placeholder={searchPlaceholder}
              onBlurCapture={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            />
          </Styled.ComboboxWrapper>
          <Styled.Listbox ref={infiniteScrollRootRef}>
            <Styled.SelectItem key={'default'} value={'default'} asChild>
              <ComboboxItem>
                <Select.ItemText> {showAllMessage} </Select.ItemText>
                <Styled.Indicator>
                  <Check size="large" />
                </Styled.Indicator>
              </ComboboxItem>
            </Styled.SelectItem>
            {ddcList.length > 0 ? (
              ddcList.map(({ id, name }) => (
                <Styled.SelectItem key={id} value={id} asChild>
                  <ComboboxItem>
                    <Select.ItemText>
                      {id} / {name}
                    </Select.ItemText>
                    <Styled.Indicator>
                      <Check size="large" />
                    </Styled.Indicator>
                  </ComboboxItem>
                </Styled.SelectItem>
              ))
            ) : (
              <Styled.SelectItemNotFound>
                <ComboboxItem>
                  {formatMessage({ id: 'Components.ModalChangeDDC.Select.NOT_FOUND' })}
                </ComboboxItem>
              </Styled.SelectItemNotFound>
            )}

            <Styled.LoadingInfinityScroll
              data-testid={TEST_IDS.LOADING_INFINITY_SCROLL}
              ref={infiniteScrollTriggerRef}>
              {isLoadingDdc && !hasError && <LoadingDots size="large" />}
            </Styled.LoadingInfinityScroll>
          </Styled.Listbox>
        </Styled.SelectContent>
      </ComboboxProvider>
    </Select.Root>
  );
};
