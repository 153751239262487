export const CARD_COMPONENT_TEST_IDS = {
  CARD_COMPONENT: 'card-component',
  CARD_TITLE: 'card-title',
  INFO_CONTAINER: 'info-container',
  INFO: 'info',
  COUNT: 'count',
  TOTAL: 'total',
  PERCENTAGE_INFO: 'percentage-info',
  PERCENTAGE: 'percentage',
  CHEVRON_BUTTON: 'chevron-button',
  ERROR_MESSAGE: 'error-message',
};

export const OVERVIEW_REFRESH_DATA_TEST_IDS = {
  REFRESH_BUTTON: 'refresh-button',
  REFRESH_ERROR_LABEL: 'refresh-error-label',
  REFRESH_LABEL: 'refresh-last-time-update-label',
};

export const OVERVIEW_TOTAL_VISITS_CARD_TEST_IDS = {
  TOTAL_VISITS_TITLE: 'total-visits-title',
  TOTAL_VISITS_COUNT_LABEL: 'total-visits-count',
  TOTAL_VISITS_COUNT_VALUE: 'total-visits-count-value',
};

export const OVERVIEW = {
  CONTENT_OVERVIEW: 'content-overview',
};

export const HORIZONTAL_STACKED_BAR_TEST_IDS = {
  TITLE: 'bar-visits-title',
  STATUS: 'bar-visits-status',
};

export const FILTERS_TEST_IDS = {
  FILTERS_CONTAINER: 'filters-container',
  FILTERS_BY_PERIOD_CONTAINER: 'filter-by-period-container',
  FILTER_BUTTON: 'filter-button',
  FILTER_INPUT: 'filter-input',
  FILTER_BUTTON_LABEL: 'filter-button-label',
  FILTER_BUTTON_ICON_CHECK: 'filter-button-icon-check',
  FILTER_BUTTON_ICON_CALENDAR: 'filter-button-icon-calendar',
  FILTER_BUTTON_ICON_CHEVRON_DOWN: 'filter-button-icon-chevron-down',
  FILTER_BUTTON_RESET_CALENDAR: 'filter-modal-reset-button',
  FILTER_BUTTON_SAVE_CALENDAR: 'filter-modal-save-button',
  FILTER_MODAL_CALENDAR: 'filter-modal-calendar-component',
  FILTER_MODAL: 'filter-modal',
  FILTER_BUTTON_ICON_CLOSE: 'filter-button-icon-close',
  FILTER_FORMAT_LABEL: 'format-label',
};

export const FILTERS_IDS = {
  OTHER_LABEL_ID: 'other-label-id',
};

export const SEARCH_IDS = {
  SEARCH_INPUT: 'search-input',
};

export const SELECT_CHANGE_DDC_IDS = {
  SELECT_CHANGE_DDC: 'select-change-ddc',
};
