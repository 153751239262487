import { createStore } from 'effector';

import { setFilterKpisParametersState } from './FiltersKpisParametersEvents';
import {
  filtersKpisParametersInitialState,
  FiltersKpisParametersState,
} from './FiltersKpisParametersState';

export const FiltersKpisParametersStore = createStore(filtersKpisParametersInitialState).on(
  setFilterKpisParametersState,
  (state: FiltersKpisParametersState, updatedState: Partial<FiltersKpisParametersState>) => ({
    ...state,
    ...updatedState,
  }),
);
