import analytics, { ScreenNameApp } from '@/config/typewriter';
import { useAnalytics } from '@/hooks/useAnalytics/useAnalytics';
import {
  ComponentsName,
  OnSortVisitListProps,
  UseSegmentSortingVisitsProps,
} from '@/types/AnalyticsTypes';

export const useSegmentSortingVisits = ({ column, direction }: UseSegmentSortingVisitsProps) => {
  const { defaultProps } = useAnalytics();
  if (!!column && !!direction) {
    const sortingVisitProps = {
      ...defaultProps,
      [OnSortVisitListProps.componentName]: ComponentsName.tableVisits,
      [OnSortVisitListProps.orderPriority]: direction as string,
      [OnSortVisitListProps.screenNameApp]: ScreenNameApp.Visit,
      [OnSortVisitListProps.sortingInfo]: OnSortVisitListProps.visit_date,
    };

    try {
      analytics.sortingVisits({
        ...sortingVisitProps,
        [OnSortVisitListProps.failureReason]: '',
      });
    } catch (error) {
      analytics.sortingVisits({
        ...sortingVisitProps,
        [OnSortVisitListProps.failureReason]:
          error instanceof Error ? error.message : OnSortVisitListProps.unknownError,
      });
    }
  }
};
