import { useEffect } from 'react';

import { Modal, Button, Heading, Select } from '@hexa-ui/components';
import { Calendar, ChevronDown, ChevronUp } from '@hexa-ui/icons';

import { VISIT_EDIT_IDS } from '../../consts/EditVisitModalConsts';
import { StatusVisit } from '../../types/VisitDetails.types';
import { SingleDatePicker } from './components/SingleDatePicker/SingleDatePicker';
import * as Styled from './EditVisitModal.styles';
import { useEditVisitModal } from './hooks/useEditVisitModal/useEditVisitModal';
import { useTranslation } from './hooks/useTranslation/useTranslation';

interface EditVisitModalProps {
  status: string;
  date: Date;
  nextVisitDate?: Date;
}

export const EditVisitModal = ({ status, date, nextVisitDate }: EditVisitModalProps) => {
  const {
    editVisit,
    visitDate,
    visitStatus,
    nextVisit,
    edit,
    canceled,
    scheduled,
    cancel,
    saveChanges,
  } = useTranslation();
  const {
    containerRef,
    handleOnSaveDate,
    handleOnSaveModal,
    isOpenCalendar,
    parseDateToString,
    parseVisitLabelDate,
    setOpenCalendar,
    selectedStatus,
    setSelectedStatus,
    setSelectedDate,
    calendarLanguage,
  } = useEditVisitModal();

  useEffect(() => {
    setSelectedStatus(status);
    setSelectedDate(date);
  }, [status, setSelectedStatus, setSelectedDate, date]);

  useEffect(() => {
    const handleClickOutsideEvent = (event: MouseEvent) => {
      const getButtonLabelAttribute = event.target as HTMLElement;
      const eventId = getButtonLabelAttribute.getAttribute('id');
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        eventId !== VISIT_EDIT_IDS.OPEN_CALENDAR_MODAL_EDIT_BUTTON
      ) {
        setOpenCalendar(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideEvent);
    document.addEventListener('pointerdown', handleClickOutsideEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEvent);
      document.addEventListener('pointerdown', handleClickOutsideEvent);
    };
  }, [containerRef, setOpenCalendar]);

  const modalActions = () => (
    <Styled.ModalActions>
      <Modal.Cancel>
        <Button
          data-testid={VISIT_EDIT_IDS.CANCEL_MODAL_EDIT_BUTTON}
          size="medium"
          variant="secondary"
          onClick={() => setOpenCalendar(false)}>
          {cancel}
        </Button>
      </Modal.Cancel>
      <Modal.Action>
        <Button
          data-testid={VISIT_EDIT_IDS.SAVE_MODAL_EDIT_BUTTON}
          size="medium"
          variant="primary"
          onClick={handleOnSaveModal}
          disabled={!selectedStatus}>
          {saveChanges}
        </Button>
      </Modal.Action>
    </Styled.ModalActions>
  );

  return (
    <Modal.Root
      actions={modalActions()}
      title={<Heading size="H3">{editVisit}</Heading>}
      trigger={
        <Button
          size="medium"
          variant="secondary"
          id={VISIT_EDIT_IDS.OPEN_MODAL_EDIT_BUTTON}
          data-testid={VISIT_EDIT_IDS.OPEN_MODAL_EDIT_BUTTON}>
          {edit}
        </Button>
      }>
      <Styled.WrapperModalContentFields>
        <div>
          <span>{visitDate}</span>
          <Styled.OpenCalendarButton
            id={VISIT_EDIT_IDS.OPEN_CALENDAR_MODAL_EDIT_BUTTON}
            data-testid={VISIT_EDIT_IDS.OPEN_CALENDAR_MODAL_EDIT_BUTTON}
            size="large"
            variant="secondary"
            onClick={() => setOpenCalendar(!isOpenCalendar)}>
            <Styled.WrapperDateAndCalendar>
              <Styled.CustomIconButton variant="inherit" size="medium" icon={Calendar} />
              <span data-testid={VISIT_EDIT_IDS.SELECT_DATE_MODAL_EDIT}>{parseDateToString()}</span>
            </Styled.WrapperDateAndCalendar>
            <Styled.CustomIconButton
              variant="inherit"
              size="small"
              icon={isOpenCalendar ? ChevronUp : ChevronDown}
            />
          </Styled.OpenCalendarButton>
        </div>
        {isOpenCalendar && (
          <SingleDatePicker
            datePickerRef={containerRef}
            onSave={handleOnSaveDate}
            language={calendarLanguage()}
          />
        )}
        <div>
          <Select.Root
            data-testid={VISIT_EDIT_IDS.SELECT_STATUS_MODAL_EDIT}
            defaultValue="scheduled"
            label={visitStatus}
            value={selectedStatus}
            disabled={status === StatusVisit.SCHEDULED}
            onChange={setSelectedStatus}>
            <Select.Option value="canceled">{canceled}</Select.Option>
            <Select.Option value="scheduled">{scheduled}</Select.Option>
          </Select.Root>
        </div>
        <Styled.VisitLabel>
          <span>{nextVisit}</span>
          <span data-testid={VISIT_EDIT_IDS.NEXT_VISIT_MODAL_EDIT}>
            {parseVisitLabelDate(nextVisitDate)}
          </span>
        </Styled.VisitLabel>
      </Styled.WrapperModalContentFields>
    </Modal.Root>
  );
};
