import { Card, TextLink } from '@hexa-ui/components';
import styled from 'styled-components';

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperNextVisitCard = styled(Card)`
  padding-top: ${({ theme }) => theme.measure.space['space-6']};
  padding-right: ${({ theme }) => theme.measure.space['space-6']};
  padding-bottom: ${({ theme }) => theme.measure.space['space-8']};
  padding-left: ${({ theme }) => theme.measure.space['space-6']};
  gap: ${({ theme }) => theme.measure.space['space-4']};
  height: 10.75rem;
`;

export const Link = styled(TextLink)`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.measure.space['space-1']};
`;
