import { useRef } from 'react';

import { Button, Checkbox } from '@hexa-ui/components';
import { ChevronDown, ChevronUp, Filter } from '@hexa-ui/icons';

import { FILTERS_TEST_IDS } from '../../consts/FiltersVisits';
import { useFilterVisits } from '../../hooks/useFilterVisits/useFiltersVisits';
import { useTranslation } from '../../hooks/useTranslation/useTranslation';
import * as Styled from './FiltersVisits.styles';

const FilterList = ({ filters, onCheck }) => (
  <Styled.ListItemsContainer>
    {filters.map((filter) => (
      <Styled.ListItem key={filter.id}>
        <Checkbox
          id={filter.id}
          label={filter.label}
          checked={filter.checked}
          onCheckedChange={() => onCheck(filter.id)}
        />
      </Styled.ListItem>
    ))}
  </Styled.ListItemsContainer>
);

const FilterButtons = ({ onClear, onClose, onApply, canApply }) => {
  const { applyButton, clearAllButton, cancelButton } = useTranslation();

  return (
    <Styled.FilterButtonsContainer>
      <Styled.ClearButton
        size="medium"
        variant="secondary"
        disabled={!canApply}
        onClick={onClear}
        data-testid={FILTERS_TEST_IDS.CLEAR_ALL_BUTTON}>
        {clearAllButton}
      </Styled.ClearButton>
      <Styled.FilterActionButtons>
        <Button
          size="medium"
          variant="secondary"
          onClick={onClose}
          data-testid={FILTERS_TEST_IDS.CANCEL_BUTTON}>
          {cancelButton}
        </Button>
        <Button
          size="medium"
          variant="primary"
          onClick={onApply}
          data-testid={FILTERS_TEST_IDS.APPLY_BUTTON}>
          {applyButton}
        </Button>
      </Styled.FilterActionButtons>
    </Styled.FilterButtonsContainer>
  );
};

export const FilterVisits = ({ appliedFilters, onApplyFilter }) => {
  const { filtersButton, status } = useTranslation();
  const filterRef = useRef(null);

  const {
    isExpanded,
    isApplySomeFilters,
    tempFilters,
    countAppliedFilters,
    handleOpenCloseFilter,
    handleClickFilter,
    handleClearFilters,
    handleClickApply,
    handleClickCancel,
  } = useFilterVisits({ appliedFilters, onApplyFilter, filterRef });

  const renderIcon = isExpanded ? <ChevronUp size="medium" /> : <ChevronDown size="medium" />;

  return (
    <Styled.FilterContainer ref={filterRef}>
      <Styled.FilterToggleButton
        size="medium"
        leading
        variant="secondary"
        onClick={handleOpenCloseFilter}
        expanded={isExpanded ? 'true' : ''}
        data-testid={FILTERS_TEST_IDS.FILTERS_BUTTON}>
        <Filter size="medium" />
        {filtersButton}
        {renderIcon}
      </Styled.FilterToggleButton>
      {isExpanded && (
        <Styled.FilterPanelContent border="medium" elevated="small">
          <Styled.FilterContent>
            <Styled.TabsContainer>
              <Styled.FilterTab weight="semibold">
                {status}
                {countAppliedFilters > 0 && (
                  <Styled.CountAppliedFilters counterLabel={countAppliedFilters} />
                )}
              </Styled.FilterTab>
            </Styled.TabsContainer>
            <FilterList filters={tempFilters} onCheck={handleClickFilter} />
          </Styled.FilterContent>
          <FilterButtons
            onClear={handleClearFilters}
            onClose={handleClickCancel}
            onApply={handleClickApply}
            canApply={isApplySomeFilters}
          />
        </Styled.FilterPanelContent>
      )}
    </Styled.FilterContainer>
  );
};
