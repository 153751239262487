import { useNavigate } from 'react-router-dom';

import { PATH_BASE, PATH_HOME } from '@/consts/route';
import { VisitDetailsSegmentProps } from '@/types/AnalyticsTypes';

import { useSegmentVisitDetails } from '../../../hooks/useSegmentVisitDetails/useSegmentVisitDetails';

export const useNextVisit = () => {
  const { dispatchVisitDetailsEvent } = useSegmentVisitDetails();
  const navigate = useNavigate();

  const handleLinkClick = (nextVisitId: string) => {
    navigate(`${PATH_HOME}${PATH_BASE}/${nextVisitId}`);

    dispatchVisitDetailsEvent(
      VisitDetailsSegmentProps.VisitDetails,
      VisitDetailsSegmentProps.NextVisit,
    );
  };

  return {
    handleLinkClick,
  };
};
