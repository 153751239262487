export enum Periods {
  YESTERDAY = 'yesterday',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  OTHER = 'other',
}

export enum Ordinal {
  st = 'st',
  nd = 'nd',
  rd = 'rd',
  th = 'th',
}

export enum KpiStatus {
  SCHEDULED = 'SCHEDULED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  JUSTIFIED = 'JUSTIFIED',
  CANCELED = 'CANCELED',
}
