import { constructEndpoint } from '@bees-force-shared/services';

import { SERVICE_VISITS_ADMIN_BFF } from '@/consts/Endpoints';
import { DeleteVisitRequestQuery } from '@/types/domains/DeleteVisitDomain';
import { GetKpiRequestQuery } from '@/types/domains/GetKpisDomain';
import { GetVisitDetailsRequestQuery } from '@/types/domains/GetVisitDetailsDomain';
import { UpdateVisitRequestQuery } from '@/types/domains/UpdateVisitDomain';

enum ENDPOINTS {
  GET_KPI = 'visits/kpis',
  GET_VISIT_DETAILS = 'visits-details/{id}',
  PATCH_VISIT = 'visits/{id}',
  DELETE_VISIT = 'visits/{id}',
}

export const KPI_ENDPOINTS = {
  GET_KPI: (params: GetKpiRequestQuery) =>
    constructEndpoint({
      service: SERVICE_VISITS_ADMIN_BFF,
      endpoint: ENDPOINTS.GET_KPI,
      queryParams: { ...params, vendorId: params.vendorId.join(',') },
    }),
};

export const VISIT_DETAILS_ENDPOINTS = {
  GET_VISIT_DETAILS: (params: GetVisitDetailsRequestQuery) =>
    constructEndpoint({
      service: SERVICE_VISITS_ADMIN_BFF,
      endpoint: ENDPOINTS.GET_VISIT_DETAILS,
      pathParams: { id: params.id },
    }),
  DELETE_VISIT: (params: DeleteVisitRequestQuery) =>
    constructEndpoint({
      service: SERVICE_VISITS_ADMIN_BFF,
      endpoint: ENDPOINTS.DELETE_VISIT,
      pathParams: { id: params.visitId },
    }),
};

export const UPDATE_VISIT_ENDPOINTS = {
  PATCH_EDIT_VISIT: (params: UpdateVisitRequestQuery) =>
    constructEndpoint({
      service: SERVICE_VISITS_ADMIN_BFF,
      endpoint: ENDPOINTS.PATCH_VISIT,
      pathParams: { id: params.id },
    }),
};
