import { SearchField } from '@hexa-ui/components';
import styled from 'styled-components';

export const SearchFieldContainer = styled.div.attrs((props) => ({
  placeholderWidth: props['aria-setsize'],
  ref: props.ref,
}))`
  div > div > button[role='combobox'] {
    margin-top: 0;
  }
  div > div {
    max-width: 100%;
  }
`;

export const CustoSearchField = styled(SearchField.Root)`
  &::placeholder {
    font-size: 15px;
  }
`;
