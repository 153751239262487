import { $userSelection } from '@bees-force-shared/services';
import { Paragraph } from '@hexa-ui/components';
import { useUnit } from 'effector-react';

import { useQueryKpis } from '@/reactQueryHooks/visits/useQueryKpis';
import { FiltersKpisParametersStore } from '@/stores/filtersKpisStore/FiltersKpisParametersStore';
import { UserInfoStore } from '@/stores/userInfoStore/UserInfoStore';
import { quantityFormatter } from '@/utils/quantityFormatter/quantityFormatter';

import { HORIZONTAL_STACKED_BAR_TEST_IDS as TEST_IDS } from '../../consts/OverviewConsts';
import { useHorizontalStackedBar } from './hooks/useHorizontalStackedBar';
import * as Styled from './HorizontalStackedBar.styles';

export const HorizontalStackedBar = () => {
  const { ddc } = useUnit(UserInfoStore);
  const state = useUnit($userSelection);
  const vendorId = state?.operation?.vendorId ?? '';

  const { startDate, endDate } = useUnit(FiltersKpisParametersStore);

  const useQueryKpisParams = {
    vendorId: [vendorId],
    startDate,
    endDate,
  };

  if (ddc.id !== '') {
    useQueryKpisParams['deliveryCenterId'] = ddc.id;
  }

  const { kpis } = useQueryKpis(useQueryKpisParams);

  const { bars, status, formatMessage } = useHorizontalStackedBar({
    visitsByStatus: kpis?.data?.visitsByStatus,
  });

  return (
    <Styled.WrapperHorizontalStackedBar>
      <Paragraph size="small" colortype="secondary" data-testid={TEST_IDS.TITLE}>
        {formatMessage({ id: 'Components.Overview.Graph.TITLE' })}
      </Paragraph>
      <Styled.WrapperHorizontalStackedBarStatusBar>
        {bars.map((item) => (
          <Styled.WrapperHorizontalStackedBarPiece
            key={item.name}
            color={item.color}
            width={item.size}
          />
        ))}
      </Styled.WrapperHorizontalStackedBarStatusBar>

      <Styled.WrapperHorizontalStackedBarStatusContainer>
        {status.map((item) => (
          <Styled.WrapperHorizontalStackedBarStatus key={item.name}>
            <Styled.WrapperHorizontalStackedBarDot
              color={item.color}></Styled.WrapperHorizontalStackedBarDot>

            <Paragraph
              size="xsmall"
              data-testid={TEST_IDS.STATUS + '-' + item.name.toLocaleLowerCase()}>
              {formatMessage({ id: 'Components.Overview.Graph.' + item.name })}
            </Paragraph>

            <Paragraph
              size="xsmall"
              colortype={item.count == 0 ? 'secondary' : 'primary'}
              weight="semibold"
              data-testid={item.name}>
              {quantityFormatter(item.count)}
            </Paragraph>
          </Styled.WrapperHorizontalStackedBarStatus>
        ))}
      </Styled.WrapperHorizontalStackedBarStatusContainer>
    </Styled.WrapperHorizontalStackedBar>
  );
};
