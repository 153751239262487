import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { X } from '@hexa-ui/icons';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';

import { Locales } from '@/enums/locales';
import { setFilterKpisParametersState } from '@/stores/filtersKpisStore/FiltersKpisParametersEvents';

import { FILTERS_TEST_IDS } from '../../consts/OverviewConsts';
import { Periods } from '../../enums/visits';
import { FilterButton } from './components/FilterButton/FilterButton';
import { FilterModalCalendar } from './components/FilterModalCalendar/FilterModalCalendar';
import * as Styled from './Filters.styles';
import { useFilters } from './hooks/useFilters';

export const Filters = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();
  const locale = (preferredLanguage || defaultLanguage) as Locales;

  const {
    selectedPeriod,
    handleOnClick,
    handleOnSaveDate,
    handleRadioChange,
    openCalendar,
    inputRef,
    showClearData,
    resetAllStatus,
    calendarLanguage,
    formatDateLabel,
    handleOnClickOutside,
    startDate,
    endDate,
  } = useFilters(locale);

  useEffect(() => {
    setFilterKpisParametersState({ startDate, endDate });
  }, [endDate, startDate]);

  return (
    <Styled.FiltersContainer data-testid={FILTERS_TEST_IDS.FILTERS_CONTAINER}>
      <Styled.FilterDescription>
        {formatMessage({ id: 'Components.Overview.Label.FILTER_DESCRIPTION' })}
      </Styled.FilterDescription>
      <Styled.FilterWrapper>
        {Object.values(Periods).map((period) => (
          <FilterButton
            inputRef={inputRef}
            key={period}
            period={period}
            name="period"
            checked={selectedPeriod === period}
            onChange={handleRadioChange}
            onClick={() => handleOnClick(period)}
            openModal={openCalendar}
            data-testid={`button-radio-${period}`}
          />
        ))}
        {selectedPeriod == Periods.OTHER && openCalendar && (
          <FilterModalCalendar
            language={calendarLanguage()}
            inputRef={inputRef}
            handleOnSave={handleOnSaveDate}
            handleOnClickOutside={handleOnClickOutside}
          />
        )}
        <Styled.DateLabel data-testid={FILTERS_TEST_IDS.FILTER_FORMAT_LABEL}>
          {formatDateLabel()}
        </Styled.DateLabel>
        {showClearData && (
          <Styled.IconButtonClear
            variant="tertiary"
            icon={X}
            onClick={resetAllStatus}
            size="tiny"
            className="clear-button"
          />
        )}
      </Styled.FilterWrapper>
    </Styled.FiltersContainer>
  );
};
