import { useIntl } from 'react-intl';

import { STATUS } from '@/consts/GlobalConsts';

import { BadgeStatusInterface } from '../types/BadgeStatusTypes';

export const useBadgeStatus = ({ status }: BadgeStatusInterface) => {
  const { formatMessage } = useIntl();

  const statusStyles = {
    [STATUS.CANCELED]: {
      $bgcolor: '#FFE6E6',
      $labelcolor: '#E82727',
      label: formatMessage({
        id: 'Components.BadgeStatus.Label.CANCELED',
      }),
    },
    [STATUS.SCHEDULE]: {
      $bgcolor: '#E6F9E6',
      $labelcolor: '#009900',
      label: formatMessage({
        id: 'Components.BadgeStatus.Label.SCHEDULED',
      }),
    },
    [STATUS.COMPLETED]: {
      $bgcolor: '#F2F2F2',
      $labelcolor: '#585858',
      label: formatMessage({
        id: 'Components.BadgeStatus.Label.COMPLETED',
      }),
    },
    [STATUS.IN_PROGRESS]: {
      $bgcolor: '#E6F2FF',
      $labelcolor: '#006EDB',
      label: formatMessage({
        id: 'Components.BadgeStatus.Label.IN_PROGRESS',
      }),
    },
    [STATUS.JUSTIFIED]: {
      $bgcolor: '#FFF4E6',
      $labelcolor: '#D47300',
      label: formatMessage({
        id: 'Components.BadgeStatus.Label.JUSTIFIED',
      }),
    },
  };

  const style = statusStyles[status];

  return { style };
};
