import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ErrorPage, LoadingContent } from '@bees-force-shared/components';
import { $userSelection } from '@bees-force-shared/services';
import { Heading, IconButton } from '@hexa-ui/components';
import { ArrowLeft } from '@hexa-ui/icons';

import { useAccount } from '@/reactQueryHooks/account/UseAccount';
import { useQueryVisitDetails } from '@/reactQueryHooks/visits/useQueryVisitDetails';

import { BdrInformation } from './components/BdrInformation/BdrInformation';
import { CancelVisitModal } from './components/CancelVisitModal/CancelVisitModal';
import { EditVisitModal } from './components/EditVisitModal/EditVisitModal';
import { NextVisit } from './components/NextVisit/NextVisit';
import { PocInformation } from './components/PocInformation/PocInformation';
import { VisitInformation } from './components/VisitInformation/VisitInformation';
import { VISIT_DETAILS_CARD_TEST_IDS } from './consts/VisitDetailsCardConsts';
import { useTranslation } from './hooks/useTranslation/useTranslation';
import { useVisitDetails } from './hooks/useVisitDetails';
import * as Styled from './VisitDetailsCard.styles';

export const VisitDetailsCard = () => {
  const { id } = useParams();
  const visitId = id ?? '';
  const { formatMessage } = useIntl();

  const state = $userSelection.getState();
  const vendorId = state?.operation?.vendorId;

  const { visitDetails } = useQueryVisitDetails({ id: visitId, vendorId }) ?? {};
  const {
    handleClickGoBackToVisitListButton,
    visitDetailsInfo,
    isCanceledActionAvailable,
    isVisitEditVisible,
    handleGoToVisitListErrorButton,
    wasCanceledByUser,
    wasCanceledBySystem,
  } = useVisitDetails({
    visitDetails: visitDetails?.data,
  });
  const { canceledBySystem, canceledBySystemReason, canceledByUserAdmin, canceledByUserSync } =
    useTranslation();

  const { account } = useAccount({ accountId: visitDetailsInfo.accountId });

  if (visitDetails?.isFetching || account.isFetching) {
    return (
      <Styled.WrapperLoading>
        <LoadingContent
          text={formatMessage({ id: 'Components.VisitDetails.PageStatus.LOADING' })}
          data-testid={VISIT_DETAILS_CARD_TEST_IDS.LOADING_PAGE_CONTENT}
        />
      </Styled.WrapperLoading>
    );
  }

  if (visitDetails?.isError) {
    return (
      <ErrorPage
        goTo={handleGoToVisitListErrorButton}
        goToText={formatMessage({ id: 'Components.VisitDetails.PageStatus.Error.ON_GO_TO' })}
        errorMessage={formatMessage({
          id: 'Components.VisitDetails.PageStatus.Error.ERROR_MESSAGE',
        })}
        subtitle={formatMessage({
          id: 'Components.VisitDetails.PageStatus.Error.SUBTITLE',
        })}
        onRefreshText={formatMessage({ id: 'Components.VisitDetails.PageStatus.Error.ON_REFRESH' })}
        onRefresh={() => visitDetails.refetch()}
      />
    );
  }

  return (
    <Styled.VisitDetailsContent>
      <Styled.VisitDetailsHeader>
        <Styled.VisitDetailsCardHeader>
          <IconButton
            icon={ArrowLeft}
            variant="tertiary"
            data-testid={VISIT_DETAILS_CARD_TEST_IDS.GO_BACK_ICON_BUTTON}
            size="medium"
            onClick={handleClickGoBackToVisitListButton}
          />
          <Heading data-testid={VISIT_DETAILS_CARD_TEST_IDS.HEADER} size="H2">
            {formatMessage({ id: 'Components.VisitDetails.Title.VISIT_DETAILS' })}
          </Heading>
        </Styled.VisitDetailsCardHeader>
        <Styled.WrapperVisitActionCard>
          {isCanceledActionAvailable && <CancelVisitModal visitId={visitId} key={id} />}
          {isVisitEditVisible && (
            <EditVisitModal
              date={visitDetailsInfo.currentVisitDate}
              status={visitDetailsInfo.currentVisitStatus}
              nextVisitDate={visitDetailsInfo.nextVisitDate}
              visitId={visitId}
            />
          )}
        </Styled.WrapperVisitActionCard>
      </Styled.VisitDetailsHeader>

      {wasCanceledByUser && visitDetailsInfo.cancelationReason && (
        <Styled.AlertError
          type="error"
          canceledByUser
          message={
            visitDetailsInfo.cancelationReason[0] === 'canceled_by_user_admin'
              ? canceledByUserAdmin
              : canceledByUserSync
          }
          data-testid={VISIT_DETAILS_CARD_TEST_IDS.ALERT_ERROR}
        />
      )}

      {wasCanceledBySystem && visitDetailsInfo.cancelationReason && (
        <Styled.AlertError
          type="error"
          message={canceledBySystem.replace(
            '[error]',
            canceledBySystemReason[visitDetailsInfo.cancelationReason[0]],
          )}
          data-testid={VISIT_DETAILS_CARD_TEST_IDS.ALERT_ERROR}
        />
      )}

      <Styled.WrapperVisitDetailsCard>
        <VisitInformation
          date={visitDetailsInfo.currentVisitDate}
          status={visitDetailsInfo.currentVisitStatus}
          suggestedTime={visitDetailsInfo.currentVisitSuggestedTime?.toString() ?? ''}
          minimumTime={visitDetailsInfo.currentVisitEstimatedTimeMin?.toString() ?? ''}
          maximumTime={visitDetailsInfo.currentVisitEstimatedTimeMax?.toString() ?? ''}
          isLoading={visitDetails?.isFetching}
          isError={visitDetails?.isError}
          onRefresh={visitDetails?.refetch}
        />
        <PocInformation
          pocName={account.data?.pocName}
          pocId={visitDetailsInfo.accountId}
          pocLatitude={account.data?.latitude}
          pocLongitude={account.data?.longitude}
          loading={visitDetails?.isFetching || account.isFetching}
          hasError={visitDetails?.isError || account.isError}
          onRefresh={account.refetch}
        />
        <NextVisit
          nextVisitDate={visitDetailsInfo.nextVisitDate}
          nextVisitStatus={visitDetailsInfo.nextVisitStatus}
          nextVisitId={visitDetailsInfo.nextVisitId}
          isLoading={visitDetails?.isFetching}
          isError={visitDetails?.isError}
          onRefresh={visitDetails?.refetch}
        />
        <BdrInformation
          bdrId={visitDetailsInfo.bdrId}
          isLoading={visitDetails?.isFetching}
          isError={visitDetails?.isError}
          onRefresh={visitDetails?.refetch}
        />
      </Styled.WrapperVisitDetailsCard>
    </Styled.VisitDetailsContent>
  );
};
