import { enUS, es, ptBR, id, fr } from 'date-fns/locale';

export const localeMap: Record<string, Locale> = {
  'en-US': enUS,
  'es-419': es,
  'pt-BR': ptBR,
  'id-ID': id,
  'fr-FR': fr,
};

export const loadLocale = (localeKey: string): Locale => {
  return localeMap[localeKey] || enUS;
};
