export const STALE_TIME_MS = 5 * 60 * 1000;
export const RETRY_TIMES = 3;
export const RETRY_DELAY_MIN_MS = 2 * 1000;
export const RETRY_DELAY_MAX_MS = 10 * 1000;

export const DEBUG_INITIAL_IS_OPEN = false;

export const QUERY_DEVTOOLS_POSITION = 'bottom-left';

export const QUERY_KEY = {
  VISITS: 'visits',
};
