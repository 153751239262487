import { useIntl } from 'react-intl';

import { EditLabelButton, EditModalOperation } from '@bees-force-shared/business-components';

import { TooltipOperations } from './components/TooltipOperations/TooltipOperations';
import { TEST_IDS } from './consts/ModalChangeOperation';
import { useModalChangeOperation } from './hooks/useModalChangeOperation';
import * as Styled from './ModalChangeOperation.styles';
import { IModalChangeOperationProps } from './types/ModalChangeOperationTypes';

export const ModalChangeOperation = ({ disabled }: IModalChangeOperationProps) => {
  const { formatMessage } = useIntl();

  const {
    currentOperation,
    operationData,
    handleCancel,
    handleConfirm,
    handleOpenModal,
    isEditOperationModalOpen,
  } = useModalChangeOperation();

  return (
    <Styled.Container>
      <EditModalOperation
        isOpen={isEditOperationModalOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        operationData={operationData}
        hasVendor
        translations={{
          title: formatMessage({ id: 'Components.ModalChangeOperation.TITLE' }),
          subTitle: formatMessage({ id: 'Components.ModalChangeOperation.SUB_TITLE' }),
          actions: {
            cancelButton: formatMessage({
              id: 'Components.ModalChangeOperation.Actions.CANCEL_BUTTON',
            }),
            saveButton: formatMessage({
              id: 'Components.ModalChangeOperation.Actions.SAVE_BUTTON',
            }),
          },
          fields: {
            country: {
              label: formatMessage({ id: 'Components.ModalChangeOperation.Fields.Country.LABEL' }),
              placeholder: formatMessage({
                id: 'Components.ModalChangeOperation.Fields.Country.PLACEHOLDER',
              }),
            },
            organization: {
              label: formatMessage({
                id: 'Components.ModalChangeOperation.Fields.Organization.LABEL',
              }),
              hint: formatMessage({
                id: 'Components.ModalChangeOperation.Fields.Organization.HINT',
              }),
              placeholder: formatMessage({
                id: 'Components.ModalChangeOperation.Fields.Organization.PLACEHOLDER',
              }),
              alert: formatMessage({
                id: 'Components.ModalChangeOperation.Fields.Organization.ALERT',
              }),
            },
            seller: {
              label: formatMessage({ id: 'Components.ModalChangeOperation.Fields.Seller.LABEL' }),
              hint: formatMessage({ id: 'Components.ModalChangeOperation.Fields.Seller.HINT' }),
              placeholder: formatMessage({
                id: 'Components.ModalChangeOperation.Fields.Seller.PLACEHOLDER',
              }),
            },
            vendorId: {
              label: formatMessage({ id: 'Components.ModalChangeOperation.Fields.VendorId.LABEL' }),
              placeholder: formatMessage({
                id: 'Components.ModalChangeOperation.Fields.VendorId.PLACEHOLDER',
              }),
            },
          },
        }}
      />

      <EditLabelButton
        data-testid={TEST_IDS.OPEN_MODAL_OPERATION}
        label={formatMessage({ id: 'Components.ModalChangeOperation.Button.Label.OPERATION' })}
        title={currentOperation}
        onClick={handleOpenModal}
        disabled={disabled}
      />

      <TooltipOperations />
    </Styled.Container>
  );
};
