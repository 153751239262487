/* eslint-disable camelcase */
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import {
  AnalyticsWrapper,
  OptimizelyWrapper,
  QueryWrapper,
} from '@bees-force-shared/business-components';
import { ThemeProvider } from '@bees-force-shared/theme';

import { EnvProvider } from './components';
import { EnvConfig } from './components/EnvProvider/EnvProvider.types';
import { IntlProvider } from './i18n';
import { routes } from './Routes';

const router = createBrowserRouter(routes, {
  future: {
    v7_relativeSplatPath: true,
  },
});

export function App(props: EnvConfig) {
  return (
    <EnvProvider env={props}>
      <IntlProvider>
        <OptimizelyWrapper sdkKey={props.OPTIMIZELY_KEY ?? ''}>
          <AnalyticsWrapper segmentKey={props.SEGMENT_KEY ?? ''}>
            <ThemeProvider>
              <QueryWrapper>
                <RouterProvider router={router} future={{ v7_startTransition: true }} />
              </QueryWrapper>
            </ThemeProvider>
          </AnalyticsWrapper>
        </OptimizelyWrapper>
      </IntlProvider>
    </EnvProvider>
  );
}
