import { useIntl } from 'react-intl';

import { Tooltip } from '@hexa-ui/components';
import { Info } from '@hexa-ui/icons';

import * as Styled from './TooltipOperations.styles';

export const TooltipOperations = () => {
  const { formatMessage } = useIntl();

  return (
    <Styled.TooltipContainer>
      <Tooltip
        placement="left"
        text={formatMessage({
          id: 'Components.ModalChangeOperation.Button.Label.TOOLTIP_INFORMATION',
        })}>
        <Info size="large" />
      </Tooltip>
    </Styled.TooltipContainer>
  );
};
