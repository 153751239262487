import analytics from '@/config/typewriter';
import { useAnalytics } from '@/hooks/useAnalytics/useAnalytics';
import { ComponentsName, onRefreshVisitListSegmentProps } from '@/types/AnalyticsTypes';

export const useSegmentRefreshVisits = () => {
  const { defaultProps } = useAnalytics();

  const dispatchRefreshEvent = (buttonName: string) => {
    const refreshVisitProps = {
      ...defaultProps,
      [onRefreshVisitListSegmentProps.ButtonName]: buttonName,
      [onRefreshVisitListSegmentProps.ComponentName]: ComponentsName.tableVisits,
      [onRefreshVisitListSegmentProps.FailureReason]: '',
    };

    try {
      analytics.refreshDataVisits(refreshVisitProps);
    } catch (error) {
      analytics.refreshDataVisits({
        ...refreshVisitProps,
        [onRefreshVisitListSegmentProps.FailureReason]:
          error instanceof Error ? error.message : onRefreshVisitListSegmentProps.unknownError,
      });
    }
  };

  return {
    dispatchRefreshEvent,
  };
};
