export interface FiltersKpisParametersState {
  startDate: string;
  endDate: string;
  deliveryCenterId?: string;
}

export const filtersKpisParametersInitialState: FiltersKpisParametersState = {
  startDate: '',
  endDate: '',
  deliveryCenterId: '',
};
