import { Paragraph } from '@hexa-ui/components';

import { HORIZONTAL_STACKED_BAR_TEST_IDS as TEST_IDS } from '../../consts/OverviewConsts';
import { useHorizontalStackedBar } from './hooks/useHorizontalStackedBar';
import * as Styled from './HorizontalStackedBar.styles';

export const HorizontalStackedBar = () => {
  const { bars, handleClick, status, last, first, formatMessage } = useHorizontalStackedBar();

  return (
    <Styled.WrapperHorizontalStackedBar onClick={handleClick}>
      <Paragraph size="small" colortype="secondary" data-testid={TEST_IDS.TITLE}>
        {formatMessage({ id: 'Components.Overview.Graph.TITLE' })}
      </Paragraph>
      <Styled.WrapperHorizontalStackedBarStatusBar>
        {bars.map((item, index) => (
          <Styled.WrapperHorizontalStackedBarPiece
            key={item.name}
            color={item.color}
            width={item.size}
            brstart={index == first ? '10px' : '0px'}
            brend={index == last ? '10px' : '0px'}
          />
        ))}
      </Styled.WrapperHorizontalStackedBarStatusBar>

      <Styled.WrapperHorizontalStackedBarStatusContainer>
        {status.map((item) => (
          <Styled.WrapperHorizontalStackedBarStatus key={item.name}>
            <Styled.WrapperHorizontalStackedBarDot
              color={item.color}></Styled.WrapperHorizontalStackedBarDot>

            <Paragraph
              size="xsmall"
              data-testid={TEST_IDS.STATUS + '-' + item.name.toLocaleLowerCase()}>
              {formatMessage({ id: 'Components.Overview.Graph.' + item.name })}
            </Paragraph>

            <Paragraph
              size="xsmall"
              colortype={item.count == 0 ? 'secondary' : 'primary'}
              weight="semibold"
              data-testid={item.name}>
              {' '}
              {item.count}{' '}
            </Paragraph>
          </Styled.WrapperHorizontalStackedBarStatus>
        ))}
      </Styled.WrapperHorizontalStackedBarStatusContainer>
    </Styled.WrapperHorizontalStackedBar>
  );
};
