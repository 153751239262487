import { VisitStatus } from '@/api/services/visits/types';
import { setStatus } from '@/stores/visitsParams/VisitsParamsEvents';

import { FILTERS_VISITS_IDS } from '../consts/FiltersVisits';

export const filterIdToApiStatus = {
  [FILTERS_VISITS_IDS.scheduled]: VisitStatus.SCHEDULE,
  [FILTERS_VISITS_IDS.completed]: VisitStatus.COMPLETED,
  [FILTERS_VISITS_IDS.canceled]: VisitStatus.CANCELED,
  [FILTERS_VISITS_IDS.justified]: VisitStatus.JUSTIFIED,
  [FILTERS_VISITS_IDS.inProgress]: VisitStatus.IN_PROGRESS,
};

export const updateStatusStore = (activeFilters: string[]): void => {
  if (activeFilters.length === 0) {
    setStatus(null);
    return;
  }

  const apiStatusValues = activeFilters
    .map((filterId) => filterIdToApiStatus[filterId])
    .filter(Boolean);

  const statusParam = apiStatusValues.join(',');
  setStatus(statusParam as VisitStatus);
};
