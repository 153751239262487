import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ActionButtons } from '@/enums/filterVisits';

import { useSegmentRefreshVisits } from './useSegmentRefreshVisits/useSegmentRefreshVisits';

export const useOverview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const { dispatchRefreshEvent } = useSegmentRefreshVisits();

  const handleClickRefreshButton = (
    handleRefreshKpisData: () => void,
    refetchVisits: () => void,
  ) => {
    dispatchRefreshEvent(ActionButtons.refresh);

    handleRefreshKpisData();
    refetchVisits();
  };

  const handleOpenOrCloseModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return {
    formatMessage,
    cardRef,
    handleClickRefreshButton,
    handleOpenOrCloseModal,
    isModalOpen,
  };
};
