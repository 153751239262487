import { useEffect } from 'react';

import { $userSelection } from '@bees-force-shared/services';

import { VisitsActions } from '@/components/VisitsActions/VisitsActions';
import { setVendorId } from '@/stores/visitsParams/VisitsParamsEvents';

import { VisitsContent } from './components/VisitsContent/VisitsContent';
import { useVisits } from './hooks/useVisits';
import * as Styled from './Visits.styles';

export const Visits = (): JSX.Element => {
  const state = $userSelection.getState();
  const vendorId = state?.operation?.vendorId;

  useVisits();
  setVendorId(vendorId);

  useEffect(() => {
    document.body.style.setProperty('overflow-y', 'auto', 'important');
    return () => {
      document.body.style.removeProperty('overflow-y');
    };
  }, []);

  return (
    <Styled.VisitsPage>
      <VisitsActions />
      <VisitsContent />
    </Styled.VisitsPage>
  );
};
