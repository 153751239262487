import MessageMap from '../i18n';

export const frFR: MessageMap = {
  test: {
    translate: 'Traduire',
    missing: 'Seulement les options de langue anglaise sont disponibles',
  },
  Pages: {
    Visits: {
      Title: {
        PAGE_VISITS: 'Visites',
      },
      Status: {
        Error: {
          ERROR_MESSAGE: 'Impossible de charger les données',
          SUBTITLE: 'Essayez de recharger la page.',
          ON_REFRESH: 'Actualiser',
        },
        LOADING: 'Chargement des données en cours',
      },
    },
  },
  Components: {
    Overview: {
      Title: {
        OVERVIEW: 'Aperçu',
        CANCELLED_VISITS: '% de visites annulées',
        BDRS_WITHOUT_VISITS: '% de représentants de développement des affaires (RDA) sans visites',
        TOTAL_OF_VISITS: 'Total des visites',
      },
      Label: {
        CANCELLED_VISITS_TOTAL: 'sur [total] Visites',
        BDRS_WITHOUT_VISITS_TOTAL: 'sur [total] RDA',
        TOTAL_OF_VISITS_COUNT: 'Total',
        LAST_UPDATE: 'Dernière mise à jour le [lastUpdate].',
        FILTER_DESCRIPTION: "Sélectionnez une plage de dates pour l'affichage des données :",
      },
      Button: {
        REFRESH: 'Actualiser',
        TRY_AGAIN: 'Essayer de nouveau',
      },
      Error: {
        REFRESH_ERROR: "Impossible d'actualiser les données",
        DEFAULT_ERROR: "Quelque chose s'est mal passé.",
        RELOAD_BUTTON: 'Recharger',
      },
      Filter: {
        Periods: {
          TODAY: "Aujourd'hui",
          YESTERDAY: 'Hier',
          TOMORROW: 'Demain',
          OTHER: 'Autre',
        },
      },
      Graph: {
        TITLE: 'Par statut',
        SCHEDULED: 'Planifiées',
        IN_PROGRESS: 'En cours',
        COMPLETED: 'Terminées',
        JUSTIFIED: 'Excusées',
        CANCELED: 'Annulées',
      },
      SearchField: {
        PLACEHOLDER: 'Rechercher',
      },
      LOADING: 'Chargement du contenu',
    },
    VisitTableCard: {
      SearchBar: {
        ACCOUNT_ID: 'Identifiant du PDC',
        BDR_ID: 'ID RDA',
        BDR_NAME: 'Nom du RDA',
        SEARCH_BY: 'Chercher par',
      },
      Buttons: {
        SEARCH_BUTTON: 'Rechercher',
        FILTERS_BUTTON: 'Filtrer',
        APPLY_BUTTON: 'Appliquer',
        CANCEL_BUTTON: 'Annuler',
        CLEAR_ALL_BUTTON: 'Tout effacer',
        RELOAD_BUTTON: 'Recharger',
      },
      Title: {
        CARD_VISITS: 'Visites',
      },
      Error: {
        ACCOUNT_ID_ERROR: "Identifiant du PDC [id] n'existe pas.",
        BDR_ID_ERROR: "RDA [id] n'existe pas.",
        BDR_NAME_ERROR: "RDA [name] n'existe pas.",
      },
      StatusVisits: {
        STATUS: 'Statut',
        SCHEDULED: 'Planifiées',
        IN_PROGRESS: 'En cours',
        COMPLETED: 'Terminées',
        JUSTIFIED: 'Excusées',
        CANCELED: 'Annulées',
      },
      TableVisits: {
        SHOWING_RESULTS: 'Afficher [results] visites',
        BDR_ID: 'ID RDA',
        BDR_NAME: 'Nom du RDA',
        POC_ID: 'Identifiant du PDC',
        VISIT_DATE: 'Date de la visite',
        MIN_TIME: 'Heure min.',
        MAX_TIME: 'Heure max.',
        MINUTES: 'minutes',
        QUANTITY_INDICATION: 'de',
        LOADING_CONTENT: 'Chargement des données',
        ERROR_CONTENT: "Quelque chose s'est mal passé.",
        NO_CONTENT: "Il n'y a pas de contenu à afficher.",
        SEARCH_NOT_FOUND: 'Aucun résultat. Veuillez vérifier votre recherche et réessayer.',
        CLEAR_SEARCH: 'Effacer la recherche',
        POC_LATITUDE: 'Latitude',
        POC_LONGITUDE: 'Longitude',
      },
      Filter: {
        FILTERED_BY: 'Filtrer par',
      },
    },
    VisitDetails: {
      Title: {
        VISIT_DETAILS: 'Détails de la visite',
        POC_INFORMATION: 'Renseignements sur le PDC',
        NEXT_VISIT: 'Prochaine visite',
        BDR_INFORMATION: 'Information du RDA',
        VISIT_INFORMATION: 'Informations sur les visites',
        CANCEL_VISIT: 'Annuler la visite ?',
      },
      Label: {
        VISIT_DATE: 'Date de la visite',
        VISIT_STATUS: 'Statut de la visite',
        SUGGESTED_TIME: 'Durée suggérée',
        MINIMUM_TIME: 'Durée minimale',
        MAXIMUM_TIME: 'Durée maximale',
        NO_NEXT_VISIT: "Aucune visite n'est prévue pour ce PDC.",

        POC_NAME: 'Nom du PDC',
        POC_ID: 'Identifiant du PDC',
        POC_LATITUDE: 'Latitude',
        POC_LONGITUDE: 'Longitude',
        NO_POC_INFORMATION: "Il n'y a pas d'information sur le PDC pour cette visite.",

        BDR_NAME: 'Nom du RDA',
        BDR_ID: 'ID RDA',
        NO_BDR_INFORMATION: "Il n'y a pas d'information sur le RDA pour ce PDC.",

        SHOW_VISIT_DETAILS: 'Afficher les détails de la visite',
        MINUTES: '[min] minutes',
        NO_DATA_AVAILABLE: 'Aucune donnée disponible',
        CANCELED_DUE_TO: 'Cette visite a été annulée par le système en raison de [error].',

        CANCEL_VISIT_DESCRIPTION:
          'Cette action peut être annulée tant que la date reste dans le futur.',

        LOADING_CONTENT: 'Chargement des données en cours',
        ERROR_CONTENT: "Quelque chose s'est mal passé.",
      },
      Button: {
        CANCEL_MODAL: 'Annuler',
        GO_BACK: 'Non, revenir en arrière',
        CANCEL_VISIT: 'Oui, annuler',
        REFRESH: 'Recharger',
      },
      Toast: {
        SUCCESS: 'Visite annulée',
      },
      PageStatus: {
        Error: {
          ERROR_MESSAGE: 'Impossible de charger les données',
          SUBTITLE: 'Essayez de recharger la page.',
          ON_REFRESH: 'Recharger',
          ON_GO_TO: 'Revenir à Visites',
        },
        LOADING: 'Chargement des données en cours',
      },
    },
    ModalBDRsWithoutVisits: {
      Title: {
        BDRS_WITHOUT_VISITS: 'RDA sans visites',
      },
      Label: {
        SHOWING_RESULTS: 'Afficher [results] résultats',
        BDR_ID: 'ID RDA',
        BDR_NAME: 'Nom du RDA',
        DATE_WITHOUT_VISITS: 'Date sans visite',
        SEARCH_BY: 'Chercher par ID RDA',
        PAGINATION_INDICATION: 'de',
      },
    },
    ModalChangeDDC: {
      Title: {
        CHANGE_DDC: 'Changer le CD',
      },
      Paragraph: {
        FIND_ANOTHER_DDC: "Chercher un autre CD à gérer par l'ID du CD ou par le nom du CD.",
      },
      Button: {
        CANCEL: 'Annuler',
        CHANGE: 'Modifier',
        Label: {
          DDC: 'CD',
        },
      },
      Select: {
        Search: {
          PLACEHOLDER: 'Rechercher',
        },
        LABEL: 'CD',
        NOT_FOUND: "Nous n'avons pas trouvé ce que vous cherchez.",
        SHOW_ALL: 'Afficher tout',
      },
    },
    ModalChangeOperation: {
      Button: {
        Label: {
          OPERATION: 'Opération',
          TOOLTIP_INFORMATION: "L'opération inclut l'organisation et le vendeur.",
        },
      },
      TITLE: "Modifier l'opération",
      SUB_TITLE: "L'opération sera appliquée à la page des visites.",
      Actions: {
        CANCEL_BUTTON: 'Annuler',
        SAVE_BUTTON: 'Enregistrer',
      },
      Fields: {
        Country: {
          LABEL: 'Pays',
          PLACEHOLDER: 'Sélectionner un pays',
        },
        Organization: {
          LABEL: 'Organisation',
          HINT: 'Peut inclure des entreprises.',
          PLACEHOLDER: 'Sélectionner une organisation',
          ALERT: "Ce pays n'a pas d'organisations",
        },
        Seller: {
          LABEL: 'Vendeur',
          HINT: 'Qui recevra la visite.',
          PLACEHOLDER: 'Sélectionner un vendeur',
        },
        VendorId: {
          LABEL: 'ID du vendeur',
          PLACEHOLDER: 'Sélectionner un ID de vendeur',
        },
      },
    },
    FilterModalCalendar: {
      Button: {
        SAVE: 'Enregistrer',
        RESET: 'Réinitialiser',
      },
    },
    BadgeStatus: {
      Label: {
        SCHEDULED: 'Planifiées',
        IN_PROGRESS: 'En cours',
        COMPLETED: 'Terminées',
        JUSTIFIED: 'Excusées',
        CANCELED: 'Annulées',
      },
    },
    EditModal: {
      Label: {
        EDIT_VISIT: 'Modifier la visite',
        VISIT_DATE: 'Date de la visite',
        VISIT_STATUS: 'Statut de la visite',
        NEXT_VISIT: 'Prochaine visite',
        SCHEDULE: 'Planifié',
        CANCELED: 'Annulées',
        NO_DATA_AVAILABLE: 'Aucune donnée disponible',
        SAVED_CHANGE: 'Les modifications ont été enregistrées avec succès.',
      },
      Button: {
        CANCEL: 'Annuler',
        SAVE_CHANGES: 'Enregistrer les modifications',
        EDIT: 'Modifier',
        SAVE: 'Enregistrer',
        RESET: 'Réinitialiser',
      },
      Errors: {
        MAX_TIME_LESS_THAN_MIN_TIME:
          'Le temps maximum ne peut pas être inférieur ou égal au temps minimum.',
        MIN_TIME_LESS_THAN_2: 'Le temps minimum ne peut pas être inférieur à 2 minutes.',
        SAME_DATE:
          "Cette date n'est pas disponible, une visite existe déjà pour cette date. Choisissez une autre pour enregistrer ou annuler",
      },
      Alert: {
        WARNING_MESSAGE:
          'Si vous enregistrez ces modifications, cette visite peut changer en statut Planifié si tous les paramètres sont valides.',
      },
    },
  },
};
