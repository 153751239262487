/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react';
import { IntlShape, IntlProvider as NativeProvider } from 'react-intl';

import { usePreferredLanguageV2 } from 'admin-portal-shared-services';

import { MessageMap } from './i18n';
import { enUS } from './languages/en-US';
import { es } from './languages/es-419';
import { frFR } from './languages/fr-FR';
import { idID } from './languages/id-ID';
import { ptBR } from './languages/pt-BR';

const DEFAULT_LANGUAGE = 'en-US';

interface IIntlProvider {
  children: ReactNode;
}

export const mergeMessages = (messagesInput: MessageMap, selectedLocale: string): any => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-419': es,
  'id-ID': idID,
  'fr-FR': frFR,
};
export const intl = {} as IntlShape;

export function IntlProvider({ children }: IIntlProvider): JSX.Element {
  const DEFAULT_LANGUAGE = 'en-US';
  const preferredLanguage = usePreferredLanguageV2().preferredLanguage ?? DEFAULT_LANGUAGE;
  const defaultLanguage = usePreferredLanguageV2().defaultLanguage ?? DEFAULT_LANGUAGE;

  const mergedMessages = mergeMessages(messages, preferredLanguage);

  return (
    <NativeProvider
      locale={preferredLanguage}
      defaultLocale={defaultLanguage}
      messages={mergedMessages}>
      {children}
    </NativeProvider>
  );
}

export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
