import { useCallback, useRef, useState } from 'react';
import { DateRange, Matcher } from 'react-day-picker';

import { CalendarRef } from '@hexa-ui/components';

import { UseFilterModalCalendarProps } from '@/components/Overview/types/FilterModalCalendar';

export const useFilterModalCalendar = ({
  inputRef,
  handleOnSave,
  handleOnReset,
}: UseFilterModalCalendarProps) => {
  const { top, left } = inputRef?.current
    ? inputRef.current.getBoundingClientRect()
    : { top: 0, left: 0 };

  const [disableActions, setDisableActions] = useState(true);
  const [dateRange, setDateRange] = useState<DateRange>();
  const calendarRef = useRef<CalendarRef>(null);

  const handleActionButtons = useCallback((event: Matcher | Matcher[] | undefined) => {
    if ((event as DateRange)?.from && (event as DateRange)?.to) {
      setDisableActions(false);
      setDateRange(event as DateRange);
    } else {
      setDisableActions(true);
    }
  }, []);
  const onResetFilter = useCallback(() => {
    if (calendarRef.current) {
      calendarRef.current.clearSelection();
      setDisableActions(true);
    }
    handleOnReset && handleOnReset();
  }, [handleOnReset]);

  const onSaveFilter = useCallback(() => {
    if (calendarRef.current) {
      handleOnSave(dateRange);
    }
  }, [handleOnSave, dateRange]);

  const calculateStyleModal = (): React.CSSProperties | undefined => {
    const buttonHeight = 40;
    const spaceLeft = 100;
    if (window.scrollY) {
      return { top: `${top + window.scrollY + buttonHeight}px`, left: `${left - spaceLeft}px` };
    }
    return { top: `${top + buttonHeight}px`, left: `${left - spaceLeft}px` };
  };

  return {
    disableActions,
    dateRange,
    calendarRef,
    handleActionButtons,
    onResetFilter,
    onSaveFilter,
    calculateStyleModal,
  };
};
