import { useEffect, useState } from 'react';

import { useUnit } from 'effector-react';

import { UserInfoStore } from '@/stores/userInfoStore/UserInfoStore';

import { Ddc } from '../types/ModalChangeDDCTypes';

interface UseModalChangeDDCProps {
  isOpen?: boolean;
}

export const useModalChangeDDC = (props?: UseModalChangeDDCProps) => {
  const [internalIsOpen, setIsOpen] = useState(false);
  const { ddc } = useUnit(UserInfoStore);
  const [currentDdc, setCurrentDdc] = useState<Ddc>(ddc);

  const isOpen = props?.isOpen ?? internalIsOpen;

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    if (isOpen) {
      setCurrentDdc(ddc);
    }
  }, [isOpen, ddc]);

  return { isOpen, openModal, closeModal, currentDdc, setCurrentDdc };
};
